import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const InputPhone = ({
	data,
	value,
	onChange,
	onBlur,
	required,
	disabled,
	error,
	name,
	label,
	sideway,
	duplicateMobileError,
}) => {
	const { t } = useTranslation();
	const [phoneValue, setPhoneValue] = useState("");
	const [code, setCode] = useState("in");
	const countryCode = useSelector((state) => state.country.country);

	useEffect(() => {
		if (typeof value === "string") {
			setPhoneValue(value.replace("+", ""));
		}
	}, [value]);
	useEffect(() => {
		if (phoneValue === "") {
			setCode(countryCode);
		}
	}, [countryCode]);

	return (
		<div
			className="d-flex justify-content-center align-items-center mt-3"
			style={sideway ? { minWidth: "fit-content" } : { width: "fit-content" }}
		>
			<p className={`${sideway ? "" : "m-1"}`} style={{ width: "50%" }}>
				{t(label)} <span className="red-text">{required ? "*" : null}</span>
			</p>
			<div style={sideway ? { width: "50%" } : { width: "100%" }}>
				<PhoneInput
					country={code}
					preferredCountries={["us", "in", "fr"]}
					value={phoneValue}
					onChange={(phone) => {
						onChange({
							target: {
								id: name,
								type: "tel",
								value: `+${phone}`,
							},
						});
					}}
					onBlur={() => {
						onBlur({
							target: {
								id: name,
								type: "tel",
								value: value,
							},
						});
					}}
					disabled={disabled}
					buttonStyle={{ height: "34px" }}
					countryCodeEditable={false}
					dropdownStyle={{ zIndex: "100" }}
					defaultErrorMessage={error}
				/>
			</div>
			<div style={{ marginTop: "30px" }}>
				{duplicateMobileError && name === "fatherMobile" ? (
					<p
						className="red-text m-0"
						style={{ position: "relative", bottom: "30px" }}
					>
						{t("Mobile Number can't be same")}
					</p>
				) : (
					""
				)}
				{error && value != "" ? (
					<p
						className="red-text m-0"
						style={{ position: "relative", bottom: "30px" }}
					>
						{"Enter a valid" + t(label)}
					</p>
				) : error && value == "" && required ? (
					<p
						className="red-text m-0"
						style={{ position: "relative", bottom: "30px" }}
					>
						{ t("Required field.")}
					</p>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default InputPhone;

InputPhone.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	error: PropTypes.string,
};
