import React from "react";
import ErrorImage from "./GeneralComponents/error.jpg";
import config from "./config";
import { connect } from "react-redux";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", token: this.props.token };
  }

  componentDidCatch(error, errorInfo) {
    const err = error.stack;
    console.log("Error data is", { error, errorInfo }, err);
    fetch(`${config.devurl}/frontendLogs/add`, {
      method: "POST",
      headers: {
        Authorization: this.state.token,
        "Content-Type": "application/json",
      },
      credentials: "same-origin",

      body: JSON.stringify({
        category: "warning",
        error: err,
        url: location.pathname,
      }),
    })
      .then((res) => res.json())
      .then((data) => console.log("error boundary", data))
      .catch((error) => console.log("error of error"));
  }
  static getDerivedStateFromError(error) {
    const err = error;

    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <div
          className="w-100 d-flex justify-content-between align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="flex-1" style={{ marginLeft: "10%" }}>
            <h1 style={{ fontSize: "40px" }}>500</h1>
            <h1>ERROR</h1>
            <p>An error has occurred and we're working to fix the problem</p>
            <p>Please try again and if it didn't work out call our support team</p>
          </div>
          <div className="flex-1">
            <img src={ErrorImage} alt="Error image" style={{ marginLeft: "calc(50% - 108px)" }} />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
const mapStateToProps = (state) => ({
  token: state.token.token,
});
export default connect(mapStateToProps, null)(ErrorBoundary);
