import { MDBModal, MDBModalFooter, MDBModalHeader, MDBIcon } from "mdbreact";
import React, {
	useState,
	useCallback,
	useEffect,
	useRef,
	useMemo,
} from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "../../../GeneralComponents/Button";
import defaultImage from "./default.jpg";
import { useTranslation } from "react-i18next";

const InputImage = (props) => {
	const { t } = useTranslation();
	const fileRef = useRef();
	const imageRef = useRef();
	const [upImg, setUpImg] = useState();
	const [crop, setCrop] = useState({
		unit: "px",
		width: 200,
		height: 200,
		aspect: 1,
		x: 0,
		y: 0,
	});
	const [completedCrop, setCompletedCrop] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);

	useEffect(() => {
		const ref = imageRef.current;
		if (props.value == "" || props.value == undefined) {
			ref.src = defaultImage;
			return;
		}
		if (typeof props.value == "string") {
			ref.src = props.value;
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(props.value);
		reader.onloadend = () => {
			ref.src = reader.result;
		};
	}, [props.value]);
	const imageUrl = useMemo(() => {}, [props.value]);

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				setUpImg(reader.result);
				setIsOpen(true);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}
		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = "high";
		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);
	}, [completedCrop]);
	function generateDownload(canvas, crop) {
		if (!crop || !canvas) {
			return;
		}
		canvas.toBlob(
			(blob) => {
				let file = new File([blob], "image.jpg", { type: "image" });
				props.onChange({
					target: {
						files: [file],
						type: "file",
						id: props.name,
					},
				});
				setIsOpen(false);
			},
			"image/*",
			1
		);
	}

	return (
		<div style={props.style} key={props.name}>
			<MDBModal
				isOpen={isOpen}
				size="md"
				centered
				toggle={() => setIsOpen(false)}
			>
				<MDBModalHeader
					style={{ backgroundColor: "#2A4494" }}
					titleClass="d-flex justify-content-between align-items-center white-text w-100"
				>
					<p
						className="m-0 p-0"
						style={{ fontSize: "21px", fontWeight: "bold" }}
					>
						Crop Image
					</p>
					<MDBIcon icon="times" onClick={() => setIsOpen(false)} />
				</MDBModalHeader>
				<div style={{ width: "100%" }}>
					<ReactCrop
						src={upImg}
						onImageLoaded={onLoad}
						crop={crop}
						circularCrop
						onChange={(c) => setCrop(c)}
						onComplete={(c) => setCompletedCrop(c)}
					/>
					<canvas
						ref={previewCanvasRef}
						style={{
							display: "none",
						}}
					/>
				</div>
				<MDBModalFooter>
					<Button
						onClick={() =>
							generateDownload(previewCanvasRef.current, completedCrop)
						}
					>
						Ok
					</Button>
				</MDBModalFooter>
			</MDBModal>
			<p style={{ fontWeight: "bold", fontSize: "16px" }}>{t(props.label)}</p>
			<img
				src={imageUrl}
				ref={imageRef}
				width={
					window.matchMedia("(max-width: 576px)").matches
						? 60
						: props.width
						? props.width
						: 150
				}
				height={
					window.matchMedia("(max-width: 576px)").matches
						? 60
						: props.height
						? props.height
						: 150
				}
				style={{
					borderRadius: "50%",
					objectFit: "contain",
				}}
			/>
			<input
				type="file"
				onChange={onSelectFile}
				accept="image/png,image/jpeg"
				ref={fileRef}
				disabled={props.disabled}
				id={props.name}
				style={{ display: "none" }}
			/>
			{props.disabled ? null : (
				<button
					type="button"
					onClick={() => fileRef.current.click()}
					style={{
						border: "none",
						backgroundColor: "transparent",
						marginLeft: "10px",
						borderRadius: "8px",
					}}
					data-toggle="tooltip"
					data-placement="top"
					title="Edit Avatar"
				>
					<i className="fas fa-pen" style={{ fontSize: "20px" }}></i>
				</button>
			)}
		</div>
	);
};

export default InputImage;
