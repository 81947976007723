import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import logo from "../public/logo.png";
import config from "../config";

import { Button, Form, FormGroup, Input, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingReset from "../loadingcompo/loadingreset";
function Reset() {
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [redirect, setredirect] = useState("");
  const [errpass, setErrpass] = useState("");
  const [loading, setloading] = useState(false);
  const token = useSelector((state) => state.token.reset);
  const handlesubmit = (event) => {
    event.preventDefault();
    console.log("Reset password");
    console.log("token", token);

    if (errpass === "error") {
      alert("password credentials do not match");
    } else if (newpassword !== confirmpassword) {
      setErrpass("conpaserr");
      //alert("password do not match");
    } else if (newpassword === "") {
      // alert("please reset your password")
    } else {
      setloading(true);
      let resok = true;
      console.log("newpassword", newpassword);
      console.log("nconfirmpassword", confirmpassword);
      fetch(`${config.devurl}/resetpassword`, {
        method: "PATCH",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",

        body: JSON.stringify({ password: newpassword, confirmpassword: confirmpassword }),
      })
        .then((res) => {
          resok = res.ok;
          console.log("resok ", resok);
          if (resok === true) {
            setTimeout(() => {
              setloading(false);
            }, 2000);

            setredirect(true);
          } else {
            console.log("resok ", res);
            setloading(false);
            throw Error(res.json());
          }
        })
        .catch((error) => {
          // alert("error",error.res)
          setloading(false);
          setErrpass(" server error");
        });
    }
  };
  //this is validation function  for validating passwords
  const validate = () => {
    let reg = /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[a-z])(?=.*[A-Z]).*$/;
    if (!reg.test(newpassword)) {
      setErrpass("error");
    } else {
      setErrpass("");
    }
  };
  const confirm = () => {
    let reg = /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[a-z])(?=.*[A-Z]).*$/;
    if (!reg.test(newpassword)) {
      setErrpass("error");
    } else {
      if (newpassword !== confirmpassword) {
        setErrpass("conpaserr");
      }
    }
  };
  const renderel = () => {
    if (errpass === "error") {
      return (
        <>
          <p className="alert11">Invalid password</p>
        </>
      );
    } else if (errpass === "conpaserr") {
      return (
        <>
          <p className="alert11">Password do not match</p>
        </>
      );
    } else {
      return null;
    }
  };

  if (token) {
    if (redirect) {
      return <Redirect to="/" />;
    } else {
      return (
        <>
          <div className="container  w-100 p-3 ">
            {loading ? (
              <LoadingReset />
            ) : (
              <div className="row" style={{ marginTop: "150px" }}>
                <div className="col-sm-1 col-md-5 col-lg-5 log-margin">
                  <img src={logo} alt="Logo" width="100%" />
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 log-margin"></div>
                <div className="col-sm-10 col-md-5 col-lg-5 log-margin border">
                  {errpass ? renderel() : null}
                  <p className="text-left font-weight-bold" color="black" style={{ fontSize: 22 }}>
                    Reset password
                  </p>
                  <Form onSubmit={handlesubmit}>
                    <FormGroup row>
                      <Col md={10}>
                        <Input
                          type="password"
                          id="newpassword"
                          name="newpassword"
                          placeholder="New Password"
                          onBlur={() => validate()}
                          value={newpassword}
                          onChange={(e) => setnewpassword(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Col md={10}>
                        <Input
                          type="password"
                          id="confirmpassword"
                          name="confirmpassword"
                          placeholder="confirm New Password"
                          value={confirmpassword}
                          onBlur={() => confirm()}
                          onChange={(e) => setconfirmpassword(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={{ size: 10 }}>
                        {errpass ? (
                          <Button
                            type="Submit"
                            style={{ background: "#304391", color: "white" }}
                            text-shadow="red"
                            size="md"
                            block
                            color="#304391"
                            disabled>
                            Reset Password
                          </Button>
                        ) : (
                          <Button
                            type="Submit"
                            style={{ background: "#304391", color: "white" }}
                            text-shadow="red"
                            size="md"
                            block
                            color="#304391">
                            Reset Password
                          </Button>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
  } else {
    return <Redirect to="/" />;
  }
}
export default Reset;
