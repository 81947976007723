import React from "react";
import { useTranslation } from "react-i18next";

const InputAddress = ({ value, onChange, onBlur, required, disabled, label, name }) => {

  const { t } = useTranslation();

  const changeHandler = (event, isBlur = false) => {
    const target = event.target,
      result = {
        target: {
          id: name,
          value: {
            ...value,
            [target.id]: target.value,
          },
        },
      };
    if (isBlur) {
      onBlur(result);
    } else {
      onChange(result);
    }
  };
  return (
    <div>
      <p
        style={{
          marginBottom: "20px",
          marginRight: "10px",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        {t(label)}
      </p>
      {Object.keys(value || {}).map((ele) => (
        <div className="form-group" style={{ marginBottom: "0" }}>
          <input
            type="text"
            className="form-control"
            id={ele}
            value={value[ele]}
            onChange={changeHandler}
            onBlur={(event) => changeHandler(event, true)}
            disabled={disabled}
            required={required ? true : null}
          />
          <label htmlFor={ele}>
            {t(ele)} {required ? <span style={{ color: "red" }}>*</span> : null}
          </label>
        </div>
      ))}
    </div>
  );
};

export default InputAddress;
