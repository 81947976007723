import React from "react";
import { useTranslation } from "react-i18next";

const Options = ({ start = "", end = "", step = 0 }) => {
  const startArray = start.split(":");
  const endArray = end.split(":");
  let currentHour = +startArray[0],
    currentMinute = +startArray[1];
  const options = [];
  while (
    currentHour < +endArray[0] ||
    (currentHour == +endArray[0] && currentMinute < +endArray[1])
  ) {
    const time =
      currentHour.toString().padStart(2, 0) + ":" + currentMinute.toString().padEnd(2, 0);
    options.push(<option value={time}>{time}</option>);
    let nextMinutes = currentMinute + step,
      nextHour = currentHour;
    if (nextMinutes >= 60) {
      nextHour += Math.floor(nextMinutes / 60);
      nextMinutes = nextMinutes % 60;
    }
    currentHour = nextHour;
    currentMinute = nextMinutes;
  }
  return options;
};
const InputDatalist = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  error,
  disabled,
  required,
  step,
  start,
  end,
  valueRequired,
  meetingForm = false,
  wrapperStyle,
  ...props
}) => {

  const {t} = useTranslation();

  return (
    <div
      style={wrapperStyle}
      className={`form-group  ${meetingForm ? "mb-0 d-flex mt-3" : ""}`}
      style={{
        marginBottom: `${meetingForm ? "" : "30px"}`,
        position: `${meetingForm ? "" : "relative"}`,
        top: `${meetingForm ? "" : "21px"}`,
      }}
    >
      <input
        type="time"
        list="productName"
        className={`form-control ${meetingForm ? "flex-fill" : ""}`}
        id={name}
        value={value}
        style={props.style}
        required={required || valueRequired}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={name} className={meetingForm ? "d-none" : ""}>
        {t(label)}
        {required ? <span className="red-text">*</span> : null}
      </label>
      <datalist id="productName">
        <Options start={start} end={end} step={step} />
      </datalist>
    </div>
  );
};

export default InputDatalist;
