import React from "react";
import { useTranslation } from "react-i18next";

const InputRadio = (props) => {

  const {t} = useTranslation();

  return (
    <div
      style={{
        marginBottom: "30px",
        position: "relative",
        bottom: "11px",
        ...props.style,
      }}>
      {props.label ? (
        <p
          style={{
            position: "relative",
            width: "max-content",
            zIndex: "20",
            backgroundColor: "white",
            top: "22px",
            left: "15px",
            padding: "0px 5px",
            fontSize: "14px",
          }}>
          {t(props.label)} <span className="red-text">*</span>
        </p>
      ) : null}
      <select
        className="browser-default custom-select text-capitalize"
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        onBlur={props.onBlur}
        id={props.name}
        required={true}>
        <option
          selected={true}
          disabled
          value={props.defaultName ? props.defaultName : t("Select an Option")}
          key="Select an option">
          {props.defaultName ? props.defaultName : t(`Select An Option`)}
        </option>
        {props.options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      {props.error ? (
        <p className="red-text m-0">{`${props.defaultValue || "Select an option"}`}</p>
      ) : null}
    </div>
  );
}

export default InputRadio;
