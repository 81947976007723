import React from "react";
import { useTranslation } from "react-i18next";

const InputDropDown = (props) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				marginBottom: "30px",
				marginTop: `${
					props.label && props.label === "Child's Blood group" ? "35px" : "0px"
				}`,
				position: "relative",
				bottom: `${
					props?.prospects && props.label === "Countries" ? "21px" : "11px"
				}`,
				...props.style,
			}}
		>
			{props.label ? (
				<p
					style={{
						position: "relative",
						width: "max-content",
						zIndex: "20",
						backgroundColor: "white",
						top: "22px",
						left: "15px",
						padding: "0px 5px",
						fontSize: "14px",
					}}
				>
					{t(props.label)}
					{props.required ? <span style={{ color: "red" }}>*</span> : null}
				</p>
			) : null}
			<select
				className="browser-default custom-select text-capitalize"
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				onBlur={props.onBlur}
				id={props.name}
				required={props.required}
			>
				<option
					selected={true}
					disabled
					value={props.defaultName ? props.defaultName : `Select An Option`}
					key="Select An Option"
				>
					{props.defaultName ? t(props.defaultName) : t(`Select An Option`)}
				</option>
				{props?.options.map((option) => (
					<option value={option} key={option}>
						{t(option)}
					</option>
				))}
			</select>
			{props.error ? (
				<p className="red-text m-0">{`${
					t(props.defaultValue) || t("Select An Option")
				}`}</p>
			) : null}
		</div>
	);
};

export default InputDropDown;
