import { data } from "react-dom-factories";
import * as ActionTypes from "../actions/actionTypes.js/Data";
export const initialState = {
	isLoggedIn: false,
	isloading: true,
	token: null,
	reset: null,
	_id: null,
	mob: null,
};
export const tokenReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOG_IN:
			return {
				isloading: false,
				token: action.payload.token,
				isLoggedIn: true,
				reset: null,
			};
		case ActionTypes.RESET:
			return { ...state, reset: action.payload.token };
		case ActionTypes.OTPSCREEN:
			console.log(action.payload.token);
			return {
				...state,
				_id: action.payload.token._id,
				mob: action.payload.token.mobile,
			};
		case ActionTypes.LOG_OUT:
			return { ...state, isloading: false, token: null, isLoggedIn: false };

		case ActionTypes.LOAD_ING:
			return { ...state, isloading: action.payload.token };

		default:
			return state;
	}
};
export const childdata = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.CHILDDATA:
			console.log("data in before child data", action.payload);
			const newState = [];
			action.payload.map((child) => {
				console.log("data in before  child data 35", child);
				let section = "";
				if (child.section) {
					section = child.section.sectionName;
				}

				let group = "";
				let owner = "";
				if (child.group) {
					group = child.group.name;
					if (child.group.owner) {
						owner = child.group.owner.owner;
					}
				}
				let data1 = {
					id: child._id,
					img: child.photo,
					firstname: child.firstName,
					lastname: child.lastName,
					secname: section,
					group: group,
					prof: owner,
				};
				newState.push(data1);
			});
			console.log("data reducer child data  49", newState);
			return newState;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const childdetail = (state = null, action) => {
	switch (action.type) {
		case ActionTypes.CHILDDETAIL:
			console.log("data in before child data detail", action.payload);

			var vaccination = [];
			action.payload.child.vaccinationType.map((item, idx) => {
				if (item !== "null") {
					let vac = {
						value: item,
					};
					vaccination.push(vac);
				}
			});
			var vacdate = [];
			action.payload.child.vaccinationLastDate.map((item, idx) => {
				if (item !== "null") {
					let vac = {
						value: item,
					};
					vacdate.push(vac);
				}
			});
			var sibling = [];
			if (action.payload.child.siblingName) {
				action.payload.child.siblingName.map((item, idx) => {
					if (item !== "null") {
						let sib = {
							value: item,
						};
						sibling.push(sib);
					}
				});
			}
			var hp = [];
			if (action.payload.child.healthProblems) {
				action.payload.child.healthProblems.map((item, idx) => {
					if (item !== "null") {
						let vac = {
							value: item,
						};
						hp.push(vac);
					}
				});
			}
			var allergi = [];
			if (action.payload.child.allergies) {
				action.payload.child.allergies.map((item, idx) => {
					if (item !== "null") {
						let vac = {
							value: item,
						};
						allergi.push(vac);
					}
				});
			}
			let section = "";
			if (action.payload.child.section) {
				section = action.payload.child.section.sectionName;
			}

			let group = "";

			if (action.payload.child.group) {
				group = action.payload.child.group.name;
			}

			let data1 = {
				_id: action.payload.child._id,
				firstname: action.payload.child.firstName,
				lastname: action.payload.child.lastName,
				motherfirstname: action.payload.child.mother.name,
				motherlastname: action.payload.child.mother.lastName,
				fatherfirstname: action.payload.child.father.name,
				fatherlastname: action.payload.child.father.lastName,
				motheremail: action.payload.child.mother.email,
				mothermobile: action.payload.child.mother.mobile,
				fatheremail: action.payload.child.father.email,
				fathermobile: action.payload.child.father.mobile,
				gender: action.payload.child.gender,
				dob: action.payload.child.dateOfBirth,
				vaccination: vaccination,
				vacdate: vacdate,
				hp: hp,
				allergi: allergi,
				ecnf1: action.payload.child.emergencyContactFirstName1,
				ecnl1: action.payload.child.emergencyContactLastName1,
				ecnm1: action.payload.child.emergencyContact1,
				ecnr1: action.payload.child.relationToChild1,
				ecnf2: action.payload.child.emergencyContactFirstName2,
				ecnl2: action.payload.child.emergencyContactLastName2,
				ecnm2: action.payload.child.emergencyContact2,
				ecnr2: action.payload.child.relationToChild2,
				height: action.payload.child.height,
				hunit: action.payload.child.heightUnit,
				weight: action.payload.child.weight,
				wunit: action.payload.child.weightUnit,
				comp: action.payload.child.companionDetails,
				tauth: action.payload.child.exteriorTripAuthorization,
				pauth: action.payload.child.photoVideoAuthorization,
				sec: section,
				group: group,
				sibling: sibling,
				photo: action.payload.child.photo,
			};
			const newState = data1;
			console.log("data reducer child data detail ", newState);
			return newState;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const childenroll = (state = null, action) => {
	switch (action.type) {
		case ActionTypes.CHILDENROLLDETAIL:
			console.log("data in before child data detail", action.payload);
			var allergi = [];
			if (action.payload.allergies) {
				action.payload.allergies.map((item, idx) => {
					if (item !== "null") {
						let vac = {
							value: item,
						};
						allergi.push(vac);
					}
				});
			}
			var sibling = [];
			if (action.payload.siblingName) {
				action.payload.siblingName.map((item, idx) => {
					if (item !== "null") {
						let sib = {
							value: item,
						};
						sibling.push(sib);
					}
				});
			}
			let dob = new Date(action.payload.dateOfBirth).getDate();
			let month = new Date(action.payload.dateOfBirth).getMonth();
			let year = new Date(action.payload.dateOfBirth).getFullYear();
			let date = year + "-" + (month + 1) + "-" + dob;

			let data1 = {
				_id: action.payload._id,
				firstname: action.payload.firstName,
				lastname: action.payload.lastName,
				motherfirstname: action.payload.motherFirstName,
				motherlastname: action.payload.motherLastName,
				fatherfirstname: action.payload.fatherFirstName,
				fatherlastname: action.payload.fatherLastName,
				motheremail: action.payload.motherEmail,
				mothermobile: action.payload.motherMobile,
				fatheremail: action.payload.fatherEmail,
				fathermobile: action.payload.fatherMobile,
				gender: action.payload.gender,
				submit: action.payload.submittedBy,
				comments: action.payload.comment,
				status: action.payload.status,
				dob: date,
				ecnf1: action.payload.emergencyContactFirstName1,
				ecnl1: action.payload.emergencyContactLastName1,
				ecnm1: action.payload.emergencyContact1,
				ecnr1: action.payload.relationToChild1,
				ecnf2: action.payload.emergencyContactFirstName2,
				ecnl2: action.payload.emergencyContactLastName2,
				ecnm2: action.payload.emergencyContact2,
				ecnr2: action.payload.relationToChild2,
				height: action.payload.height,
				hunit: action.payload.heightUnit,
				weight: action.payload.weight,
				wunit: action.payload.weightUnit,
				comp: action.payload.companionDetails,
				tauth: action.payload.exteriorTripAuthorization,
				pauth: action.payload.photoVideoAuthorization,
				sibling: sibling,
				allergi: allergi,
				photo: action.payload.photo,
				age: action.payload.age,
				//mphoto:action.payload.motherPhoto,
				//fphoto:action.payload.fatherPhoto,
				// oemail:action.payload.
			};
			const newState = data1;
			console.log("data reducer child data detail ", newState);
			return newState;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const sectionnames = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.SECTION:
			console.log("data in before child data", action.payload);
			const newState = [];
			action.payload.map((section) => {
				var owner = "";
				if (section.owner) {
					owner = section.owner.owner + " " + section.owner.lastName;
				}
				let data1 = {
					id: section._id,
					img: section.avatar,
					secname: section.sectionName,
					owner: owner,
					created: section.createdAt,
					lastmodified: section.updatedAt,
				};
				newState.push(data1);
			});
			state = newState.slice();
			console.log("data reducer section data ", newState);
			return state;
		case ActionTypes.CREATESEC:
			var newstate = state.slice();
			var owner =
				action.payload.sections.owner.owner +
				" " +
				action.payload.sections.owner.lastName;
			let data1 = {
				id: action.payload.sections._id,
				img: action.payload.sections.avatar,
				secname: action.payload.sections.sectionName,
				owner: owner,
				created: action.payload.sections.createdAt,
				lastmodified: action.payload.sections.updatedAt,
			};
			newstate.push(data1);
			state = newstate;
			console.log("createsec", newstate);
			return state;
		case ActionTypes.EDITSECTION:
			let datachild1 = JSON.stringify(state);
			datachild1 = JSON.parse(datachild1);
			const { data2 } = action.payload;
			console.log("inside reducer 190", action.payload);
			datachild1.map((child) => {
				if (child.id === action.payload.Id) {
					(child["secname"] = action.payload.secname),
						(child["owner"] = action.payload.owner),
						(child["created"] = action.payload.created),
						(child["lastmodified"] = action.payload.lastmodified),
						(child["img"] = action.payload.img);
				}
			});
			console.log("inside reducer 190", datachild1);
			state = datachild1;
			return datachild1;
		case ActionTypes.DELETESEC:
			var newstate = state.slice();
			var newstate1 = newstate.filter((item) => {
				if (item.id !== action.payload) {
					return item;
				}
			});
			state = newstate1;
			console.log("delete sec", newstate1);
			return newstate1;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const section = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.SECTIONNAMES:
			var newstate = [];
			action.payload.map((section) => {
				var data = {
					section: section.sectionName,
					id: section._id,
				};
				newstate.push(data);
			});
			state = newstate;
			console.log("data reducers line no-200 section names", newstate);
			return newstate;
		case ActionTypes.ADD_SEC:
			var newstate = state.slice();
			let data1 = {
				section: action.payload.sections.sectionName,
				id: action.payload.sections._id,
			};
			newstate.push(data1);
			state = newstate;
			return state;
		case ActionTypes.DELETESEC:
			var newstate = state.slice();
			console.log("dele", action.payload);
			var newstate1 = newstate.filter((item) => {
				if (item.id !== action.payload) {
					return item;
				}
			});
			state = newstate1;
			console.log("delete sec", newstate1);
			return newstate1;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const ownerlist = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.OWNERLIST:
			const newstate = [];
			action.payload.ownerName.map((owner) => {
				newstate.push(owner);
			});
			console.log("owner in redux", newstate);
			return newstate;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const groupname = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.GROUPNAME:
			console.log("group in redux");
			const newstate = [];
			action.payload.map((action) => {
				var owner = "";
				if (action.owner) {
					owner = action.owner.owner + " " + action.owner.lastName;
				}
				var sec = "";
				if (action.section) {
					sec = action.section.sectionName;
				}
				let data1 = {
					id: action._id,
					groupname: action.name,
					secname: sec,
					img: action.avatar,
					owner: owner,
					created: action.createdAt,
					lastmodified: action.updatedAt,
				};
				newstate.push(data1);
			});
			state = newstate;
			console.log("group in redux", newstate);
			return newstate;
		case ActionTypes.CREATEGROUP:
			var newState = state.slice();
			let data2 = {
				id: action.payload.id,
				groupname: action.payload.groupname,
				secname: action.payload.secname,
				img: action.payload.img,
				owner: action.payload.owner,
				created: action.payload.created,
				lastmodified: action.payload.lastmodified,
			};
			newState.push(data2);
			console.log(newState);
			state = newState;
			return state;
		case ActionTypes.EDITGROUP:
			let datachild1 = JSON.stringify(state);
			datachild1 = JSON.parse(datachild1);
			console.log("inside reducer 190", action.payload);
			datachild1.map((child) => {
				if (child.id === action.payload.Id) {
					(child["secname"] = action.payload.secname),
						(child["owner"] = action.payload.owner),
						(child["created"] = action.payload.created),
						(child["lastmodified"] = action.payload.lastmodified),
						(child["img"] = action.payload.img),
						(child["groupname"] = action.payload.groupname);
				}
			});
			console.log(datachild1);
			return datachild1;
		case ActionTypes.DELETEGROUP:
			var newstate1 = state.slice();
			newstate1 = newstate1.filter((item) => {
				if (item.id !== action.payload) {
					return item;
				}
			});
			state = newstate1;
			return newstate1;
		case ActionTypes.DELETESECGROUP:
			var newstate1 = state.slice();
			console.log(" before", action.payload.secname);
			newstate1.forEach((item) => {
				if (item.secname == action.payload.secname) {
					(item["id"] = item.id),
						(item["groupname"] = item.groupname),
						(item["secname"] = "");
					item["img"] = item.img;
					item["owner"] = item.owner;
					item["created"] = item.created;
					item["lastmodified"] = item.lastmodified;
				}
			});

			console.log("newstate1", newstate1);
			return newstate1;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const group = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.GROUP:
			var newstate = [];
			action.payload.map((action) => {
				var data = {
					group: action.name,
					id: action._id,
				};
				newstate.push(data);
			});
			state = newstate;
			console.log("data reducers line no-339 group names group", newstate);
			return newstate;
		case ActionTypes.ADD_GROUP:
			var newstate = state.slice();
			let data1 = {
				group: action.payload.groupname,
				id: action.payload.id,
			};
			newstate.push(data1);
			state = newstate;
			return state;
		case ActionTypes.DELETEGROUP:
			var newstate = state.slice();
			newstate = newstate.filter((item) => {
				if (item.id !== action.payload) {
					return item;
				}
			});
			state = newstate;
			return newstate;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const category = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.CATEGORY:
			console.log("category", action.payload);
			let newstate = [];
			action.payload.map((cate) => {
				let data = {
					category: cate.category,
				};
				newstate.push(data);
			});
			state = newstate;
			return newstate;

		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const subcategory = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.SUBCATEGORY:
			let newstate = [];
			action.payload.subCategoryName.map((cate) => {
				let data = {
					category: cate.subCategory,
				};
				newstate.push(data);
			});
			state = newstate;
			return newstate;

		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};
export const activity = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ACTIVITY:
			var newstate = [];
			console.log(action.payload);
			action.payload.map((item) => {
				var category = "";
				var name = "";
				var color = "";
				var freq = "";
				var subc = "";
				var desc = "";
				var dur = "";
				var inst = "";
				var milestone = "";
				var sec = "";
				var group = "";

				if (item.activityCategory) {
					category = item.activityCategory.category;
				}
				if (item.activityCode) {
					color = item.activityCode;
				}
				if (item.activityTitle) {
					name = item.activityTitle;
				}
				if (item.frequency) {
					freq = item.frequency;
				}
				if (item.activitySkill) {
					subc = item.activitySkill.skill;
				}
				if (item.activityDescription) {
					desc = item.activityDescription;
				}
				if (item.milestone[0]) {
					milestone = item.milestone[0].milestone.slice(0, 25);
				}
				if (item.activityDurationInMins) {
					dur = item.activityDurationInMins;
				}
				if (item.instructions) {
					inst = item.instructions;
				}

				if (item.section) {
					sec = item.section.sectionName;
				}
				if (item.group) {
					group = item.group.name;
				}
				let data = {
					id: item._id,
					category: category,
					color: color,
					name: name,
					freq: freq,
					skill: subc,
					desc: desc,
					dur: dur,
					inst: inst,
					milestone: milestone,
					sec: sec,
					group: group,
				};
				newstate.push(data);
				// console.log('activity ', newstate)
			});
			state = newstate;
			return newstate;
		case ActionTypes.CREATEACTIVITY:
			var newstate = state.slice();

			let data = {
				id: action.payload.id,
				category: action.payload.category,
				color: action.payload.color,
				name: action.payload.name,
				freq: action.payload.freq,
				subc: action.payload.subc,
				desc: action.payload.desc,
				dur: action.payload.dur,
				inst: action.payload.inst,
				skill: action.payload.skill,
				sec: action.payload.sec,
				group: action.payload.group,
				milestone: action.payload.milestone,
			};
			console.log("new activity", data);
			newstate.push(data);
			state = newstate;
			return newstate;
		case ActionTypes.DELETEACTIVITY:
			var newstate = state.slice();
			console.log("id in red act", action.payload, newstate, state);
			var newstate1 = newstate.filter((item) => {
				console.log(
					"id in red act",
					item.id,
					action.payload,
					item.id === action.payload
				);
				if (item.id !== action.payload) {
					return item;
				}
			});
			state = newstate1;
			console.log(newstate1);
			return newstate1;
		case ActionTypes.LOG_OUT:
			return [];
		default:
			return state;
	}
};

export const Staff = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ADD_STAFF:
			//return {...state, isLoading: false, errMess: null, staff: action.payload};
			console.log("data in before staff data", action.payload);
			const newState = [];
			action.payload.daycareProfessionals.map((staff) => {
				//console.log('data in before  staff data 35', staff)
				let data1 = {
					employeeCode: staff.employeeCode,
					photo: staff.photo,
					owner: staff.owner,
					lastName: staff.lastName,
					dob: staff.dob,
					email: staff.email,
					address1: staff.addresses.address1,
					address2: staff.addresses.address2,
					city: staff.addresses.city,
					country: staff.addresses.country,
					_id: staff._id,
					idDetails: staff.idDetails,
					idType: staff.idType,
					joining: staff.joining,
					manager: staff.manager,
					mobile: staff.mobile,
					nationality: staff.nationality,
					groupNames: staff.groups,
					sectionNames: staff.sections,
					//secname: child.section.sectionName,
					//group: child.group.name,
					//prof: child.group.owner
				};
				newState.push(data1);
			});
			console.log("data reducer child data  49", newState);
			state = newState;
			return newState;

		case ActionTypes.ADD_STAFFLIST:
			// console.log('actionlist reducer is called')
			//console.log('data in before  staff data 35', staff)
			console.log("reducer addstafflist called");
			let data1 = {
				employeeCode: action.payload.employeeCode,
				photo: action.payload.photo,
				owner: action.payload.firstName,
				lastName: action.payload.lastName,
				dob: action.payload.dob,
				email: action.payload.email,
				address1: action.payload.addresses.address1,
				address2: action.payload.addresses.address2,
				city: action.payload.addresses.city,
				country: action.payload.addresses.country,
				_id: action.payload._id,
				idDetails: action.payload.idDetails,
				idType: action.payload.idType,
				joining: action.payload.joining,
				manager: action.payload.manager,
				mobile: action.payload.mobile,
				nationality: action.payload.nationality,
				groupNames: action.payload.groups,
				sectionNames: action.payload.sections,
				//secname: child.section.sectionName,
				//group: child.group.name,
				//prof: child.group.owner
			};
			console.log("newly added entry is-", data1);
			state.push(data1);
			return state;
		case ActionTypes.DELETE_STAFF:
			console.log(
				"DELETE_STAFF action reducer is called with id=",
				action.payload
			);

			var newstate = state.slice();
			console.log("before delete newstate is ", newstate);
			newstate = newstate.filter((staff) => {
				if (staff._id !== action.payload) {
					return staff;
				}
			});
			console.log("after delete newstate is ", newstate);
			state = newstate;
			return newstate;
		case ActionTypes.ARCHIVE_STAFF:
			console.log(
				"ARCHIVE_STAFF action reducer is called with id=",
				action.payload
			);

			var newstate = state.slice();
			console.log("before archive newstate is ", newstate);
			newstate = newstate.filter((staff) => {
				if (staff._id !== action.payload) {
					return staff;
				}
			});
			console.log("after archive newstate is ", newstate);
			state = newstate;
			return newstate;
		default:
			return state;
	}
};

export const Salary = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ADD_SALARY:
			console.log("reducer add salary called");

			console.log("state before adding salary is", state);
			/*var a=state.map((staff)=>{
                    console.log('each staff in state is',staff);
                    return staff;
                });*/
			let newdata = {
				_id: action.payload._id,
				employeeGrade: action.payload.employeeGrade,
				basicSalary: action.payload.basicSalary,
				currency: action.payload.currency,
				variableEarning: action.payload.variableEarning.slice(),
				deductions: action.payload.deductions.slice(),
			};
			console.log("newly added entry is-", newdata);
			state.push(newdata);
			console.log("state after adding salary is", state);
			return state;
		case ActionTypes.EDIT_SALARY:
			console.log("reducer edit salary called");
			let newstate = state.filter((staff) => staff._id != action.payload._id);
			let data1 = {
				_id: action.payload._id,
				employeeGrade: action.payload.employeeGrade,
				basicSalary: action.payload.basicSalary,
				currency: action.payload.currency,
				variableEarning: action.payload.variableEarning.slice(),
				deductions: action.payload.deductions.slice(),
			};
			console.log("newly added entry is-", data1);
			newstate.push(data1);
			return newstate;
		default:
			return state;
	}
};

export const TwilioDevice = (state = null, action) => {
	switch (action.type) {
		case ActionTypes.TWILIO_CLIENT_DEVICE:
			return action.payload;
		default:
			return state;
	}
};

export const ParentsData = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ADD_PARENTS:
			let data1 = action.payload;
			data1 = data1.map((ele) => {
				let newEle = {
					...ele,
					parentName: `${ele.firstName} ${ele.lastName}`.toLowerCase(),
					childName: `${ele.child?.firstName || ""} ${
						ele.child?.lastName || ""
					}`.toLowerCase(),
					groupName: `${ele.child?.group?.name ?? ""}`.toLowerCase(),
					sectionName: `${ele.child?.section?.sectionName ?? ""}`.toLowerCase(),
				};
				newEle.anyText = `${newEle.parentName} ${newEle.childName} ${newEle.groupName} ${newEle.sectionName}`;
				return newEle;
			});
			return data1;
		case ActionTypes.UPDATE_PARENTS:
			let newstate = state.slice();
			let updatedList = newstate.filter((item) => {
				if (item._id !== action.payload) {
					return item;
				}
			});
			state = updatedList;
			console.log(updatedList);
			return updatedList;
		default:
			return state;
	}
};

export const CallHistory = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ADD_CALLHISTORY:
			console.log(action.payload);
			return action.payload;
		case ActionTypes.UPDATE_CALLHISTORY:
			return [action.payload, ...state];
		default:
			return state;
	}
};

export const Currency = (state = "$ Dollars", action) => {
	switch (action.type) {
		case ActionTypes.ADD_CURRENCY:
			return action.payload;
		case ActionTypes.UPDATE_CURRENCY:
			return action.payload;
		default:
			return state;
	}
};

export const NestedData = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ADD_NESTED:
			return action.payload;
		default:
			return state;
	}
};

export const NestedSection = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.SECTION_OBJECT:
			return action.payload;
		default:
			return state;
	}
};
export const NewEmail = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.NEW_EMAIL:
			return action.payload;
		case ActionTypes.FLUSH_EMAIL:
			return [];
		default:
			return state;
	}
};

export const newMeeting = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.NEW_MEETING:
			return action.payload;
		case ActionTypes.FLUSH_MEETING:
			return [];
		default:
			return state;
	}
};

export const config = (
	state = {
		id: null,
		config: [],
		submodule: [],
		keywords: {
			daycare: "daycare",
			daycareProfessional: "daycareProfessional",
			group: "group",
			section: "section",
		},
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_ID:
			return { ...state, id: action.payload };
		case ActionTypes.ADD_CONFIG:
			return { ...state, config: [...action.payload] };
		case ActionTypes.ADD_SUBMODULE:
			return { ...state, submodule: [...action.payload] };
		case ActionTypes.ADD_KEYWORDS:
			return { ...state, keywords: { ...action.payload } };
		default:
			return state;
	}
};

export const country = (state = { country: "", photo: "" }, action) => {
	switch (action.type) {
		case ActionTypes.ADD_COUNTRY:
			return typeof action.payload === "string"
				? { ...state, country: action.payload }
				: { country: action.payload[0], photo: action.payload[1] };
		default:
			return state;
	}
};

export const message = (
	state = { socket: null, messages: null, all: {} },
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_SOCKET:
			return { ...state, socket: action.payload };
		case ActionTypes.ADD_MESSAGE:
			return { ...state, messages: action.payload };
		case ActionTypes.ADD_ALL_MESSAGE:
			return {
				...state,
				all: {
					...state.all,
					[action.payload.id]: (state.all[action.payload.id]
						? state.all[action.payload.id]
						: []
					).concat(action.payload.messages),
				},
			};
		case ActionTypes.ADD_NEW_MESSAGE:
			const newMessage = [...state.messages],
				index = newMessage.findIndex((ele) => ele._id === action.payload.id);
			newMessage[index].lastChat = { ...action.payload.message[0] };
			let newData = newMessage[index];
			newMessage.splice(index, 1);
			newMessage.unshift(newData);
			console.log("updated data", newMessage, newMessage[index]);
			return {
				...state,
				messages: newMessage,
				all: {
					...state.all,
					[action.payload.id]: action.payload.message.concat(
						state.all[action.payload.id] ? state.all[action.payload.id] : []
					),
				},
			};
		default:
			return state;
	}
};



export const accessibleModules = (state = {}, action) => {

  switch (action.type) {
    case ActionTypes.ADD_ACCESSIBLE_MODULES_DATA:
      {
        state = {}
        const payload = action.payload;
        console.log(payload,"payload")
        Object.keys(payload).forEach(key => {
          Object.keys(payload[key].subModules).forEach(ele =>{
            state[`${payload[key].subModules[ele].label}`] = payload[key].subModules[ele].accessibility} )
        })

        return state;
      }
    default:
      return state;
  }

}

