import React from "react";
import DefaultPhoto from "../../../GeneralComponents/defaultMale.jpg";
import { useTranslation } from "react-i18next";
const PhotoName = ({ data }) => {
  const {t} = useTranslation();
  return (
    <div
      className="d-flex  align-items-center justify-content-center"
      style={{ width: "fit-content" }}
    >
      {data === "ALL" ? (
        <p className="m-0">{t("All")}</p>
      ) : (
        <>
          <img
            src={data?.photo === "" ? DefaultPhoto : data.photo}
            width="24px"
            height="24px"
            className="rounded-circle"
          />
          <p className="m-0 ml-1">{data.name}</p>
        </>
      )}
    </div>
  );
};

export default PhotoName;
