import React from "react";
import { useTranslation } from "react-i18next";
const CustomInput = ({
  name,
  label,
  value,
  onChange,
  type,
  required,
  onBlur,
  error,
  disabled,
  association,
  duplicateEmailError
}) => {

  const {t} = useTranslation();

  return (
    <>
      <div className="form-group" style={{ marginTop: "26px" }}>
        <input
          type={type || "text"}
          className="form-control"
          id={name}
          value={value}
          max={association ? `${new Date().getFullYear() - 14}-12-25` : ""}
          min="0"
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={type === "tel" ? "+1234567890" : type === "email" ? "example@mail.com" : ""}
          required={required ? true : null}
        />
        <label htmlFor={name}>
          {t(label)} {required ? <span style={{ color: "red" }}>*</span> : null}
        </label>
        {(type === 'email' && duplicateEmailError && name === 'fatherEmail') ? (<p className="red-text m-0" style={{ position: "relative", bottom: "30px" }}>
            {t(`Email can't be same`)}
          </p>) : "" }
        {error && value != "" ? (
          <p className="red-text m-0" style={{ position: "relative", bottom: "30px" }}>
            {/*{ `${t("Enter a valid ")}` + `${label}`}  */}
            {t(`Required field.`)}
          </p>
        ) : error && value == "" && required ? (
          <p className="red-text m-0" style={{ position: "relative", bottom: "30px" }}>
            {t(`Required field.`)}
          </p>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CustomInput;
