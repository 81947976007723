import React, { useRef } from "react";
import styles from "./searchBar.module.scss";
import { useTranslation } from "react-i18next";
const SearchBar = ({
	onChange,
	searchMode,
	onCancel,
	filterTypes = [],
	placeHolder = "",
	...props
}) => {
	const { t } = useTranslation();
	const inputRef = useRef();
	return (
		<div
			className="ml-3 mr-3 rounded-pill d-flex align-items-center "
			style={{
				height: "30px",
				padding: "16px 10px",
				backgroundColor: "white",
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
			}}
		>
			<i className="fa fa-search fa-1x"></i>
			<input
				ref={inputRef}
				type="text"
				style={{ width: "100%" }}
				className={`flex-grow-1 ml-2 border-0 ${styles.search}`}
				defaultValue=""
				placeholder={placeHolder}
				onChange={(event) => onChange(event.target.value)}
			/>
			{onCancel ? (
				<i
					className="fa fa-times"
					onClick={() => {
						inputRef.current.value = "";
						onCancel();
					}}
				></i>
			) : null}
			{props.onRightClick ? (
				<div className="d-flex justify-center align-items-center">
					<i
						className="fa fa-filter fa-1x"
						style={{ cursor: "pointer" }}
						onClick={props.onClickFilter}
					></i>
					{filterTypes.length ? (
						<select
							className="browser-default custom-select"
							style={{
								border: "none",
								paddingTop: "0px ",
								paddingBottom: "0px ",
								height: "30px",
								marginLeft: "10px",
								width: "fit-content",
							}}
							onChange={props.onFilterChange}
						>
							{filterTypes.map((ele, index) => (
								<option
									key={index}
									value={ele[0]}
									default={ele[0] === searchMode}
								>
									{t(ele[1])}
								</option>
							))}
						</select>
					) : null}
				</div>
			) : null}
		</div>
	);
};
export default SearchBar;
