import allowed from "./component/modulesData.json";
import { subModuleData } from "./component/defaultSideNavData";

export const allowedModulesHandler = (data) => {
  const finalData = [],
    finalSubModulesData = [];
  allowed.data.forEach((ele) => {
    console.log("module data", ele);
    const moduleData = data.modules[ele.name],
      subModules = moduleData?.subModules;
    console.log("module Data && submodule data", { moduleData, subModuleData });
    const eachModule = [];
    if (moduleData?.accessible) {
      if (ele.type) {
        finalData.push({
          path: ele.path,
          name: moduleData.label,
          value: ele.name,
          type: ele.type,
          data: ele.data,
        });
      } else {
        finalData.push({ path: ele.path, name: moduleData.label, value: ele.name });
      }

      console.log("login data error", subModuleData, ele.name, subModules);
      subModuleData[ele.name].forEach((sub, index) => {
        if (sub.name === "profile") return;
        if (subModules[sub.name]?.accessible) {
          console.log("each module", subModules[sub.name])
          eachModule.push({
            ...sub,
            name: subModules[sub.name].label,
            icon: ele.icons ? ele?.icons[index] : "no",
          });
        }
      });
    }

    finalSubModulesData.push([ele.baseurl, ele.sideNav, [...eachModule]]);
  });
  console.log("login data->final submodules data", finalSubModulesData);
  console.log("login data->final data", finalData, finalSubModulesData[2][2][0]);

  finalData.forEach(item => {
    const key = item.path.split('/')[1];
    finalSubModulesData.forEach(ele => {
      if (key == ele[0].split('/')[1]) {
        item.path = ele[2][0].url
      }
    })
  })



  return [finalData, finalSubModulesData];
};
