import {
	MDBBtn,
	MDBModalHeader,
	MDBTabContent,
	MDBTabPane,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBLink,
	MDBIcon,
} from "mdbreact";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ChildRegister.module.scss";
import { data, details } from "./childProfileData";
import { useSelector, useDispatch } from "react-redux";
import useForms from "./useForms";
import InputRenderer from "./InputRenderer";
import useFetch from "../../CustomHooks/useFetch";
import { setParents } from "../../../Redux/actions/actionCreator/data.action";
import Loader from "../ChildDashboard/DevelopmentDasboard/charts/Loader";
import DefaultPhoto from "./default.jpg";
import DefaultFemale from "../../../GeneralComponents/defaultFemale.png";
import DefaultMale from "../../../GeneralComponents/defaultMale.jpg";
import { Redirect } from "react-router-dom";
import { countries } from "./countriesListWithStates";
import Button from "../../../GeneralComponents/Button";
import SnackBar from "../../../GeneralComponents/SnackBar";
import DocumentsTab from "./DocumentsTab";
import { useTranslation } from "react-i18next";

const ChildRegister = ({
	childData,
	childEnrollment,
	registerData,
	handleToggle,
	isEnrollment,
	dataRegister,
	registrationScreen,
	...props
}) => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState("0");
	const group = useSelector((state) => state.group);
	const section = useSelector((state) => state.section);
	const parentsData = useSelector((state) => state.parentsData);
	const nestedData = useSelector((state) => state.nestedData);
	const keywords = useSelector((state) => state.config.keywords);
	const [isChildProfile, setIsChildProfile] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [formData, setFormData] = useState(null);
	const [childChangedValue, setChildChangedValue] = useState(null);
	const [motherChangedValue, setMotherChangedValue] = useState(null);
	const [fatherChangedValue, setFatherChangedValue] = useState(null);
	const [defaultPhotos, setDefaultPhotos] = useState([null, null, null]);
	const [modalState, setModalState] = useState(false);
	const [isSuccess, setIsSuccess] = useState(true);
	const [comment, setComment] = useState(null);
	const [contentType, setContentType] = useState(true);
	const [country, setCountry] = useState("");
	const [sessionList, setSessionList] = useState(null);
	const [childAddress, setChildAddress] = useState({
		line1: "",
		line2: "",
		city: "",
		country: "",
		postalCode: "",
		state: "",
	});

	const [snackBarContent, setSnackBarContent] = useState(null);
	const snackBarRef = useRef();
	const [isErrorSnackBar, setIsErrorSnackBar] = useState(false);
	const dispatch = useDispatch();
	// create child
	const accessibilityData = useSelector((state) => state).accessibility;
	const [siblingNamesArray, setSiblingNamesArray] = useState([]);
	const [siblingDOBArray, setSiblingDOBArray] = useState([]);

	const [deleteId, setDeleteId] = useState(null);
	const [redirect, setRedirect] = useState(false);
	const { response, error, isLoading } = useFetch({  
		url: "/child/create",
		method: "POST",
		body: formData,
		contentType: false,
		accessibility: accessibilityData["Child Profile"],
	});
	let address = {};

	const {
		values,
		handleChange,
		handleArrayChange,
		handleBlur,
		changedValue,
		errors,
		checkErrors,
	} = useForms({
		initialValues: initialValues,
		isChildProfile: isChildProfile,
	});
	// useEffect(() => console.log("data enrollment", isEnrollment), [isEnrollment]);
	//delete prospect after registration
	const deleteProspectResponse = useFetch({
		url: `/child/prospect/deleteProspectChild`,
		method: "POST",
		body: deleteId,
		accessibility: accessibilityData["Child Profile"],
	});

	const urlToFile = async (url, fileName) => {
		const response = await fetch(url);
		const blob = await response.blob();
		const image = await Promise.resolve(new File([blob], fileName));
		return image;
	};
	useEffect(() => console.log("default image", defaultPhotos), [defaultPhotos]);
	//this updates child details in request
	const childResponse = useFetch({
		url: `/updatechilddetails/${childData?._id}`,
		method: "PATCH",
		body: childChangedValue,
		accessibility: accessibilityData["Child Profile"],
		contentType: contentType,
	});
	const motherResponse = useFetch({
		url: `/updatemother/${childData?._id}`,
		method: "PATCH",
		body: motherChangedValue,
		contentType: contentType,
		accessibility: accessibilityData["Child Profile"],
	});
	const fatherResponse = useFetch({
		url: `/updatefather/${childData?._id}`,
		method: "PATCH",
		body: fatherChangedValue,
		contentType: contentType,
		accessibility: accessibilityData["Child Profile"],
	});
	const enrollChild = useFetch({
		url: `/enrollChild/edit/addComment/${childEnrollment?._id}`,
		method: "PATCH",
		body: comment,
		accessibility: accessibilityData["Child Profile"],
	});
	const getActiveSessions = useFetch({
		url: "/sessions/viewAll",
	});
	useEffect(() => {
		if (getActiveSessions.response) {
			const sessions = getActiveSessions.response.sessions;
			setSessionList(sessions);
			let sessionName = [];
			for (let i = 0; i < sessions.length; i++) {
				if (sessions[i].status === "Active") {
					sessionName.push(sessions[i].sessionName);
				}
			}
			//const sessionName = sessions.filter((ele) => ele.sessionName);
			data[4].data[10].options = sessionName;
			console.log(sessionName, data[4]);
		}
	}, [
		getActiveSessions.response,
		getActiveSessions.isLoading,
		getActiveSessions.error,
	]);

	//update address variables after childResponse;
	useEffect(() => {
		if (!childResponse.isLoading && childResponse.response) {
			address = childResponse.response.address;
			console.log({ address });
		}
	}, [childResponse.response, childResponse.isLoading]);

	useEffect(() => {
		//this is based on the index of group and section in the ./childProfileData file. if the data changes the index has to be changed.
		data[4].data[0].label = keywords.section;
		data[4].data[1].label = keywords.group;
		details[4] = `${keywords.daycare} Details`;
		console.log("daycare details", data);
	}, [keywords.section]);

	useEffect(() => {
		const getImages = async () => {
			const babyImage = await urlToFile(DefaultPhoto, "fatherPhoto");
			const motherImage = await urlToFile(DefaultFemale, "motherPhoto");
			const maleImage = await urlToFile(DefaultMale, "motherPhoto");
			setDefaultPhotos([babyImage, motherImage, maleImage]);
		};
		getImages();
	}, []);

	const [changedStatus, setChangedStatus] = useState({});
	const statusResponse = useFetch({
		url: `/editEnrollmentButton/${changedStatus.id}`,
		body: changedStatus.body,
		method: "PATCH",
	});

	useEffect(() => {
		if (response) {
			setModalState(true);
			setIsSuccess(true);
			if (registerData) {
				setDeleteId(JSON.stringify({ prospectChildID: registerData._id }));
			}
			if (dataRegister) {
				setChangedStatus({
					id: dataRegister._id,
					body: JSON.stringify({ status: "confirmed" }),
				});
			}
		}
	}, [response]);

	useEffect(() => {
		if (error) {
			console.log("Error", error);
			setModalState(true);
			setIsSuccess(false);
		}
	}, [error]);

	useEffect(() => {
		if (values.section != "Select an option") {
			let groups;
			if (nestedData[values.section]) {
				groups = [...nestedData[values.section]];
			}
			data[4].data[1].options = groups || [];
			console.log("datasections", nestedData[values.section]);
		}
	}, [values.section]);

	useEffect(() => {
		console.log("mother photo", motherResponse.response);
		if (motherResponse.error) {
			console.log(motherResponse.error.toUpperCase());
			setSnackBarContent(motherResponse.error);
			setIsErrorSnackBar(true);
			snackBarRef.current.openSnackBar();
		}
		if (motherResponse?.error || motherResponse?.response) {
			setMotherChangedValue(null);
		}
	}, [motherResponse.response, motherResponse.error, motherResponse.isLoading]);

	useEffect(() => {
		console.log("mother photo", fatherResponse.response);
		if (fatherResponse.error) {
			console.log(fatherResponse.error);
			setSnackBarContent(fatherResponse.error);
			setIsErrorSnackBar(true);
			snackBarRef.current.openSnackBar();
		}
		if (fatherResponse?.error || fatherResponse?.response) {
			setFatherChangedValue(null);
		}
	}, [fatherResponse.response, fatherResponse.error, fatherResponse.isLoading]);

	const changePhotoHandler = (id, photo) => {
		console.log("parents id", id);
		const index = parentsData.findIndex((ele) => ele._id == id);
		const newParentsData = [...parentsData];
		newParentsData[index] = {
			...newParentsData[index],
			photo: URL.createObjectURL(photo),
		};
		console.log("new parents", newParentsData[index]);
		dispatch(setParents(newParentsData));
	};

	useEffect(() => {
		const sections = [];
		for (const [key, value] of Object.entries(nestedData)) sections.push(key);
		data[4].data[0].options = [...sections];
		console.log(
			"datasections",
			sections,
			data[4].data[0],
			section.map((ele) => ele.section)
		);
	}, [nestedData]);

	useEffect(() => {
		if (childData) {
			setChildAddress((prevState) => ({
				...prevState,
				line1: childData.addressLine1,
				line2: childData.addressLine2,
				city: childData.addressCity,
				country: childData.addressCountry,
				postalCode: childData.addressPostalCode,
				state: childData.addressState,
			}));
			console.log({ childData, childAddress });
			console.log("initial value", initialValues, values, childData);
			setInitialValues(childData);
			setSiblingNamesArray(childData.siblingName);
			setSiblingDOBArray(childData.siblingDOB);
			setIsChildProfile(true);
			setCountry(childAddress.country);
		}
	}, [initialValues]);

	const [primaryContact, setPrimaryContact] = useState(null);
	useEffect(() => {
		if (primaryContact) {
			console.log("primary contact has been changed to", primaryContact);
			console.log(data);
			const dt = data[1].section;
			let filteredData, requiredData;
			if (primaryContact == "Mother") {
				filteredData = dt[2].data;
				requiredData = dt[1].data;
			} else if (primaryContact == "Father") {
				filteredData = dt[1].data;
				requiredData = dt[2].data;
			}
			for (let i = 0; i < filteredData.length; i++) {
				if (filteredData[i].required) {
					filteredData[i].required = false;
				}
				if (requiredData[i].required) {
					requiredData[i].required = true;
				}
			}
			console.log(filteredData);
		}
	}, [primaryContact]);
	useEffect(() => {
		if (changedValue) {
			let primaryContact;
			let contenttype = true;
			let type = "child";
			console.log("changed values", changedValue);
			const name = Object.keys(changedValue)[0];
			if (name.includes("mother")) type = "mother";
			// if (name.includes("primaryContact")) {
			//   primaryContact = changedValue.primaryContact;
			//   const dt = data[1].section;
			//   let filteredData, requiredData;
			//   if (primaryContact == "Mother") {
			//     filteredData = dt[2].data;
			//     requiredData = dt[1].data;
			//   } else if (primaryContact == "Father") {
			//     filteredData = dt[1].data;
			//     requiredData = dt[2].data;
			//   }
			//   for (let i = 0; i < filteredData.length; i++) {
			//     if (filteredData[i].required) {
			//       filteredData[i].required = false;
			//     }
			//     if (requiredData[i].required) {
			//       requiredData[i].required = true;
			//     }
			//   }
			//   console.log(filteredData);
			// }
			if (name.includes("father")) type = "father";
			if (name == "comment") type = "comment";
			if (name.includes("address")) type = "address";
			if (name.includes("session")) type = "session";
			if (name.includes("group") || name.includes("section"))
				type = "groupsection";
			if (name.includes("sibling")) type = "sibling";
			if (name.includes("Hear") || name.includes("KnowAbout"))
				type = "heardAbout";
			//checks if the photo has been changed
			if (name.toLowerCase().includes("photo")) {
				const formData = new FormData();
				if (type == "child") {
					console.log("changed values data", changedValue);
					formData.append("photo", changedValue.photo);
					setChildChangedValue(formData);
				} else if (type == "mother") {
					console.log("mothers photo");
					formData.append("photo", changedValue.motherPhoto);
					if (childData) {
						changePhotoHandler(childData.motherId, changedValue.motherPhoto);
						setMotherChangedValue(formData);
					}
				} else {
					console.log("father photo");
					formData.append("photo", changedValue.fatherPhoto);
					if (childData) setFatherChangedValue(formData);
				}
				contenttype = false;
				type = "null";
			}

			setContentType(contenttype);
			switch (type) {
				case "child": {
					setChildChangedValue(JSON.stringify(changedValue));
					break;
				}
				case "sibling": {
					console.log(changedValue);
					console.log(Object.keys(changedValue)[0]);
					const key = Object.keys(changedValue)[0];
					const arrayValues = Object.values(changedValue)[0];
					console.log({ arrayValues });
					let updateObject = [];
					if (key === "siblingDOB") {
						let siblingNames = siblingNamesArray;
						console.log({ siblingNamesArray, siblingDOBArray, siblingNames });
						setSiblingDOBArray(arrayValues);
						setSiblingNamesArray(siblingNames);
						for (let i = 0; i < arrayValues.length; i++) {
							let obj = {
								siblingName: siblingNames[i],
								siblingDOB: arrayValues[i],
							};
							updateObject.push(obj);
						}
					} else if (key == "siblingName") {
						let siblingDOB = siblingDOBArray;
						console.log({ siblingNamesArray, siblingDOBArray, siblingDOB });
						setSiblingNamesArray(arrayValues);
						setSiblingDOBArray(siblingDOB);
						for (let i = 0; i < arrayValues.length; i++) {
							let obj = {
								siblingName: arrayValues[i],
								siblingDOB: siblingDOB[i],
							};
							updateObject.push(obj);
						}
					}
					console.log({ updateObject });
					setChildChangedValue(JSON.stringify({ siblings: updateObject }));
					break;
				}
				case "heardAbout": {
					const values = Object.values(changedValue)[0];
					const key = Object.keys(changedValue)[0];
					if (key == "howDidYouKnowAboutUsOthers") {
						setChildChangedValue(
							JSON.stringify({ howDidYouHearAboutUS: "Others" + "-" + values })
						);
					} else {
						setChildChangedValue(
							JSON.stringify({ howDidYouHearAboutUS: values })
						);
					}
					break;
				}
				case "mother": {
					const fieldChange = {
						motherName: "firstName",
						motherLastName: "lastName",
						motherEmail: "email",
						motherMobile: "mobile",
					};
					setMotherChangedValue(
						JSON.stringify({ [fieldChange[name]]: changedValue[name] })
					);
				}
				case "father": {
					const fieldChange = {
						fatherName: "firstName",
						fatherLastName: "lastName",
						fatherEmail: "email",
						fatherMobile: "mobile",
					};
					setFatherChangedValue(
						JSON.stringify({ [fieldChange[name]]: changedValue[name] })
					);
				}
				case "comment": {
					setComment(JSON.stringify({ comment: changedValue.comment }));
				}
				case "address": {
					if (name.includes("Country")) {
						setCountry(changedValue.addressCountry);
						const value = changedValue.addressCountry;
						const dt = countries.find((ele) => ele.country === value);
						const add = data.find((ele) => ele.name === "address");
						console.log({ dt, add });
						if (dt && add) {
							const val = dt.states;
							const values = add.data.find(
								(ele) => ele.name === "addressState"
							);
							if (values) {
								values.options = val;
							}
						}
					}
					if (name.includes("Country")) {
						setChildAddress((prevState) => ({
							...prevState,
							country: changedValue.addressCountry,
						}));
					} else if (name.includes("Line1")) {
						setChildAddress((prevState) => ({
							...prevState,
							line1: changedValue.addressLine1,
						}));
					} else if (name.includes("Line2")) {
						setChildAddress((prevState) => ({
							...prevState,
							line2: changedValue.addressLine2,
						}));
					} else if (name.includes("City")) {
						setChildAddress((prevState) => ({
							...prevState,
							city: changedValue.addressCity,
						}));
					} else if (name.includes("Code")) {
						setChildAddress((prevState) => ({
							...prevState,
							postalCode: changedValue.addressPostalCode,
						}));
					} else if (name.includes("State")) {
						setChildAddress((prevState) => ({
							...prevState,
							state: changedValue.addressState,
						}));
					}
					console.log({ childAddress });
					setChildChangedValue(JSON.stringify({ address: childAddress }));
				}
				case "session": {
					//console.log(sessionList);
					const newValue = Object.values(changedValue)[0];
					console.log(newValue);
					const val = sessionList.find((ele) => ele.sessionName === newValue);
					console.log(val);
					setChildChangedValue(JSON.stringify({ sessionId: val?._id }));
					break;
				}
				case "groupsection": {
					const val = Object.values(changedValue)[0];
					console.log(val);
					if (name.includes("group")) {
						setChildChangedValue(JSON.stringify({ groupName: val }));
					} else if (name.includes("section")) {
						setChildChangedValue(JSON.stringify({ sectionName: val }));
					}
				}
			}
		}
	}, [changedValue]);

	useEffect(() => {
		if (childEnrollment) {
			console.log("initial value", initialValues, childEnrollment);
			setIsChildProfile(isEnrollment);
			setInitialValues(childEnrollment);
		}
	}, [initialValues]);

	//fills up the data if the student is enrolled
	useEffect(() => {
		if (group && section) {
			let values = {};
			let sectionData = {};
			const newData = [...data];
			console.log(newData);
			//map through all the data in data.js
			newData.forEach((ele) => {
				values = {
					...values,
					...(ele.section
						? ele.section.forEach((element) => {
								const listOfValues = element.data;
								let emptyObj = {};
								const valuesTakeOut = listOfValues.map(
									(el) =>
										Array.isArray(el) === false && {
											...emptyObj,
											[el.name]: el.initialValue,
										}
								);
								let finalObj = {};
								for (let i = 0; i < valuesTakeOut.length; i++) {
									finalObj = { ...finalObj, ...valuesTakeOut[i] };
								}
								const returnValue = { ...finalObj };
								console.log({ returnValue, ...returnValue, ...[returnValue] });
								sectionData = { ...sectionData, ...returnValue };
						  })
						: ele.data.reduce(
								(obj, current) =>
									Array.isArray(current)
										? {
												...obj,
												...current.reduce(
													(obj, current) => ({
														...obj,
														[current.name]: current.initialValue,
													}),
													{}
												),
										  }
										: { ...obj, [current.name]: current.initialValue },
								{}
						  )),
				};
			});
			values = { ...values, ...sectionData };
			if (registerData) {
				values = { ...values, ...registerData };
				setCountry(registerData?.addressCountry);
			}
			console.log({ newData, registerData, sectionData });
			console.log(`values`, { values, registerData });
			if (!dataRegister) {
				values.siblingName = [""];
				values.siblingDOB = [""];
				setInitialValues(() => values);
			} else {
				setInitialValues(() => ({ ...values, ...dataRegister }));
			}
		}
	}, [data, section, group]);

	//change state dropdown when country is changed
	useEffect(() => {
		// console.log(typeof country);
		const dt = countries.find((ele) => ele.country === country);
		const add = data.find((ele) => ele.name === "address");
		console.log({ dt, add });
		if (dt && add) {
			const val = dt.states;
			const values = add.data.find((ele) => ele.name === "addressState");
			if (values) {
				values.options = val;
			}
		}
	}, [country]);

	const submitHandler = (index) => {
		//const error = checkErrors(index);
		let error = false;
		console.log(error);
		console.log("Submit handler called!", values);
		const formdata = new FormData();
		Object.keys(values).forEach((key) => {
			if (key == "photo") {
				if (values[key] == "") formdata.append("photos", defaultPhotos[0]);
				else formdata.append("photos", values[key]);
			} else if (key == "motherPhoto") {
				if (values[key] == "") formdata.append("photos", defaultPhotos[0]);
				else formdata.append("photos", values[key]);
			} else if (key == "fatherPhoto") {
				if (values[key] == "") formdata.append("photos", defaultPhotos[0]);
				else formdata.append("photos", values[key]);
			} else if (key == "addressLine1") {
				formdata.append("line1", values[key]);
			} else if (key == "addressLine2") {
				formdata.append("line2", values[key]);
			} else if (key == "addressCity") {
				formdata.append("city", values[key]);
			} else if (key == "addressCountry") {
				formdata.append("country", values[key]);
			} else if (key == "addressPostalCode") {
				formdata.append("postalCode", values[key]);
			} else if (key == "addressState") {
				formdata.append("state", values[key]);
			} else if (key == "session") {
				const newValue = values[key];
				console.log(newValue);
				const val = sessionList.find((ele) => ele.sessionName === newValue);
				console.log({ val });
				if (val === undefined && newValue !== undefined) {
					formdata.append("sessionId", newValue?._id);
				} else if (val !== undefined) {
					formdata.append("sessionId", val?._id);
				}
			} else if (key == "associatedSession") {
				if (values[key]?._id) {
					formdata.append("sessionId", values[key]?._id);
				} else {
					return;
				}
			} else if (key == "group") {
				formdata.append("groupName", values[key]);
			} else if (key == "section") {
				formdata.append("sectionName", values[key]);
			} 
			// RM Comment - making vaccine info non-mandatory for Sparkle implmentation
			/*else if (key == "vaccinationType") {
				console.log(values[key][0].length === 0);
				if (values[key][0].length === 0) {
					error = true;
					return;
				} else {
					error = false;
					console.log("new key", key);
					formdata.append(key, values[key]);
				}
			} else if (key == "vaccinationLastDate") {
				console.log(values[key]);
				if (values[key][0].length === 0) {
					error = true;
					return;
				} else {
					error = false;
					console.log("new key", key);
					formdata.append(key, values[key]);
				}
			}*/ else if (key === "primaryContact") {
				formdata.append("primaryContact", "Mother");
			} else if (key === "exteriorTripAuthorization") {
				console.log("new key", key);
				formdata.append(key, values[key]);
				formdata.append("consent", true);
			} else if (key === "howDidYouHearAboutUs") {
				if (values[key] === "Others") {
					const othersInfo = values["howDidYouKnowAboutUsOthers"];
					formdata.append(
						"howDidYouHearAboutUS",
						values[key] + "-" + othersInfo
					);
				} else {
					formdata.append("howDidYouHearAboutUS", values[key]);
				}
			} else if (key == "siblingName") {
				let siblings = [];
				if (values.siblingName && values.siblingDOB) {
					const siblingNamesArray = values["siblingName"];
					const siblingDOBArray = values["siblingDOB"];
					for (let i = 0; i < siblingNamesArray.length; i++) {
						siblings.push({
							siblingName: siblingNamesArray[i] || "",
							siblingDOB: siblingDOBArray[i] || "",
						});
					}
				}
				formdata.append("siblings", JSON.stringify(siblings));
			} else if (
				key != "associatedSession" ||
				key != "group" ||
				key != "section" ||
				key != "session"
			) {
				console.log("new key", key);
				if (key != "siblings") {
					formdata.append(key, values[key]);
				}
			}
		});
		if (formdata.get("group")) {
			formdata.delete("group");
		}
		if (formdata.get("section")) {
			formdata.delete("section");
		}
		if (formdata.get("associatedSession")) {
			formdata.delete("associatedSession");
		}
		if (formdata.get("siblingsDOB")) {
			formdata.delete("siblingDOB");
		}
		const allKeys = Object.keys(values);
		const motherPhotoIndex = allKeys.findIndex((ele) => ele === "motherPhoto");
		const fatherPhotoIndex = allKeys.findIndex((ele) => ele === "fatherPhoto");
		if (motherPhotoIndex === -1) formdata.append("photos", defaultPhotos[1]);
		if (fatherPhotoIndex === -1) formdata.append("photos", defaultPhotos[2]);

		//Keeping this console logs here for faster debugging in future
		//
		// This logs all the all the values filled in the childRegistration form as => key, value
		// Object.keys(values).forEach((key) => {
		//   console.log(key, values[key]);
		// });
		//
		// This loop logs all the formData as => key, value
		for (var pair of formdata.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}
		if (error === false) {
			console.log(formdata);
			setFormData(formdata);
			console.log("Going to rediect to child listing");
			setRedirect(true);
		} else {
			console.log("Error");
		}
	};
	const nextTabHandler = (index) => {
		if (!checkErrors(index)) {
			setActiveTab(`${index + 1}`);
		}
	};

	const previousTabHandler = (index) => {
		setActiveTab(`${index - 1}`);
	};

	return (
		<div
			style={{
				width: "100%",
				minHeight: "100vh",
				paddingTop: `${
					childData || childEnrollment || registrationScreen ? 0 : 70
				}px`,
			}}
		>
			{/*{redirect ? <Redirect to="/childManagement/childProfile" /> : null} */}
			<MDBModal
				size="sm"
				centered
				isOpen={modalState}
				toggle={() => {
					setModalState(false);
					handleToggle && response ? handleToggle() : null;
				}}
			>
				<MDBModalHeader
					className={`${isSuccess ? "green" : "red"}`}
					titleClass="d-flex justify-content-between align-items-center white-text w-100"
				>
					<div
						className="m-0 p-0"
						style={{ fontSize: "21px", fontWeight: "bold" }}
					>
						{isSuccess ? "Success" : "Error"}
					</div>
					<MDBIcon
						icon="times"
						size="1x"
						onClick={() => {
							setModalState(false);
							handleToggle && response && !error ? handleToggle() : null;
						}}
					/>
				</MDBModalHeader>
				<MDBModalBody>
					{isSuccess
						? "Child registered successfully"
						: typeof error === "string"
						? error?.charAt(0).toUpperCase() + error?.slice(1)
						: "Critcal error has occured"}
				</MDBModalBody>
				<MDBModalFooter className="d-flex justify-content-between flex-row-reverse flex-fill">
					<Button
						onClick={() => {
							setModalState(false);
							handleToggle && response && !error ? handleToggle() : null;
							console.log("Clicked on OK and refreshing window");
							//{redirect ? <Redirect to="/childManagement/childProfile" /> : null}
							// RM: refresh the windows to have the data 
							if (isSuccess) window.location.reload();
						}}
					>
						{t("Ok")}
					</Button>
				</MDBModalFooter>
			</MDBModal>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<SnackBar
						content={snackBarContent}
						isError={isErrorSnackBar}
						ref={snackBarRef}
					/>
					<div
						className="d-flex align-items-center w-100"
						style={{ overflow: "auto" }}
					>
						{details.map((ele, index) => (
							<button
								style={{
									display: `${ele === "Documents" && !childData ? "none" : ""}`,
								}}
								className={[
									styles.button,
									activeTab == `${index}` ? styles.active : {},
								].join(" ")}
								onClick={() =>
									childData || childEnrollment || !checkErrors(index)
										? setActiveTab(`${index}`)
										: null
								}
							>
								{t(`${ele}`)}
							</button>
						))}
					</div>
					<MDBTabContent
						activeItem={activeTab}
						className="h-100"
						// style={{ overflow: "auto" }}
					>
						{data.map((ele, index) => (
							<MDBTabPane
								tabId={`${index}`}
								className="h-100"
								// style={{ overflow: "hidden" }}
							>
								<form
									onSubmit={(event) => event.preventDefault()}
									// className={`${styles.form}`}
								>
									{ele.section
										? ele.section.map((element, index) => (
												<div>
													{element.sectionHeading && (
														<div>
															<h6 className="text-center pt-3 mb-0">
																{t(element.sectionHeading)}
															</h6>
														</div>
													)}

													<div
														key={index}
														className={styles.card}
														style={
															element.data[0].type == "file"
																? { gridTemplateColumns: "auto 1fr 1fr" }
																: {}
														}
													>
														<InputRenderer
															disabled={
																accessibilityData["Child Profile"] === "Read"
															}
															handleChange={handleChange}
															handleArrayChange={handleArrayChange}
															handleBlur={handleBlur}
															values={values}
															data={element.data}
															errors={errors}
															isChildProfile={childData ? true : false}
															isEnrollment={isEnrollment}
														/>
													</div>
												</div>
										  ))
										: null}
									{!ele.section ? (
										<div
											className={styles.card}
											style={
												ele.data[0].type == "file"
													? { gridTemplateColumns: "auto 1fr 1fr" }
													: {}
											}
										>
											<InputRenderer
												disabled={accessibilityData["Child Profile"] === "Read"}
												handleChange={handleChange}
												handleArrayChange={handleArrayChange}
												handleBlur={handleBlur}
												values={values}
												data={ele.data}
												errors={errors}
												isChildProfile={childData ? true : false}
												isEnrollment={childEnrollment ? true : false}
											/>{" "}
										</div>
									) : null}
									<div>
										{childData || childEnrollment ? null : (
											<div
												style={{
													gridColumnStart: `${
														ele?.data[0].type == "file" ? 2 : 1
													}`,
													gridColumnEnd: `${
														ele?.data[0].type == "file" ? 4 : 3
													}`,
													display: "flex",
													flexDirection: "row-reverse",
													// justifyContent : 'space-around'
												}}
												className={`d-flex justify-content-${
													index == 0 ? "start" : "between"
												} align-items-center`}
											>
												<MDBBtn
													onClick={() =>
														data.length - 1 === index
															? submitHandler(index)
															: nextTabHandler(index)
													}
													color="indigo"
													className="white-text text-capitalize mr-3 float-right"
													style={{
														gridColumnStart: "1",
														gridColumnEnd: `${
															ele?.data[0].type == "file" ? 4 : 3
														}`,
														width: "100px",
														justifySelf: "end",
													}}
													type="submit"
													disabled={
														data.length - 1 === index &&
														accessibilityData["Child Profile"] === "Read"
													}
												>
													{`${
														data.length - 1 === index
															? `${t("submit")}`
															: `${t("next")}`
													}`}
												</MDBBtn>

												{index == 0 ? null : (
													<MDBBtn
														onClick={() => previousTabHandler(index)}
														color="indigo"
														className="white-text text-capitalize ml-3 float-right"
														style={{
															width: "100px",
															marginLeft: "20px",
														}}
														type="submit"
													>
														{t("back")}
													</MDBBtn>
												)}
											</div>
										)}
									</div>
								</form>
							</MDBTabPane>
						))}
						<MDBTabPane tabId={"6"} className="h-100">
							<DocumentsTab data={childData} />
						</MDBTabPane>
					</MDBTabContent>
				</>
			)}
		</div>
	);
};

export default ChildRegister;
