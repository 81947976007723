import React, { useMemo, useState } from "react";
//import DropDown from "../../Activities/ManageActivity/DropDown";
import DropDown from "../../../GeneralComponents/MultipleDropdown/DropDown";
import { MDBIcon } from "mdbreact";
import styles from "./MyDay.module.scss";
import OuterWrapper from "../FinancialReporting/charts/OuterWrapper";
import ActivityStatus from "./ActivityStatus";
import { useEffect } from "react";
import StaffChart from "./StaffChart";
import usePyFetch from "../../CustomHooks/usePyFetch";
import Meetings from "./Meetings";
import ChildrenChart from "./ChildrenChart";
import ActivityData from "./ActivityData";
import { useSelector } from "react-redux";
import useFetch from "../../CustomHooks/useFetch";
import { useTranslation } from "react-i18next";
const MyDay = (props) => {
	const { t } = useTranslation();
	const [requestBody, setRequestBody] = useState(null);
	const [numberOfChidren, setNumberOfChildren] = useState(null);
	const [percentage, setPercentage] = useState(null);
	const [data, setData] = useState(null);
	const [sectionData, setSectionData] = useState([]);
	const [group, setGroup] = useState("All");
	const [groupOptions, setGroupOptions] = useState(["All"]);
	const nestedData = useSelector((state) => state.nestedData);
	const keywords = useSelector((state) => state.config.keywords);
	const [section, setSection] = useState(t(`All ${keywords.daycare}`));

	useEffect(() => {
		console.log("nested data", nestedData);
		const sections = [t(`All ${keywords.daycare}`)];
		for (let val in nestedData) {
			sections.push(val);
		}
		setSectionData(sections);
	}, [nestedData]);

	const activityResponse = useFetch({
		url: "/day_activities_planned",
		method: "POST",
		body: requestBody,
	});
	const [date, setDate] = useState(
		new Date().toLocaleDateString("en-IN", {
			day: "2-digit",
			month: "long",
			year: "numeric",
		})
	);

	useEffect(() => {
		if (activityResponse.response) {
			const response = activityResponse.response;
			console.log("act data", response);
			setData(response);
			setNumberOfChildren(response.child_photo_name.length);
			const performed =
				response.group_data.length + response.selected_child_data.length;
			if (!performed && !response.planned_act.length) {
				setPercentage(0);
			} else {
				setPercentage(
					Math.round(
						(performed / (performed + response.planned_act.length)) * 100
					)
				);
			}
		}
	}, [activityResponse.response]);
	const requestDate = useMemo(() => {
		const dateNew = new Date(date);
		return `${dateNew.getFullYear()}-${(dateNew.getMonth() + 1 + "").padStart(
			2,
			"0"
		)}-${(dateNew.getDate() + "").padStart(2, "0")}`;
	}, [date]);
	useEffect(() => console.log("meal date", requestDate), [requestDate]);
	useEffect(() => {
		setRequestBody(JSON.stringify({ start_date: requestDate }));
	}, [requestDate]);
	const changeDate = (direction) => {
		const newDate = new Date(date);
		newDate.setDate(newDate.getDate() + direction);
		setDate(
			newDate.toLocaleDateString("en-IN", {
				day: "2-digit",
				month: "long",
				year: "numeric",
			})
		);
	};
	useEffect(() => {
		console.log("finaldata2", percentage);
	}, [percentage]);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100vh",
				paddingTop: "60px",
				paddingBottom: "50px",
				overflow: "hidden",
			}}
		>
			<div className={`${styles.header} w-100`}>
				<div className="d-flex flex-1 mx-auto justify-content-center">
					<div className="d-flex justify-content-center align-items-center">
						<MDBIcon icon="chevron-left" onClick={() => changeDate(-1)} />
						<h6 className="m-0 pl-3 pr-3">{date}</h6>
						<MDBIcon icon="chevron-right" onClick={() => changeDate(1)} />
					</div>
				</div>
				<div className="d-flex align-items-center">
					<DropDown
						list={sectionData}
						title={section}
						selectHandler={(event) => {
							const sec = event.target.innerHTML;
							setSection(sec);
							setGroup("All");
							if (sec === t(`All ${keywords.daycare}`)) {
								setGroupOptions(["All"]);
							} else setGroupOptions(["All", ...nestedData[sec]]);
						}}
					/>
					<DropDown
						list={groupOptions}
						title={group}
						selectHandler={(event) => {
							const group = event.target.innerHTML;
							setGroup(group);
						}}
					/>
				</div>
			</div>
			<div className={styles.gridWrapper}>
				<OuterWrapper minHeight title={t("Activity status")}>
					<ActivityStatus percentage={percentage} />
				</OuterWrapper>
				<OuterWrapper minHeight>
					<Meetings date={requestDate} />
				</OuterWrapper>
				<OuterWrapper minHeight style={{ gridRowStart: "2", gridRowEnd: "4" }}>
					<ActivityData
						data={data}
						sectionName={section}
						groupName={group}
						setPercentage={(data) => setPercentage(data)}
					/>
				</OuterWrapper>
				<OuterWrapper
					{...(window.matchMedia("(max-width: 768px)").matches
						? {}
						: { minHeight: "auto" })}
					title={t("Children Attendance")}
				>
					<ChildrenChart
						date={requestDate}
						numberOfChildren={numberOfChidren}
					/>
				</OuterWrapper>
				<OuterWrapper
					{...(window.matchMedia("(max-width: 768px)").matches
						? {}
						: { minHeight: "auto" })}
					title={t(`${keywords.daycareProfessional}`) + t(" presence status")}
				>
					<StaffChart date={requestDate} />
				</OuterWrapper>
				<OuterWrapper minHeight></OuterWrapper>
			</div>
		</div>
	);
};

export default MyDay;
