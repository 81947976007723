import React from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBIcon,
} from "mdbreact";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../GeneralComponents/Button";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  modalHeader: {
    backgroundColor: "#304391!important",
    textAlign: "center",
    color: "#fff!important",
  },
  button: {
    backgroundColor: "#304391!important",
    color: "#fff",
  },
});

const WarningModal = ({ isOpen, handleToggle, onConfirmation, ...props }) => {
  const { t } = useTranslation();
  return (
    <MDBModal isOpen={isOpen} toggle={handleToggle} centered>
      <MDBModalHeader
        className="indigo"
        titleClass="white-text d-flex justify-content-between"
        style={{ padding: "0.5rem!important" }}
      >
        <p className="m-0 p-0" style={{ fontSize: "21px", fontWeight: "bold" }}>
          {t("Confirm Delete")}
        </p>
      </MDBModalHeader>
      <MDBModalBody>{t("Do you wish to delete the document?")}</MDBModalBody>

      <MDBModalFooter className="d-flex justify-content-between">
        <Button onClick={handleToggle}>{t("Cancel")}</Button>
        <Button
          onClick={() => {
            onConfirmation();
            handleToggle();
          }}
        >
          {t("Yes")}
        </Button>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default WarningModal;
