import React from "react";
import { useTranslation } from "react-i18next";

const TableItem = ({
  data,
  onClick,
  handleDeleteArchive,
  tableHeader = [],
  style = null,
  boldColumn,
}) => {
  return (
    <tr className="pt-2 pb-2">
      {data.map((ele, index) => (
        typeof ele === "string" ? (
          <td
            key={index}
            data-title={tableHeader[index]}
            onClick={typeof ele === "string" ? onClick : handleDeleteArchive}
            style={{
              fontWeight: `${index === boldColumn ? "bold" : ""}`,
              ...style,
            }}>
            {ele}
          </td>
        ) : (
          <td key={index} data-title={tableHeader[index]}>{ele}</td>
        )
      ))}
    </tr>
  );
}
export default TableItem;
