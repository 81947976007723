import React, { useRef, useState, useEffect } from "react";
import StripeIcon from "../../../public/stripe.svg";
import AddIcon from "@material-ui/icons/Add";
import { MDBBtn, MDBPopover, MDBPopoverBody, MDBIcon } from "mdbreact";
import { makeStyles } from "@material-ui/core";
import SnackBar from "../../../GeneralComponents/SnackBar";
import Table from "../../childdetail/ChildProfile/Table";
import TableItem from "../../childdetail/ChildProfile/TableItem";
import useFetch from "../../CustomHooks/useFetch";
import Button from "../../../GeneralComponents/Button";
import CustomDropDown from "./../../StaffManagement/Profile/CustomDropDown";
import InputFileName from "./../../StaffManagement/Profile/InputFileName";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningModal from "./../../StaffManagement/Profile/WarningModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OPTIONS = { year: "numeric", month: "long", day: "numeric" };
const useStyles = makeStyles({
  button: {
    backgroundColor: "transparent!important",
    boxShadow: "none!important",
  },
});

const DocumentsTab = ({ data, ...props }) => {
  const { t } = useTranslation();
  const accessibilityData = useSelector((state) => state).accessibility;
  const classes = useStyles();
  const inputRef = useRef();
  const snackBarRef = useRef();
  const [snackBarContent, setSnackBarContent] = useState("");
  const staffId = props._id;
  useEffect(() => {
    setAllDocumentBody(null);
    setAllDocumentBody(JSON.stringify({ daycareProfessionalID: staffId }));
  }, [staffId]);
  const [getAllDocumentBody, setAllDocumentBody] = useState(
    JSON.stringify({ daycareProfessionalID: staffId })
  );

  const [fileType, setFileType] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [verifyStaffBody, setVerifyStaffBody] = useState(null);
  const [docType, setDocType] = useState(t("Select an Option"));
  const [docData, setDocData] = useState([]);
  const [uploadDocumentBody, setUploadDocumentBody] = useState(null);
  const [direction, setDirection] = useState([null, null, null, null]);
  const [filteredData, setFilteredData] = useState(null);

  const [selectedDocs, setSelectedDocs] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);

  const [uploadToStripeBody, setUploadToStripeBody] = useState(null);
  const [getDocumentURLBody, setDocumentURLBody] = useState(null);
  const [deleteDocumentBody, setDeleteDocumentBody] = useState(null);
  const [edit, setEdit] = useState(false);
  const [updateDocumentBody, setUpdateDocumentBody] = useState(null);
  const [documentID, setDocumentID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [verification, setVerification] = useState(false);
  const [popOverMessage, setPopOverMessage] = useState("");
  const [disableVerifyWithStripe, setDisableVerifyWithStripe] = useState(false);

  const [snackError, setSnackError] = useState(false);
  //api for uploading the identificationDocument
  const uploadToStripe = useFetch({
    url: "/daycareprofessional/uploadtostripe",
    method: "POST",
    body: uploadToStripeBody,
    accessibility: accessibilityData["Child Profile"],
  });

  const uploadDocument = useFetch({
    url: "/childdocuments/addDocument",
    method: "POST",
    body: uploadDocumentBody,
    contentType: false,
    accessibility: accessibilityData["Child Profile"],
  });

  const getAllDocuments = useFetch({
    url: "/childdocuments/allDocuments",
    method: "POST",
    body: JSON.stringify({
      childID: data?._id,
    }),
  });

  const getDocumentURL = useFetch({
    url: "/childdocuments/viewDocument",
    method: "POST",
    body: getDocumentURLBody,
  });

  const deleteDocument = useFetch({
    url: "/childdocuments/deleteDocument",
    method: "POST",
    body: deleteDocumentBody,
    accessibility: accessibilityData["Child Profile"],
  });

  const updateDocument = useFetch({
    url: "/childdocuments/updateDocument",
    method: "POST",
    body: updateDocumentBody,
    contentType: false,
    accessibility: accessibilityData["Child Profile"],
  });

  useEffect(() => {
    if (uploadDocument.response) {
      setSnackError(false);
      setSnackBarContent(uploadDocument.response.message);
      setDocType(t("Select an Option"));
      setFileType(false);
      setFileName(null);
      setFileData(null);
      snackBarRef.current.openSnackBar();
      setDocData(uploadDocument.response.documents);
    }
    if (uploadDocument.error) {
      setSnackError(true);
      setSnackBarContent(uploadDocument.error);
      snackBarRef.current.openSnackBar();
    }
  }, [uploadDocument.response, uploadDocument.error]);

  useEffect(() => {
    if (updateDocument.response) {
      setDocType(t("Select an Option"));
      setFileType(false);
      setFileName(null);
      setFileData(null);
      setSnackBarContent(updateDocument.response.message);
      snackBarRef.current.openSnackBar();
      setDocData(updateDocument.response.documents);
    }
  }, [updateDocument.response, updateDocument.error]);

  useEffect(() => {
    console.log(getAllDocuments.response);
    if (getAllDocuments.response) {
      setDocData(getAllDocuments.response.documents);
      setTotalDocs(getAllDocuments.response.documents.length);
    }
  }, [getAllDocuments.response]);

  useEffect(() => {
    const body = getDocumentURL.response;
    if (body) {
      console.log(body.document);
      var link = document.createElement("a");
      link.href = body.document;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDocumentURLBody(null);
    }
  }, [getDocumentURL.response, getDocumentURL.error]);

  useEffect(() => {
    if (deleteDocument.response) {
      setSnackBarContent(deleteDocument.response.message);
      snackBarRef.current.openSnackBar();
      setDocData(deleteDocument.response.documents);
    }
  }, [deleteDocument.response]);

  const changeHandler = (event) => {
    const value = inputRef.current.value.split("\\").pop();
    let displayVal;
    displayVal = value;
    setFileName(displayVal);
    setFileData(event.target.files[0]);
    setFileType(true);
  };

  const [error, setError] = useState(null);
  //this is for api: /daycareprofessional/uploaddocument
  const handleUploadDocument = (event) => {
    event.preventDefault();
    if (docType === t("Select an Option")) {
      setError(t("Select a document type"));
    } else {
      setError(null);
      if (edit === false) {
        console.log("upload document");
        setAllDocumentBody(null);
        setDocData([
          ...docData,
          {
            documentName: fileName,
            documentType: docType,
            createdAt: new Date().toLocaleDateString("en-EN", OPTIONS),
            updatedAt: new Date().toLocaleDateString("en-EN", OPTIONS),
          },
        ]);
        const formdata = new FormData();
        formdata.append("childID", data._id);
        formdata.append("document", fileData);
        formdata.append("documentName", fileName);
        formdata.append("documentType", docType);
        setUploadDocumentBody(formdata);
      } else {
        console.log("update document");
        setAllDocumentBody(null);
        const formdata = new FormData();
        formdata.append("childID", data._id);
        formdata.append("documentID", documentID);
        formdata.append("document", fileData);
        formdata.append("documentName", fileName);
        formdata.append("documentType", docType);
        setUpdateDocumentBody(formdata);
      }
      setFileType(false);
    }
  };

  const documentViewHandler = (id) => {
    console.log(id);
    setDocumentURLBody(JSON.stringify({ childID: data._id, documentID: id }));
  };

  const handleDeleteDocument = () => {
    setAllDocumentBody(null);
    setDeleteDocumentBody(
      JSON.stringify({ childID: data._id, documentID: documentID })
    );
  };

  return (
    <div>
      {isOpen && (
        <WarningModal
          isOpen={isOpen}
          onConfirmation={() => {
            handleDeleteDocument();
          }}
          handleToggle={() => {
            setIsOpen(!isOpen);
            setDocumentID(null);
          }}
        />
      )}
      <SnackBar
        isError={snackError}
        ref={snackBarRef}
        content={snackBarContent}
      />
      {fileType && fileName && (
        <div className="mt-3">
          <div className="row justify-content-around align-items-center">
            <div className="col-9">
              <div className="row">
                <div className="col-9">
                  <InputFileName
                    name="fileName"
                    label=""
                    value={fileName}
                    onChange={(event) => setFileName(event.target.value)}
                  />
                </div>
                <div className="col-3">
                  <CustomDropDown
                    name="bankDropdown"
                    value={docType}
                    labelRequired={false}
                    defaultName="Select an Option"
                    options={[
                      "Identity Proof",
                      "Report Card",
                      "Medical Record",
                      "Birth Certificate",
                      "Transfer Certificate",
                      "Residence Proof",
                      "Vaccination Certificate",
                      "Tax Document",
                      "Salary Proof",
                      "Others",
                    ]}
                    onChange={(event) => setDocType(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <Button
              disabled={accessibilityData["Child Profile"] === "Read"}
              onClick={handleUploadDocument}
              type="submit"
              className="mt-1"
              style={{ color: "white" }}
            >
              {edit ? t("Update") : t("Upload")}
            </Button>
          </div>
          <div className="row text-danger ml-3">{error}</div>
        </div>
      )}
      <div>
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none", fontSize: 0 }}
          onChange={changeHandler}
          accept="image/*"
        />
        <div className="d-flex justify-content-end">
          <MDBBtn
            data-testid="button-add"
            disabled={accessibilityData["Child Profile"] === "Read"}
            color="indigo"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Click to add documents"
            className="rounded-circle d-flex justify-content-center align-items-center p-1"
            onClick={() => inputRef.current.click()}
            style={{
              color: "white",
              height: "25px",
              width: "25px",
              marginRight: "20px",
              marginTop: "16px",
            }}
          >
            <MDBIcon
              icon="plus"
              color={
                accessibilityData["Child Profile"] === "Read" ? "white" : "grey"
              }
              size="1x"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </MDBBtn>
        </div>
        <div>
          <Table
            direction={direction}
            data={docData}
            isFirstSortable={false}
            tableHeader={[
              [t("Document Name")],
              [t("Document Type")],
              [t("Create Date")],
              [t("Modified Date")],
              [""],
            ]}
            tableItems={(data, index) => (
              <TableItem
                data={[
                  <div className="d-flex">
                    <div>
                      {" "}
                      {data.uploadedOnStripe &&
                      data.stripeVerificationStatus === "Verified" ? (
                        <div className="d-flex h-100 align-items-center">
                          <i
                            class="fab fa-stripe-s"
                            style={{
                              color: "white",
                              backgroundColor: "indigo",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />{" "}
                          <i
                            class="fas fa-check"
                            style={{
                              color: "green",
                              paddingBottom: "15px",
                              fontSize: "10px",
                            }}
                          />
                        </div>
                      ) : data.uploadedOnStripe &&
                        data.stripeVerificationStatus === "Pending" ? (
                        <div className="d-flex">
                          <i
                            class="fab fa-stripe-s"
                            style={{
                              color: "white",
                              backgroundColor: "indigo",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />{" "}
                          <i
                            class="fas fa-exclamation"
                            style={{
                              color: "yellow",
                              paddingBottom: "15px",
                              fontSize: "10px",
                            }}
                          />
                        </div>
                      ) : data.uploadedOnStripe ? (
                        <div className="d-flex">
                          <i
                            class="fab fa-stripe-s"
                            style={{
                              color: "white",
                              backgroundColor: "indigo",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />{" "}
                          <i
                            class="fas fa-times"
                            style={{
                              color: "red",
                              paddingBottom: "15px",
                              fontSize: "10px",
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {data.documentName.length > 20
                        ? data.documentName.substr(0, 15) + "..."
                        : data.documentName}
                    </div>
                  </div>,

                  data.documentType,
                  new Date(data.createdAt).toLocaleDateString("en-EN", OPTIONS),
                  new Date(data.updatedAt).toLocaleDateString("en-EN", OPTIONS),
                  <div className="d-flex justify-content-between align-items-center">
                    {accessibilityData["Child Profile"] !== "Read" ? (
                      <span style={{ padding: "16px 10px" }}>
                        <EditIcon
                          fontSize="small"
                          onClick={() => {
                            inputRef.current.click();
                            setDocumentID(data._id);
                            setEdit(true);
                          }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                    <span style={{ padding: "16px 10px" }}>
                      {accessibilityData["Child Profile"] !== "Read" ? (
                        <DeleteIcon
                          fontSize="small"
                          onClick={() => {
                            setDocumentID(data._id);
                            setIsOpen(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>,
                ]}
                onClick={() => documentViewHandler(data._id)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentsTab;
