import { Directions } from "@material-ui/icons";
import { MDBBadge, MDBIcon } from "mdbreact";
import React from "react";

const Arrow = ({ direction, ...props }) => {
  return (
    <MDBBadge
      color="indigo"
      className="ml-2"
      onClick={direction == null ? null : props.onClick}
      style={{ visibility: `${direction == null ? "hidden" : "visible"}` }}
    >
      <MDBIcon icon="arrow-down" flip={direction ? "vertical" : ""} size="1x" />
    </MDBBadge>
  );
};

export default Arrow;
