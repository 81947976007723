import React, { useEffect, useState } from "react";
import { MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from "mdbreact";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../../GeneralComponents/Button";
import config from "../../config";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(`${config.stripe_key}`, {
  stripeAccount: `${config.stripe_account}`,
});

const stripePromiseFrance = loadStripe(`${config.stripe_key_france}`, {
  stripeAccount: `${config.stripe_account_france}`,
});
const ModalComponent = ({ isOpen, toggle, paymentIntent, countryCode, onSuccess, text }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(`${paymentIntent}`, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Daycare 1",
        },
      },
    });

    if (result.error) {
      console.log(result.error.message);
      setError(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        console.log("Payment succeeded!");
        onSuccess();
        toggle();
      }
    }
  };

  return (
    <MDBModal size="md" isOpen={isOpen} toggle={toggle} centered>
      <MDBModalHeader
        style={{ backgroundColor: "#2A4494" }}
        titleClass="d-flex justify-content-between align-items-center white-text w-100">
        <p className="m-0 p-0" style={{ fontSize: "21px", fontWeight: "bold" }}>
          {text}
        </p>
        <MDBIcon
          icon="times"
          onClick={() => {
            toggle();
          }}
        />
      </MDBModalHeader>
      <MDBModalBody>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "20px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <div className="d-flex justify-content-between mt-3 mb-2">
          <Button onClick={toggle}>Cancel</Button>
          <Button onClick={() => handleSubmit()} disabled={!stripe}>
            {text}
          </Button>
        </div>
        {error && (
          <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
            <div className="text-danger">{error}</div>
          </div>
        )}
      </MDBModalBody>
    </MDBModal>
  );
};
const CardStripe = ({ isOpen, toggle, paymentIntent, countryCode, onSuccess }) => {
  const {t} = useTranslation();
  return (
    <Elements stripe={countryCode == "FR" ? stripePromiseFrance : stripePromise}>
      <ModalComponent
        isOpen={isOpen}
        toggle={toggle}
        countryCode={countryCode}
        paymentIntent={paymentIntent}
        onSuccess={onSuccess}
        text={t("Make Payment")}
      />
    </Elements>
  );
};

export default CardStripe;
