import { useEffect, useState } from "react";
import { data } from "./childProfileData";

const useForms = ({
	initialValues,
	isChildProfile,
	defaultValue,
	defaultData = data,
}) => {
	const [values, setValues] = useState({});
	const [changedValue, setChangedValue] = useState(null);
	const [errors, setErrors] = useState({});
	// useEffect(() => console.log("values", values), [values]);
	useEffect(() => {
		console.log("data enrollment", initialValues);
		setValues(() => initialValues);
	}, [initialValues]);
	const setToDefault = () => setValues(defaultValue);

	const checkErrors = (index, errors = {}) => {
		let newErrors = {};
		defaultData[index].data.forEach((ele) => {
			if (ele.required) {
				console.log(values[ele.name], ele.name, ele.type);
				if (
					ele.type === "dropdown" &&
					(values[ele.name] === "Select An Option" ||
						values[ele.name] === "Select A State" ||
						values[ele.name] === "Select A Country")
				) {
					newErrors[ele.name] = true;
				} else {
					newErrors[ele.name] = values[ele.name] == "";
				}
				if (ele.type == "tel") {
					newErrors[ele.name] = !/^\+(?:[0-9] ?){6,14}[0-9]$/.test(
						values[ele.name]
					);
				}
				if (ele.type == "email") {
					newErrors[
						ele.name
					] = !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
						values[ele.name]
					);
				}
				if (ele.type == "address1") {
					if (values[ele.name] === "") {
						newErrors[ele.name];
					}
				}
				if (ele.type === "text") {
					newErrors[ele.name] = !/^[\w ]+$/.test(values[ele.name]);
				}
			}
		});
		newErrors = { ...newErrors, ...errors };
		console.log("newError", newErrors);
		setErrors(newErrors);
		for (let key in newErrors) {
			console.log("Error", newErrors[key]);
			if (newErrors[key]) {
				return true;
			}
		}
		return false;
	};
	const handleArrayChange = (event, index, type) => {
		const id = event.target.id;
		let array = [...values[id]];
		console.log({ array });
		if (type == "change") {
			array[index] = event.target.value;
		}
		if (type == "add") {
			array.push("");
		}
		if (type == "remove") {
			if (id == "vaccinationType" || id == "vaccinationLastDate") {
				let vaccineName = [...values["vaccinationType"]];
				let vaccineDate = [...values["vaccinationLastDate"]];
				vaccineName = vaccineName.filter((_, inde) => inde != index);
				vaccineDate = vaccineDate.filter((_, inde) => inde != index);
				setValues((prevValues) => ({
					...prevValues,
					vaccinationType: vaccineName,
					vaccinationLastDate: vaccineDate,
				}));
				return;
			}
			array = array.filter((_, inde) => inde != index);
			setChangedValue({ [id]: array });
		}
		setValues((prevValues) => ({ ...prevValues, [id]: array }));
	};
	//validation checks
	const validity = (event) => {
		const target = event.target;
		console.log("target", target.type, target.value);
		if (
			target.type == "select-one" &&
			(target.value == "Select an option" ||
				target.value == "Dropdown of States" ||
				target.value == "Dropdown of Countries" ||
				target.value == "Select an Option")
		) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[target.id]: true,
			}));
		}
		if (target.required) {
			console.log(
				"email",
				target.validity.valueMissing,
				target.validity.typeMismatch
			);
			setErrors((prevErrors) => ({
				...prevErrors,
				[target.id]:
					target.validity.valueMissing || target.validity.typeMismatch,
			}));
		}
		if (target.type == "tel") {
			console.log(
				"email tel",
				!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(target.value),
				target.value
			);
			setErrors((prevErrors) => ({
				...prevErrors,
				[target.id]: !/^\+(?:[0-9] ?){6,14}[0-9]$/.test(target.value),
			}));
		}
		if (target.type == "email") {
			setErrors((prevErrors) => ({
				...prevErrors,
				[target.id]: !/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/.test(
					target.value
				),
			}));
		}
		if (target.type == "address1") {
			console.log("address type variable mismatch");
		}
		if (target.type == "text") {
			setErrors((prevErrors) => ({
				...prevErrors,
				[target.id]: !/^[\w ]+$/.test(target.value),
			}));
		}
		console.log("target", target.validity, target.type);
	};
	const handleBlur = (event) => {
		const target = event.target;
		const name = target.id;
		if (target?.type == "file" || target?.type == "multipleDropdown") {
			console.log("changed", { [name]: target.value });
			setChangedValue({ [name]: target.value });
		} else {
			setChangedValue({ [name]: values[name] });
		}
		validity(event);
	};
	const handleChange = (event) => {
		console.log("change event", event);
		const target = event.target;
		const name = target.id;
		let value = target.type == "file" ? target.files[0] : target.value;
		if (target.type == "file") {
			handleBlur({
				target: { value: target.files[0], id: target.id, type: "file" },
			});
		}

		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};
	return {
		values,
		handleChange,
		handleArrayChange,
		handleBlur,
		changedValue,
		errors,
		checkErrors,
		setToDefault,
	};
};

export default useForms;
