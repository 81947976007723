import React, { useEffect, useState, useRef } from "react";
import useFetch from "../../CustomHooks/useFetch";
import usePyFetch from "../../CustomHooks/usePyFetch";
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import Loader from "../../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";
import { useTranslation } from "react-i18next";

const ChildrenChart = ({ date }) => {
	const { t } = useTranslation();
	const [body, setBody] = useState(null);
	const [data, setData] = useState(null);
	const chartRef = useRef();
	const { response, isLoading, error } = useFetch({
		url: "/day_child_attendance",
		method: "POST",
		body: body,
	});

	useEffect(() => {
		setBody(JSON.stringify({ start_date: date }));
	}, [date]);
	useEffect(() => {
		console.log("child chart for 2", response);
		if (!isLoading && chartRef.current && response) {
			echarts.use([TooltipComponent, PieChart, CanvasRenderer, GridComponent]);
			let chart = echarts.init(chartRef.current);
			const value = response.count;
			chart.setOption({
				tooltip: {
					trigger: "item",
				},
				series: [
					{
						name: "attendence",
						type: "pie",
						radius: "60%",
						data: [
							{ value: value.ABSENT, name: t("absent") + `(${value.ABSENT})` },
							{
								value: value.ON_LEAVE,
								name: t("on leave") + `(${value.ON_LEAVE})`,
							},
							{
								value: value.PRESENT,
								name: t(`present`) + `(${value.PRESENT})`,
							},
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: "rgba(0, 0, 0, 0.5)",
							},
						},
					},
				],
			});
		}
	}, [isLoading, chartRef.current, response]);
	if (error) return <p>{t("no data found")}</p>;
	return isLoading ? (
		<Loader />
	) : (
		<div
			ref={chartRef}
			style={{ width: "100%", height: "calc(100% - 30px)" }}
		></div>
	);
};

export default ChildrenChart;
