import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config";

const useFetch = ({ url, body = null, method, contentType = true, reFetch, isMobile = false, accessibility = 'Edit' }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.token);
  useEffect(() => {
    console.log("url", url, method, body);
    if (url.includes("null") || url.includes("undefined")) return;
    if (method && body === null) {
      return;
    }
    setError(null);
    setResponse(null);
    const fetchData = async () => {
      setIsLoading(true);
      const header = {
        method: method || "GET",
        headers: {
          Authorization: token.token,
        },
        credentials: "same-origin",
      };
      if (contentType) header.headers["Content-Type"] = "application/json";
      if (body) header.body = body;
      try {
        if (accessibility === 'Edit') {
          let resok = true;

          const res = await fetch(`${isMobile ? config.mobileurl : config.devurl}${url}`, header);
          const json = await res.json();
          resok = res.ok;
          if (/4[0-9]{2}/i.test(res.status) || /5[0-9]{2}/i.test(res.status)) {
            setError(json.error || json.message || res.statusText);
            setIsLoading(false);
            return;
          }
          resok ? setResponse(json) : setError(res.error);
          setIsLoading(false);
        }
        else {
          setError({ "error": "You are not Authorized to perform this action" });
          setIsLoading(false);
        }
      } catch (error) {
        console.log("url", error, url);
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, body, contentType, token, reFetch]);
  return { response, error, isLoading };
};

export default useFetch;
