import React from "react";

import { useTranslation } from "react-i18next";

const AddressInput = ({
  name,
  label,
  value,
  onChange,
  type,
  required,
  onBlur,
  error,
  disabled,
}) => {

  const { t } = useTranslation();

  return (
    <>
      <div className="form-group mt-3">
        <input
          type={type || "text"}
          className="form-control"
          id={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          required={required ? true : null}
        />
        <label htmlFor={name}>
          {t(label)} {required ? <span style={{ color: "red" }}>*</span> : null}
        </label>
        {error && value === "" ? (
          <p className="red-text m-0" style={{ position: "relative", bottom: "30px" }}>
            {`${t("Required field.")}`}
          </p>
        ) : null}
      </div>
    </>
  );
};

export default AddressInput;
