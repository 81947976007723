import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import {Provider} from 'react-redux';
import store from './Redux/config/config'
import Main from './component/MainComponent'

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>
      

    </div>
  );
}

export default App;
