/*
TODO: Review where this file is used. Prima facie is this file is NOT USED any where so remove the file and its references.. 
TODO: some cleanup to happen.
*/

import React from "react";
const ChildDashboard = React.lazy(() =>
	import(
		/* webpackChunkName: "childDashboard" */ "./childdetail/ChildDashboard/ChildDashboard"
	)
);
const ChildProfile = React.lazy(() =>
	import(
		/* webpackChunkName: "childProfile" */ "./childdetail/ChildProfile/ChildProfile"
	)
);
const Enrollment = React.lazy(() =>
	import(
		/* webpackChunkName: "enrollment" */ "./childdetail/Enrollment/Enrollment"
	)
);
const ChildRegister = React.lazy(() =>
	import(
		/* webpackChunkName: "childRegister2" */ "./childdetail/ChildRegister/ChildRegister"
	)
);

const Billing = React.lazy(() =>
	import(/* webpackChunkName: "billing" */ "./childdetail/Billing/Billing")
);
const DaycareDetails = React.lazy(() =>
	import(/* webpackChunkName: "DaycareDetails" */ "./Daycare/DaycareDetails")
);
const ViewSections = React.lazy(() =>
	import(/* webpackChunkName: "ViewSections" */ "./Daycare/ViewSections")
);
const ViewGroups = React.lazy(() =>
	import(/* webpackChunkName: "ViewGroups" */ "./Daycare/ViewGroups")
);
const CreateActivity = React.lazy(() =>
	import(
		/* webpackChunkName: "CreateActivity" */ "./Activities/CreateActivity/CreateActivity"
	)
);
const ManageActivity = React.lazy(() =>
	import(
		/* webpackChunkName: "ManageActivity" */ "./Activities/ManageActivity/ManageActivity"
	)
);

const Presence = React.lazy(() =>
	import(/* webpackChunkName: "Presence" */ "./childdetail/Presence/Presence")
);
const Prospects = React.lazy(() =>
	import(/* webpackChunkName: "Prospects" */ "./childdetail/Prospects/Propects")
);
const Reports = React.lazy(() =>
	import(
		/* webpackChunkName: "Reports" */ "./Daycare/FinancialReporting/Reports"
	)
);
const ViewSession = React.lazy(() =>
	import(/* webpackChunkName: "ViewSession" */ "./Daycare/ViewSessions")
);
const UserManagement = React.lazy(() =>
	import(
		/* webpackChunkName: "UserManagement" */ "./Daycare/UserManagement/UserManagement"
	)
);

//const LiveDashboard = React.lazy(() =>
//	import(/* webpackChunkName: "LiveDashboard" */ "./Live/LiveDashboard")
//);


export const data = {
	"Child Profile": [
		{
			name: "Child Dashboard",
			url: "/childManagement/childDashboard",
			component: ChildDashboard,
		},
		{
			name: "Child Profile",
			url: "/childManagement/childProfile",
			component: ChildProfile,
		},
		{
			name: "Enrollment",
			url: "/childManagement/enrollment",
			component: Enrollment,
		},
		{
			name: "Register A Child",
			url: "/childManagement/childRegister",
			component: ChildRegister,
		},
		{
			name: "Billing",
			url: "/childManagement/billing",
			component: Billing,
		},
		{
			name: "Presence",
			url: "/childManagement/presence",
			component: Presence,
		},
		{
			name: "Prospects",
			url: "/childManagement/prospects",
			component: Prospects,
		},
	],
	Daycare: [
		{
			name: "Daycare Details",
			url: "/myDaycareNew/details",
			component: DaycareDetails,
		},
		{
			name: "View Sections",
			url: "/myDaycareNew/sections",
			component: ViewSections,
		},
		{
			name: "View Groups",
			url: "/myDaycareNew/groups",
			component: ViewGroups,
		},
		{
			name: "Reports",
			url: "/myDaycareNew/reports",
			component: Reports,
		},
		{
			name: "View Sessions",
			url: "/myDaycareNew/viewSessions",
			component: ViewSession,
		},
		{
			name: "User Management",
			url: "/myDaycareNew/userManagement",
			component: UserManagement,
		},
	],
	
	
	/*,	
	Live: [
		{
			name: "Live Stream",
			url: "/",
			component: LiveDashboard,
		},
	], */
};
