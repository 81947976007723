import React from "react";

const OuterWrapper = ({ children, title, style, minHeight }) => (
	<div
		style={{
			boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
			borderRadius: "20px",
			minHeight: `${minHeight ? "auto" : "300px"}`,
			padding: "10px",
			...style,
		}}
	>
		<p className="m-0 text-center font-weight-bold">{title}</p>
		{children}
	</div>
);

export default OuterWrapper;
