import React from 'react';

import './loading.css'
function LoadingReset() {
  return (<>
    
      <div className="loading">

      </div>
      <div className='row-loading'>
        <label ><pre style={{color:'#304391'}}> <b>Password has been successfully reset, redirecting you to login page.</b> </pre></label>
      </div>
    

  </>

  )
}
export default LoadingReset;