import React from "react";
import CustomInput from "./CustomInput";
import { useTranslation } from "react-i18next";

const VaccineInput = ({ ele, handleBlur, handleChange, values, disabled }) => {
  const { t } = useTranslation();
  return (
    <div key={ele[0].name}>
      <div className="d-flex align-items-center mt-3">
        <p
          style={{
            marginBottom: "0px",
            marginRight: "10px",
            fontSize: "14px",
            fontWeight: "bold",
          }}>
          {ele[0].name === "siblingName" ? t("Sibling Details") : t("Vaccination Details")}
        </p>
        {disabled ? null : (
          <button
            type="button"
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => handleChange({ target: { id: ele[0].name } }, null, "add")}>
            <i className="fas fa-plus fa-sm"></i>
          </button>
        )}
      </div>
      <div className="d-flex  flex-column">
        {values[ele[0].name]
          ? values[ele[0].name].map((_, index) => (
            <div className="d-flex align-items-center" key={index} >
              <CustomInput
                name={ele[0].name}
                label={ele[0].label}
                value={values[ele[0].name][index]}
                onChange={(event) => handleChange(event, index, "change")}
                onBlur={handleBlur}
                required={ele[0].required}
                type={"text"}
                disabled={disabled}
              />
              <CustomInput
                name={ele[1].name}
                label={ele[1].label}
                value={values[ele[1].name][index]}
                onChange={(event) => handleChange(event, index, "change")}
                onBlur={handleBlur}
                required={ele[1].required}
                type={ele[1].type}
                disabled={disabled}
              />
              <button
                type="button"
                disabled={disabled}
                onClick={() => handleChange({ target: { id: ele[0].name } }, index, "remove")}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  marginLeft: "10px",
                  marginBottom: "30px",
                }}>
                <i className="fas fa-trash-alt fa-lg"></i>
              </button>
            </div>
          ))
          : null}
      </div>
    </div>
  );
};

export default VaccineInput;
