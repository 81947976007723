import React, { Component, useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import logo2 from "../public/logo2.png";
import orgLogo from "../public/klay_daycare_logo.png";
import poweredLogo from "../public/poweredlogo.png";
import "./css/register.css";
import { useTranslation } from "react-i18next";
import LanguageSelection from "../component/LanguageSelection";

import {
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavbarToggler,
	MDBCollapse,
	MDBNavItem,
	MDBNavLink,
	MDBIcon,
	MDBBtn,
} from "mdbreact";
import {
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
} from "mdbreact";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

//import Notification from "./Notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import useFetch from "./CustomHooks/useFetch";
import { logout } from "../Redux/actions/actionCreator/data.action";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DropDownMenu from "../GeneralComponents/DropDownMenu";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		background: "white",
		color: "black",
		zIndex: theme.zIndex.drawer + 1,
	},
	powered_text: {
		fontSize: "9px",
	},
	brand_name: {
		fontFamily: "Comfortaa",
		fontWeight: 700,
		letterSpacing: "1px",
		fontSize: "12px",
		color: "#2a4494",
	},
}));

function Header(props) {
	const classes = useStyles();
	const [pillname, setPillName] = useState("childManagement");
	const [activePill, setActivePill] = useState("childManagement");
	const [collapse, setCollapse] = useState(false);
	const [isWideEnough, setIsWideEnough] = useState(false);
	const allowedData = useSelector((state) => state.config).config;
	const subModule = useSelector((state) => state.config).submodule;
	const location = useLocation();
	const history = useHistory();
	const [imgSrc, setImgSrc] = useState("");
	const photo = useSelector((state) => state.country.photo);

	const DropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (DropdownRef.current && !DropdownRef.current.contains(event.target)) {
				setCollapse(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [DropdownRef]);

	const { t } = useTranslation();

	useEffect(
		() => console.log("location pillname", pillname, allowedData, activePill),
		[pillname]
	);

	useEffect(() => {
		setPillName(() => location.pathname.split("/")[1]);
		const val = location.pathname.split("/")[1];
		if (val === "myDaycareNew") {
			setActivePill("daycare");
		}
		// added the active pill on the first click
		else if (val === "pnl") {
			setActivePill("reports");
		}
		else if (val === "staffmanagement") {
			setActivePill("staffManagement");
		}
		else if (val === "activity") {
			setActivePill("activities");
		} else {
			setActivePill(val);
		}
		console.log("allowed data in header", allowedData);
		console.log("location", location.pathname, location.pathname.split("/")[2]);
	}, [location.pathname]);
	function onClick() {
		setCollapse(!collapse);
	}

	const logo = useFetch({
		url: "/admin/manager/editLabels",
		method: "POST",
		body: JSON.stringify({
			daycareManagerEmail: "contact@stepswatch.co.in",
			payload: {},
		}),
	});

	const dispatch = useDispatch();
	const handlesubmit = () => {
		dispatch(logout());
		localStorage.removeItem("token");
		localStorage.removeItem("config");
	};

	//assign the logo link to a variable
	useEffect(() => {
		if (!logo.isLoading && logo.response) {
			setImgSrc(logo.response.organisationLogo);
			//console.log(logo.response);
		}
	}, [logo.isLoading, logo.response]);
	const gotoProfile = () => {
		history.push("/myDaycareNew/details");
	};

	return (
		<div className={classes.root} ref={DropdownRef}>
			<AppBar position="fixed" className={clsx(classes.appBar)}>
				<MDBNavbar color="white" light expand="md" fixed="top">
					<MDBNavbarBrand className="pr-2" href="/">
						<img
							src={logo2}
							height="30"
							style={{ backgroundColor: "transparent", zIndex: "1000" }}
							alt="logo"
						/>
						{/* {orgLogo && (
              <div className={`text-right pl-3 ${clsx(classes.powered_text)}`}>
                Powered by <span className={clsx(classes.brand_name)}>Stepswatch</span>
              </div>
            )} */}
					</MDBNavbarBrand>
					{!isWideEnough && <MDBNavbarToggler onClick={onClick} />}
					<MDBCollapse isOpen={collapse} navbar>
						<MDBNavbarNav left>
							{allowedData.map((ele, index) => {
								const isActive = activePill === ele.value;
								const linkStyle = {
									fontWeight: "normal", // Bold for active item, normal for others
									color: "#333333",                      // Dark color for better visibility
								};

								if (ele.type) {
									return (
										<div key={index}>
											<MDBNavItem active={isActive}>
												<MDBDropdown>
													<MDBDropdownToggle nav caret style={linkStyle}>
														{t(`${ele.name}`)}
													</MDBDropdownToggle>
													<MDBDropdownMenu basic>
														{ele.data.map((dropdown, dropdownIndex) => (
															<MDBDropdownItem key={dropdownIndex}>
																<MDBNavLink to={dropdown.path} style={linkStyle}>
																	{dropdown.name}
																</MDBNavLink>
															</MDBDropdownItem>
														))}
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBNavItem>
										</div>
									);
								} else {
									return (
										<div key={index}>
											<MDBNavItem active={isActive}>
												<MDBNavLink
													onClick={() => {
														setPillName(ele.name);
														setActivePill(ele.value);
														setCollapse(false);
													}}
													to={ele.path}
													style={linkStyle}
												>
													{t(`${ele.name}`)}
												</MDBNavLink>
											</MDBNavItem>
										</div>
									);
								}
							})}
						</MDBNavbarNav>

						<MDBNavbarNav
							right
							className="d-flex justify-content-center align-items-center"
						>
							{/* <Notification /> */}
							<LanguageSelection />
							<DropDownMenu
								options={[
									{ name: `${t("profile")}`, click: gotoProfile },
									{ name: `${t("logout")}`, click: handlesubmit },
								]}
								icon="user-circle"
							/>
						</MDBNavbarNav>
					</MDBCollapse>
				</MDBNavbar>
			</AppBar>
		</div>
	);
}
export default Header;
