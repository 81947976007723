import React, { useEffect } from "react";
import CustomInput from "./CustomInput";
import { useTranslation } from "react-i18next";

const InputArray = ({ value, label, name, onChange, onBlur, disabled }) => {

  const {t} = useTranslation();

  return (
    <div key={name}>
      <div className="d-flex  align-items-center mt-3">
        <p
          style={{
            marginBottom: "0px",
            marginRight: "10px",
            fontSize: "14px",
            fontWeight: "bold",
          }}>
          {t(label)}
        </p>
        {disabled ? null : (
          <button
            type="button"
            onClick={() => onChange({ target: { id: name } }, null, "add")}
            style={{ border: "none", backgroundColor: "transparent" }}>
            <i className="fas fa-plus fa-sm"></i>
          </button>
        )}
      </div>
      {value
        ? value.map((ele, index) => (
            <div className="d-flex align-items-center" key={index}>
              <CustomInput
                name={name}
                value={ele}
                type={ele.type}
                label={label}
                onBlur={onBlur}
                disabled={disabled}
                onChange={(event) => onChange(event, index, "change")}
              />
              <button
                type="button"
                onClick={() => onChange({ target: { id: name } }, index, "remove")}
                disabled={disabled}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  marginBottom: "34px",
                  marginLeft: "10px",
                }}>
                <i className="fas fa-trash-alt fa-lg"></i>
              </button>
            </div>
          ))
        : null}
    </div>
  );
};

export default InputArray;
