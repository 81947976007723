import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config";

const usePyFetch = ({ url, method = "GET", body = null }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.token);
  useEffect(() => {
    const fetchData = async () => {
      if (url.includes("null")) return;
      if (method != "GET" && (body == "null" || body == null)) return;
      setIsLoading(true);
      setError(null);
      try {
        console.log("url", `${config.pyurl}${url}`, body, method);
        const header = {
          method: method,
          dataType: "json",
          xhrFields: {
            withCredentials: true,
          },
          headers: {
            Authorization: token.token,
            "Content-Type": "application/json",
          },
          crossDomain: true,
          redirect: "follow",
        };
        if (body) header.body = body;
        const res = await fetch(`${config.pyurl}${url}`, header);
        const json = await res.json();
        if (json.error) {
          setError(json.error);
          setIsLoading(false);
          return;
        } else {
          setResponse(json);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("headers", error, typeof error);
        if (typeof error === "object") setError("data not found");
        else setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, body]);
  return { response, error, isLoading };
};

export default usePyFetch;
