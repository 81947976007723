import React, { useState, useMemo, useEffect, useRef } from "react";
import SearchBar from "../childdetail/ChildProfile/SearchBar";
import Button from "../../GeneralComponents/Button";
import {
	MDBDropdown,
	MDBDropdownItem,
	MDBDropdownMenu,
	MDBDropdownToggle,
	MDBIcon,
	MDBPopover,
	MDBPopoverBody,
	MDBBtn,
} from "mdbreact";
import { useSelector } from "react-redux";
import { Popper } from "@material-ui/core";
import useFetch from "../CustomHooks/useFetch";
import Loader from "../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";
import Table from "../childdetail/ChildProfile/Table";
import TableItem from "../childdetail/ChildProfile/TableItem";
import SnackBar from "../../GeneralComponents/SnackBar";
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core";
//!importing card stripe
import CardStripe from "./CardStripe.jsx";
import DropDownMenu from "../../GeneralComponents/DropDownMenu";
import { useTranslation } from "react-i18next";
import styles from "./PayRoll.module.scss";

const PAYMENT_MULTIPLIER = 100;

const useStyles = makeStyles({
	button: {
		backgroundColor: "transparent!important",
		boxShadow: "none!important",
		padding: "0",
		margin: "0",
	},
});

const PayRoll = (props) => {
	const { t } = useTranslation();
	const [date, setDate] = useState(
		new Date().toLocaleDateString("en-IN", {
			day: "numeric",
			month: "long",
			year: "numeric",
		})
	);
	const accessibilityData = useSelector((state) => state).accessibility;

	const [totalData, setTotalData] = useState([]);
	const classes = useStyles();
	const [filteredData, setFilteredData] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [direction, setDirection] = useState([
		false,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	]);

	const currency = useSelector((state) => state.currency);
	const keywords = useSelector((state) => state.config.keywords);

	const currentMonth = useMemo(() => date.split(" ").slice(1).join(" "), [
		date,
	]);
	const [getPayRollBody, setGetPayRollBody] = useState(currentMonth);
	//const countryData = useFetch({ url: `/daycareDetails` });
	const country = useSelector((state) => state.country.country);
	//const [country, setCountry] = useState("");
	useEffect(() => {
		setGetPayRollBody(
			JSON.stringify({
				month: currentMonth,
			})
		);
	}, [currentMonth]);

	const [payBody, setPayBody] = useState(null);
	const [emailBody, setEmailBody] = useState(null);
	const [downloadBody, setDownloadBody] = useState(null);
	const snackBarRef = useRef();
	// const [anchorEl, setAnchorEl] = useState(null);
	// const open = Boolean(anchorEl);
	const [snackBarContent, setSnackBarContent] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [month, setMonth] = useState(null);
	const [payableAmount, setPayableAmount] = useState(0);
	const [selectedStaff, setSelectedStaff] = useState(0);
	const [paymentSuccess, setPaymentSuccess] = useState(false);

	const [totalGrossSum, setTotalGrossSum] = useState(null);
	const [totalDeductionSum, setTotalDeductionSum] = useState(null);
	const [totalNetSum, setTotalNetSum] = useState(null);
	const [totalPaid, setTotalPaid] = useState(0);
	const [totalEntries, setTotalEntries] = useState(0);

	const [adhocDataBody, setAdHocDataBody] = useState(null);

	const payResponse = useFetch({
		url: "/daycare/salary/paySalary",
		body: payBody,
		method: "PATCH",
		accessibility: accessibilityData["Payroll"],
	});
	const sendEmailResponse = useFetch({
		url: "/create/salarySlip/sendEmail",
		body: emailBody,
		method: "POST",
		accessibility: accessibilityData["Payroll"],
	});
	const downloadPayslipResponse = useFetch({
		url: "/create/salarySlip/downloadPayslip",
		body: downloadBody,
		method: "POST",
		accessibility: accessibilityData["Payroll"],
	});

	const getPayRoll = useFetch({
		url: "/daycare/salary",
		body: getPayRollBody,
		method: "POST",
	});

	//!this handles generating AdHoc Data
	const generateAdHocData = useFetch({
		url: "/daycare/generate/payroll",
		body: adhocDataBody,
		method: "POST",
		accessibility: accessibilityData["Payroll"],
	});

	const [response, setResponse] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		console.log("salary error", getPayRoll.error);
		if (getPayRoll.error) {
			setError(getPayRoll.error);
			setTotalPaid(0);
		}
	}, [getPayRoll.error]);
	useEffect(() => {
		setResponse(getPayRoll.response);
		if (getPayRoll.response) {
			setError(null);
		}
		setIsLoading(getPayRoll.isLoading);
	}, [getPayRoll.response, getPayRoll.isLoading]);

	useEffect(() => {
		if (response) {
			if (response.salaries.length > 0) {
				setError(null);
				handleSalaryResponse(response);
			}
		}
	}, [response]);

	const handleGenerateAdHocData = () => {
		setGetPayRollBody(null);
		setAdHocDataBody(
			JSON.stringify({
				month: currentMonth,
			})
		);
	};

	useEffect(() => {
		const body = generateAdHocData.response;
		setIsLoading(generateAdHocData.isLoading);
		if (body) {
			const response = body;
			if (response) {
				setSnackBarContent(t("Payroll successfully generated"));
				snackBarRef.current.openSnackBar();
				setResponse(response);
				setAdHocDataBody(null);
			}
		}
	}, [
		generateAdHocData.response,
		generateAdHocData.isLoading,
		generateAdHocData.error,
	]);

	const changeDate = (direction) => {
		const newDate = new Date(date);
		newDate.setMonth(newDate.getMonth() + direction);
		setDate(
			newDate.toLocaleDateString("en-IN", {
				day: "numeric",
				month: "long",
				year: "numeric",
			})
		);
		setIsDisabled(false);
	};

	const searchTextHandler = (text) =>
		setFilteredData(totalData.filter((ele) => ele.name.includes(text)));

	useEffect(() => {
		let gross = 0,
			deductions = 0,
			netPay = 0,
			paid = 0,
			currencySymbol = currency[0] + " ";
		if (filteredData.length >= 0) {
			filteredData.forEach((ele) => {
				gross += ele.grossNum;
				deductions += ele.totalDeductionsNum;
				netPay += ele.netPayNum;
				if (ele.status === "Paid") paid++;
			});
			setTotalGrossSum(`${currencySymbol}${gross.toLocaleString()}`);
			setTotalDeductionSum(`${currencySymbol}${deductions.toLocaleString()}`);
			setTotalNetSum(`${currencySymbol}${netPay.toLocaleString()}`);
			setTotalPaid(paid);
			setTotalEntries(filteredData.length);
			console.log(
				"gross things",
				gross,
				deductions,
				netPay,
				paid,
				filteredData.length
			);
		}
	}, [filteredData]);

	const handleSalaryResponse = (response) => {
		const data = [];
		let gross = 0,
			deductions = 0,
			netPay = 0,
			paid = 0,
			totalEntries = 0;
		const currencySymbol = currency[0] + " ";

		response.salaries.forEach((ele) => {
			(gross += parseFloat(ele.grossEarning)),
				(deductions += parseFloat(ele.totalDeductions)),
				(netPay += parseFloat(ele.netPay)),
				ele.paid ? paid++ : paid,
				(totalEntries += 1);
			data.push({
				id: "" + ele.employeeCode,
				firstName: ele.firstName,
				lastName: ele.lastName,
				name: `${ele.firstName} ${ele.lastName}`.toLowerCase(),
				ownerId: ele.owner_id,
				totalGross:
					currencySymbol + parseFloat(ele.grossEarning).toLocaleString(),
				grossNum: ele.grossEarning,
				profile: keywords.daycareProfessional,
				totalDeductions:
					currencySymbol + parseFloat(ele.totalDeductions).toLocaleString(),
				totalDeductionsNum: ele.totalDeductions,
				netPay: currencySymbol + parseFloat(ele.netPay).toLocaleString(),
				netPayNum: ele.netPay,
				status: ele.paid ? "Paid" : "Not Paid",
				basicSalary: ele.basicSalary,
				currency: ele.currency,
				deductions: ele.deductions,
				earning: ele.variableEarning,
				// RM: disabling stripe verification and putting hard code "Verified" status so the code runs well on the payroll. this enables checkbox.
				//verification: ele.stripeConnectedAccountStatus,
				verification: "Verified",
				checked: false,
				issued: false,
			});
		});
		setTotalGrossSum(`${currencySymbol} ${gross.toLocaleString()}`);
		setTotalDeductionSum(`${currencySymbol} ${deductions.toLocaleString()}`);
		setTotalNetSum(`${currencySymbol} ${netPay.toLocaleString()}`);
		setTotalPaid(paid.toLocaleString());
		setTotalEntries(totalEntries.toLocaleString());
		setTotalData(data);
		setFilteredData(data);
		console.log("staff text1", typeof totalPaid, data, response);
	};

	useEffect(() => console.log("payroll", response, isLoading, error), [
		response,
		isLoading,
		error,
	]);

	const sortHandler = (field, value) =>
		setFilteredData((prevData) =>
			[...filteredData].sort((a, b) =>
				value
					? typeof a[field] === "number"
						? a[field] - b[field]
						: a[field].localeCompare(b[field])
					: typeof a[field] === "number"
						? b[field] - a[field]
						: b[field].localeCompare(a[field])
			)
		);
	const directionHandler = (field, index) => {
		let elementData = direction[index];
		const newData = [null, null, null, null, null, null, null, null];
		newData[index] = elementData === null ? false : !elementData;
		sortHandler(field, newData[index]);
		setDirection(() => newData);
	};
	useEffect(
		() =>
			console.log(
				"staff val",
				payResponse.response,
				payResponse.isLoading,
				payResponse.error
			),
		[payResponse.response, payResponse.isLoading, payResponse.error]
	);
	useEffect(() => {
		if (sendEmailResponse.response) {
			setSnackBarContent("email sent");
			snackBarRef.current.openSnackBar();
		}
	}, [sendEmailResponse.response]);

	const paySalaryHandler = () => {
		const data = [];
		let newFilteredData = [...filteredData];
		newFilteredData = newFilteredData.map((ele) => {
			if (ele.checked) {
				data.push(ele.ownerId);
				return { ...ele, status: "Paid", checked: false };
			}
			return ele;
		});
		// Added the snackbar after the salary is paid from the payroll dashboard.
		setSnackBarContent(t("Salary has been paid successfully"));
		snackBarRef.current.openSnackBar();

		setFilteredData(newFilteredData);
		setTotalData(newFilteredData);
		console.log("staff val", { month: currentMonth, owners: data });
		setPayBody(JSON.stringify({ month: currentMonth, owners: data }));
	};

	const emailPayHandler = () => {
		const data = [];
		let newFilteredData = [...filteredData];
		newFilteredData = newFilteredData.map((ele) => {
			if (ele.checked) {
				data.push(ele.ownerId);
				return { ...ele, checked: false };
			}
			return ele;
		});
		setFilteredData(newFilteredData);
		setEmailBody(JSON.stringify({ month: currentMonth, id: data }));
	};
	const setAllChecked = (checked) => {
		setIsChecked(checked);
		setIsDisabled(checked);
		setFilteredData((prevData) =>
			[...prevData].map((ele) => ({
				...ele,
				checked: ele.verification === "Verified" ? checked : false,
			}))
		);
	};
	useEffect(() => {
		if (downloadPayslipResponse.response) {
			fetch(downloadPayslipResponse.response?.pdf[0], {
				mode: "cors",
			})
				.then((response) => response.blob())
				.then((blob) => {
					let blobUrl = window.URL.createObjectURL(blob);
					console.log("starting download");
					var link = document.createElement("a");
					link.download = `Payslip.pdf`;
					link.href = blobUrl;
					document.body.appendChild(link);
					link.click();
					link.remove();
				})
				.catch((e) => console.error("value error", e));
			setDownloadBody(null);
		}
	}, [downloadPayslipResponse.response]);

	const downloadSlipHandler = () => {
		const data = [];
		let newFilteredData = [...filteredData];
		newFilteredData = newFilteredData.map((ele) => {
			if (ele.checked) {
				data.push(ele.ownerId);
				return { ...ele, checked: false };
			}
			return ele;
		});
		setSnackBarContent(t("Downloading Payslip"));
		snackBarRef.current.openSnackBar();
		setFilteredData(newFilteredData);
		setDownloadBody(JSON.stringify({ id: data, month: currentMonth }));
	};
	function exportcsvlist() {
		var csvrow = [];
		var allel = [
			[
				"Employee ID",
				"Last Name",
				"First Name",
				"Profile",
				"Total Gross",
				"TODeduction",
				"Net Pay",
				"Status",
			],
		];
		var temp = filteredData;
		for (var i = 0; i < temp.length; i++) {
			allel.push([
				temp[i].id,
				temp[i].lastName,
				temp[i].firstName,
				temp[i].profile,
				temp[i].totalGross,
				temp[i].totalDeductions,
				temp[i].netPay,
				temp[i].status ? "Paid" : "Not Paid",
			]);
		}
		console.log(allel);
		for (var i = 0; i < allel.length; i++) {
			csvrow.push(allel[i].join(","));
		}
		var csvstring = csvrow.join("%0A");
		console.log("staff csv", csvstring);
		var link = document.createElement("a");
		link.href = "data:attachment/csv," + csvstring;
		link.target = "_blank";
		link.download = "paySlipCSV.csv";
		link.click();
	}
	function exportalldetail() {
		var csvrow = [];
		var allel = [
			[
				"Employee ID",
				"Last Name",
				"First Name",
				"Profile",
				"Basic Salary",
				"Currency",
				"Earning",
				,
				,
				,
				,
				,
				,
				,
				,
				,
				"Deductions",
				,
				,
				,
				,
				,
				,
				,
				,
				,
				"Total deduction",
				"Total Gross earning",
				"Net Pay",
				"Status",
			],
		];
		allel.push([
			,
			,
			,
			,
			,
			,
			"Variable1",
			"Amount1",
			"Variable2",
			"Amount2",
			"Variable3",
			"Amount3",
			"Variable4",
			"Amount4",
			"Variable5",
			"Amount5",
			"Variable1",
			"Amount1",
			"Variable2",
			"Amount2",
			"Variable3",
			"Amount3",
			"Variable4",
			"Amount4",
			"Variable5",
			"amount5",
			,
			,
			,
			,
			,
		]);

		var temp = filteredData;
		for (var i = 0; i < temp.length; i++) {
			let st = "";
			let deduct = [];
			let earning = [];

			for (var j = 0; j < 5; j++) {
				if (temp[i].earning[j]) {
					console.log("1", temp[i].earning[j].variable);
					earning.push([
						temp[i].earning[j].variable,
						temp[i].earning[j].amount,
					]);
				} else {
					console.log("1", temp[i].earning[j]);
					earning.push([, ,]);
				}
			}
			for (var j = 0; j < 5; j++) {
				if (temp[i].deductions[j]) {
					deduct.push([
						temp[i].deductions[j].variable,
						temp[i].deductions[j].amount,
					]);
				} else {
					console.log("1", temp[i].earning[j]);
					deduct.push([, ,]);
				}
			}
			allel.push([
				temp[i].id,
				temp[i].lastName,
				temp[i].firstName,
				temp[i].profile,
				temp[i].basicSalary,
				temp[i].currency,
				earning,
				deduct,
				temp[i].totalGross,
				temp[i].totalDeductions,
				temp[i].netPay,
				temp[i].status ? "Paid" : "Not Paid",
			]);
		}
		console.log(allel);
		for (var i = 0; i < allel.length; i++) {
			csvrow.push(allel[i].join(","));
		}
		var csvstring = csvrow.join("%0A");
		console.log(csvstring);
		var link = document.createElement("a");
		link.href = "data:attachment/csv," + csvstring;
		//link.type = 'MIME'
		link.target = "_blank";
		link.download = "file.csv";

		link.click();
	}

	const [staffId, setStaffId] = useState([]);
	const [showPayment, setShowPayment] = useState(false);
	const [paymentIntentBody, setPaymentIntentBody] = useState(null);
	const [paymentIntent, setPaymentIntent] = useState(null);
	const [multiplePaymentInfo, setMultiplePaymentInfo] = useState([]);
	const [transferPaymentIntent, setTransferPaymentIntent] = useState(null);
	const [transferGroupId, setTransferGroupId] = useState(null);
	const [createTransferBody, setCreateTransferBody] = useState(null);
	const [paymentIntentId, setPaymentIntentId] = useState(null);

	const handleOnlinePayment = () => {
		let multiPaymentArray = [];
		console.log(filteredData);
		setMonth(`${date.split(" ")[1]}, ${date.split(" ")[2]}`);
		let moneyToBePaid = 0;
		let staffCount = 0;
		for (let i = 0; i < filteredData.length; i++) {
			if (
				filteredData[i].status === "Not Paid" &&
				filteredData[i].checked == true
			) {
				const currentMoney = parseFloat(
					filteredData[i].netPay.replace(/\,/g, "").split(" ")[1]
				);
				//console.log({ currentMoney });
				moneyToBePaid += currentMoney;
				console.log(filteredData[i].ownerId);
				setStaffId(filteredData[i].ownerId);
				multiPaymentArray.push({
					daycareProfessionalID: filteredData[i].ownerId,
					amount: (currentMoney * PAYMENT_MULTIPLIER).toString(),
				});
				staffCount = staffCount + 1;
			}
		}
		setMultiplePaymentInfo(multiPaymentArray);
		setPayableAmount(moneyToBePaid);
		setSelectedStaff(staffCount);
		setModalOpen(true);
	};

	const getPaymentIntent = useFetch({
		url: "/createpaymentintent",
		body: paymentIntentBody,
		method: "POST",
		accessibility: accessibilityData["Payroll"],
	});

	const getTransferPaymentIntent = useFetch({
		url: "/createtransferpaymentintent",
		body: transferPaymentIntent,
		method: "POST",
		accessibility: accessibilityData["Payroll"],
	});

	const createTransfer = useFetch({
		url: "/createtransfers",
		method: "POST",
		body: createTransferBody,
		accessibility: accessibilityData["Payroll"],
	});

	useEffect(() => {
		if (getPaymentIntent.response) {
			console.log(getPaymentIntent.response);
			if (getPaymentIntent.response.paymenIntentClientSecret) {
				setPaymentIntent(getPaymentIntent.response.paymentIntentClientSecret);
				setShowPayment(true);
			} else if (getPaymentIntent.response.error) {
				setSnackBarContent(getPaymentIntent.response.error);
				snackBarRef.current.openSnackBar();
			}
		}
	}, [getPaymentIntent.response]);

	const [countryCode, setCountryCode] = useState(null);
	useEffect(() => {
		const body = getTransferPaymentIntent.response;
		if (getTransferPaymentIntent.response) {
			if (body.eligibleForTransfer) {
				setCountryCode(getTransferPaymentIntent.response?.country);
				setPaymentIntent(
					getTransferPaymentIntent.response?.paymentIntentClientSecret
				);
				setTransferGroupId(getTransferPaymentIntent.response?.transferGroup);
				setPaymentIntentId(getTransferPaymentIntent.response?.paymentIntentId);
				setShowPayment(true);
			} else {
				setSnackBarContent(
					t("Some of the accounts are not verified or connected to stripe")
				);
				snackBarRef.current.openSnackBar();
			}
		}
	}, [getTransferPaymentIntent.response]);

	useEffect(() => {
		if (createTransfer.response) {
			const body = createTransfer.response.transfers;
			console.log(createTransfer.response);
			if (body[0].status === "Transfered") {
				paySalaryHandler();
				setModalOpen(true);
				setPaymentSuccess(true);
			}
		}
	}, [createTransfer.response]);

	//handle onclicking the make payment button
	const handlePaymentConfirmationIntent = () => {
		setTransferPaymentIntent(
			JSON.stringify({
				daycareProfessionals: multiplePaymentInfo,
				amount: payableAmount * PAYMENT_MULTIPLIER,
			})
		);

		console.log({ multiplePaymentInfo, payableAmount });
		console.log({ filteredData });
	};

	//!this handles the closing of stripeModal
	const handleStripeToggle = () => {
		setShowPayment(false);
		setPaymentIntentBody(null);
		setTransferPaymentIntent(null);
		setPaymentIntent(null);
	};

	const handleToggle = () => {
		setModalOpen(false);
		if (paymentSuccess) {
			setPaymentIntentBody(null);
			setPayableAmount(0);
			setSelectedStaff(0);
			setPaymentSuccess(false);
		}
	};

	//!this handles the showing payment modal once the payment is done
	const handleOnlinePaymentSucceed = () => {
		console.log("Handle Online Payment Success!");
		setCreateTransferBody(
			JSON.stringify({
				daycareProfessionals: multiplePaymentInfo,
				transferGroup: transferGroupId,
				paymentIntentId: paymentIntentId,
				country: countryCode,
			})
		);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100vh",
				paddingTop: "80px",
				paddingBottom: "50px",
			}}
		>
			<SearchBar
				onChange={(text) => searchTextHandler(text.toLowerCase())}
				placeHolder={t("Search by staff's name")}
			/>
			<SnackBar ref={snackBarRef} content={snackBarContent} />
			{showPayment && (
				<CardStripe
					isOpen={showPayment}
					paymentIntent={paymentIntent}
					countryCode={countryCode}
					toggle={handleStripeToggle}
					onSuccess={handleOnlinePaymentSucceed}
				/>
			)}
			{modalOpen && (
				<PaymentConfirmationModal
					paymentSuccess={paymentSuccess}
					paymentConfirmationIntent={() => {
						handlePaymentConfirmationIntent();
					}}
					month={month}
					isOpen={modalOpen}
					payableAmount={payableAmount}
					selectedStaff={selectedStaff}
					handleToggle={handleToggle}
				/>
			)}
			<br></br>
			<div className={`${styles.labels}`}>
				<div
					className={`d-flex justify-content-between align-items-center ${styles.generate}`}
				>
					<Button
						disabled={
							isLoading ||
							(totalPaid == 0 ? false : true) ||
							accessibilityData["Payroll"] === "Read"
						}
						onClick={() => {
							console.log(totalData.length);
							console.log(filteredData.length);
							handleGenerateAdHocData();
						}}
					>
						{t("Generate Payroll")}
					</Button>
				</div>
				<div className={`${styles.month}`}>
					<div
						className="d-flex align-items-center justify-content-center"
						style={{ margin: "auto 0" }}
					>
						<MDBIcon icon="chevron-left" onClick={() => changeDate(-1)} />
						<h6 className="mb-0 mx-2"
							style={{ width: "180px", textAlign: "center" }}>{currentMonth}</h6>
						<MDBIcon icon="chevron-right" onClick={() => changeDate(1)} />
					</div>
				</div>

				<p className="d-flex float-right"
					style={{ margin: "auto 0" }} >
					{t("Currency")}: {currency.split(" ")[0] + " " + currency.split(" ")[1]}
				</p>

				<div className="d-flex justify-content-between align-items-center">
					<MDBDropdown>
						<MDBDropdownToggle
							caret
							color="indigo"
							disabled={!isDisabled || accessibilityData["Payroll"] === "Read"}
							className="text-white"
						>
							{t("Pay")}
						</MDBDropdownToggle>
						<MDBDropdownMenu basic>
							<MDBDropdownItem
								disabled={!isDisabled}
								onClick={paySalaryHandler}
							>
								{t("By Cash")}
							</MDBDropdownItem>
							{/* RM: Disabled the online payment
							<MDBDropdownItem
								disabled={!isDisabled}
								onClick={handleOnlinePayment}
							>
								{t("By Online Payment")}
							</MDBDropdownItem>
					        */}
						</MDBDropdownMenu>
					</MDBDropdown>
					{/* <Button >
            Pay
          </Button> */}
					{/* //RM: Disabled Issue payslip button because of email functionality
					<Button
						disabled={!isDisabled || accessibilityData["Payroll"] === "Read"}
						onClick={emailPayHandler}
					>
						{t("Issue payslip")}
					</Button>
		  		*/}

					<DropDownMenu
						disabled={
							error || !isDisabled || accessibilityData["Payroll"] === "Read"
						}
						options={[
							{ name: "Download Payslip", click: downloadSlipHandler },
							{/* disabling both options	{ name: "Extract List View", click: exportcsvlist },
							{ name: "Extract All Details", click: exportalldetail }, */}
						]}
						icon="bars"
						width="160px"
					/>
				</div>
			</div>
			<br></br>

			{isLoading || getPayRoll.isLoading ? (
				<Loader />
			) : error ? (
				<p style={{ textAlign: "center" }} className="text-muted">
					{t(`No Data Available for this month`)}
				</p>
			) : !totalData.length ? (
				<Loader />
			) : (
				<Table
					direction={direction}
					data={filteredData}
					isFirstSortable={false}
					tableHeader={[
						[t("Employee ID"), () => directionHandler("id", 0)],
						[t("First Name"), () => directionHandler("firstName", 1)],
						[t("Last Name"), () => directionHandler("lastName", 2)],
						[t("Profile"), () => directionHandler("profile", 3)],
						[t("Total Gross"), () => directionHandler("totalGross", 4)],
						[t("Deduction"), () => directionHandler("totalDeductions", 5)],
						[t("Net Pay"), () => directionHandler("netPay", 6)],
						[t("Status"), () => directionHandler("status", 7)],
						[
							<div className="d-flex align-items-center justify-content-center">
								<input
									type="checkbox"
									checked={isChecked}
									disabled={
										filteredData.length == 0 || country === "in"
											? true
											: false || accessibilityData["Payroll"] === "Read"
									}
									onClick={(event) => setAllChecked(event.target.checked)}
								/>
							</div>,
						],
					]}
					tableItems={(data, index) => (
						<TableItem
							data={[
								data.id,
								data.firstName,
								data.lastName,
								data.profile,
								data.totalGross,
								data.totalDeductions,
								data.netPay,
								data.status,
								data.verification === "Verified" ? (
									<div className="d-flex align-items-center justify-content-center">
										<input
											type="checkbox"
											checked={data.checked}
											disabled={accessibilityData["Payroll"] === "Read"}
											onClick={(event) => {
												event.stopPropagation();
												const newFilteredData = [...filteredData];
												if (country !== "in") {
													newFilteredData[index].checked = event.target.checked;
													if (
														newFilteredData.findIndex((ele) => ele.checked) ===
														-1
													) {
														setIsDisabled(false);
													} else {
														setIsDisabled(true);
													}
													setFilteredData(newFilteredData);
												} else {
													newFilteredData[index].checked = event.target.checked;
													for (let i = 0; i < newFilteredData.length; i++) {
														if (i !== index) {
															newFilteredData[i].checked = false;
														}
													}
													if (
														newFilteredData.findIndex((ele) => ele.checked) ===
														-1
													) {
														setIsDisabled(false);
													} else {
														setIsDisabled(true);
													}
													setFilteredData(newFilteredData);
												}
												console.log("checked", event.target.checked);
											}}
										/>
									</div>
								) : (
									<div className="d-flex align-items-center justify-content-center">
										<MDBPopover placement="top" popover clickable id="popper1">
											<MDBBtn className={classes.button}>
												<InfoIcon color="secondary" />
											</MDBBtn>
											<div>
												<MDBPopoverBody>
													{t(
														"Account details are not verified with Stripe yet, Please check Staff account details."
													)}
												</MDBPopoverBody>
											</div>
										</MDBPopover>
									</div>
								),
							]}
						/>
					)}
					tableFooter={[
						`${t("Total number of staff")} = ${totalEntries}`,
						"",
						totalGrossSum,
						totalDeductionSum,
						totalNetSum,
						`${totalPaid}/${totalEntries}`,
						"",
					]}
				/>
			)}
		</div>
	);
};

export default PayRoll;
