import React, { useEffect } from "react";
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DropDown = (props) => {
  const { t } = useTranslation();
  return (
    <MDBDropdown>
      <MDBDropdownToggle
        disabled={props.disabled}
        caret
        color="indigo"
        className="text-capitalize text-white"
        style={{
          textTransform: `${props.secondaryButton ? "none" : ""}`,
          padding: `${props.secondaryButton ? "0rem 0.5rem" : ""}`,
          fontSize: `${props.secondaryButton ? "15px" : ""}`,
        }}
        onClick={(event) => event.stopPropagation()}
      >
        {t(props.title)}
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem header>{props.header}</MDBDropdownItem>
        {props.list.map((item) => (
          <MDBDropdownItem
            onClick={(event) => {
              event.stopPropagation();
              props.selectHandler(event);
            }}
            className="d-inline-block text-truncate"
          >
            {t(item)}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

DropDown.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  list: PropTypes.array,
  selectHandler: PropTypes.func,
};
export default DropDown;
