import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";

import * as Reducers from "./data.reducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createForms } from "react-redux-form";
import { InitialFeedback } from "../form";

const reducers = combineReducers({
  salary: Reducers.Salary,
  staff: Reducers.Staff,
  token: Reducers.tokenReducer,
  childdata: Reducers.childdata,
  childdetail: Reducers.childdetail,
  sectionname: Reducers.sectionnames,
  ownerlist: Reducers.ownerlist,
  section: Reducers.section,
  groupname: Reducers.groupname,
  group: Reducers.group,
  category: Reducers.category,
  subcategory: Reducers.subcategory,
  childenrolldetail: Reducers.childenroll,
  activity: Reducers.activity,
  twilioDevice: Reducers.TwilioDevice,
  parentsData: Reducers.ParentsData,
  callHistory: Reducers.CallHistory,
  currency: Reducers.Currency,
  nestedData: Reducers.NestedData,
  nestedSection: Reducers.NestedSection,
  newEmail: Reducers.NewEmail,
  newMeeting: Reducers.newMeeting,
  config: Reducers.config,
  country: Reducers.country,
  message: Reducers.message,
  accessibility : Reducers.accessibleModules,
  ...createForms({
    feedback: InitialFeedback,
  }),
});

export default reducers;
