import React, { useState, useEffect } from "react";
import useFetch from "../../CustomHooks/useFetch";
import { MDBIcon } from "mdbreact";
import Loader from "../../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Meetings = ({ date }) => {
	const { t } = useTranslation();
	const [meetingBody, setMeetingBody] = useState(null);
	const [data, setData] = useState(null);
	const history = useHistory();
	const { response, isLoading, error } = useFetch({
		url: "/connect/dashboard/viewEvents",
		method: "PATCH",
		body: meetingBody,
	});

	useEffect(() => {
		if (response) {
			setData(response.length);
		}
	}, [response]);
	useEffect(() => {
		const currentDate = new Date(date),
			nextDate = new Date(date);
		nextDate.setDate(nextDate.getDate() + 1);
		setMeetingBody(
			JSON.stringify({
				todayDay: currentDate.getDate(),
				pastMonth: currentDate.getMonth(),
				pastYear: currentDate.getFullYear(),
				futureDay: nextDate.getDate(),
				futureMonth: nextDate.getMonth(),
				futureYear: nextDate.getFullYear(),
				subModule: "Parents",
			})
		);
	}, [date]);
	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			<div className="d-flex align-items-center justify-content-center mb-3">
				<p className="m-0 pr-1 text-center font-weight-bold">{t("Meetings")}</p>
				<MDBIcon
					icon="chevron-right"
					onClick={() => {
						history.push(`/newConnect/parents?meetingDate=${date}`);
					}}
				/>
			</div>
			{isLoading ? <Loader /> : <h3>{error ? 0 : data}</h3>}
		</div>
	);
};

export default Meetings;
