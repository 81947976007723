import React, { useEffect, useState, useRef } from "react";
import Loader from "../../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";
import usePyFetch from "../../CustomHooks/usePyFetch";
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import useFetch from "../../CustomHooks/useFetch";
import { useTranslation } from "react-i18next";

const StaffChart = ({ date }) => {
  const {t} = useTranslation();
  const [chartBody, setChartBody] = useState(null);
  const chartResponse = useFetch({ url: "/day_leave_on_day", method: "POST", body: chartBody });
  const chartRef = useRef();
  useEffect(() => {
    if (
      !chartResponse.isLoading &&
      !chartResponse.error &&
      chartRef.current &&
      chartResponse.response
    ) {
      echarts.use([TooltipComponent, PieChart, CanvasRenderer, GridComponent]);
      let chart = echarts.init(chartRef.current);
      const leave = +chartResponse.response.on_leave,
        present = chartResponse.response.total_staff - chartResponse.response.on_leave;
      chart.setOption({
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "enrollment",
            type: "pie",
            radius: "50%",
            data: [
              { value: leave, name: t(`on leave`) + `(${leave})` },
              {
                value: present,
                name: t("present") + `(${present})`,
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    }
  }, [chartResponse.isLoading, chartResponse.error, chartRef.current]);
  useEffect(() => {
    console.log("staff pie date", date);
    setChartBody(JSON.stringify({ start_date: date }));
  }, [date]);
  return chartResponse.isLoading ? (
    <Loader />
  ) : (
    <div ref={chartRef} style={{ width: "100%", height: "calc(100% - 20px)" }}></div>
  );
};
export default StaffChart;
