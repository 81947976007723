import React from "react";
import Loader from "../../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";

const ActivityStatus = ({ percentage }) => (
  <div
    className="w-100 d-flex"
    style={{ borderRadius: "16px", backgroundColor: "#b5c4ff", height: "calc(100% - 25px)" }}
  >
    {percentage === null ? (
      <Loader />
    ) : (
      <>
        {percentage === 0 ? null : (
          <div
            style={{ width: `${percentage}%`, backgroundColor: "#304391", borderRadius: "16px" }}
            className="d-flex justify-content-center align-items-center text-white"
          >{`${percentage}%`}</div>
        )}
        {100 - percentage === 0 ? null : (
          <div
            style={{ width: `${100 - percentage}%` }}
            className="d-flex justify-content-center align-items-center text-white"
          >{`${100 - percentage}%`}</div>
        )}
      </>
    )}
  </div>
);

export default ActivityStatus;
