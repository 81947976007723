import React from "react";
const Loader = () => (
  <div
    className=" d-flex justify-content-center align-items-center"
    style={{ height: "100%", width: "100%" }}
    data-testid="loader"
  >
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);
export default Loader;
