export const InitialFeedback ={
    employeeCode: '',
    photo:'',
    owner: '',
    lastName: '',
    dob: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    _id: '',
    idDetails: '',
    idType: '',
    joining: '',
    manager: '',
    mobile: '',
    nationality: '',
    groupNames: '',
    sectionNames: ''
};