import React, { lazy, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import Login from "./Logincomponent";
import Reset from "./Resetcomponent";
import {
	Switch,
	Route,
	Redirect,
	useLocation,
	useHistory,
} from "react-router-dom";
import Header from "./Header";
// RM: removal of staffmanagement folder from src
//import StaffProfileDM from "../staffmanagement/staffprofileDM";
//import CreateDP from "../staffmanagement/createDaycareProffesional";
//import StaffDetail from "../staffmanagement/StaffDetail";
import ErrorBoundary from "../ErrorBoundary";
import { useSelector, useDispatch } from "react-redux";
import {
	login,
	setloading,
	setTwilioDevice,
	setParents,
	group,
	groupnames,
	section,
	sectionNames,
	addCurrency,
	ownerlist,
	nestedGroup,
	logout,
	activity,
	addConfig,
	addSubmodule,
	addKeywords,
	addCountry,
	addSectionObject,
	addAccessibleModulesData,
} from "../Redux/actions/actionCreator/data.action";
const Profile = lazy(() =>
	import(/* webpackChunkName: "Profile" */ "./profile")
);

// removed Staffmanageent folder from component folder
//const Staffmanament = lazy(() =>
//	import(
//		/* webpackChunkName: "Staffmanagement" */ "./Staffmanageent/staffmanagementmain"
//	)
// ); 
//const PayrollComp = lazy(() =>
//	import(/* webpackChunkName: "ViewActivity" */ "./Staffmanageent/payroll")
//);


const User = lazy(() =>
	import(/* webpackChunkName: "User" */ "./Forgetpassword/userid")
);
const Otpscreen = lazy(() =>
	import(/* webpackChunkName: "Otpscreen" */ "./Forgetpassword/otpscreen")
);
const Resetpassword = lazy(() =>
	import(
		/* webpackChunkName: "Resetpassword" */ "./Forgetpassword/resetpassword"
	)
);

import config from "../config";
import { Device } from "twilio-client";
//import IncomingCalls from "./Connect/IncomingCalls";
const ChildSideNav = lazy(() =>
	import(/* webpackChunkName: "" */ "./childdetail/ChildSideNav")
);
import { data } from "./SideNavData";
import { Suspense } from "react";
import Loader from "./childdetail/charts/Loader";
import AllowedData from "./AllowedModules.json";
import useFetch from "./CustomHooks/useFetch";
import MyDay from "./Daycare/MyDay/MyDay";
import PayRoll from "./StaffManagement/PayRoll";
import { code } from "./childdetail/ChildRegister/countriesListWithISOCode";
import ChildRegister from "./childdetail/ChildRegister/ChildRegister";
import LanguageSelect from "./LanguageSelection";
import styles from "./MainComponent.module.scss";

function Main() {
	const dispatch = useDispatch();
	const token = useSelector((state) => state.token).token;
	const isLoading = useSelector((state) => state.token).isloading;
	const allowed = useSelector((state) => state.config);
	const [allowedData, setAllowedData] = useState([]);
	console.log("allowed allowed", allowed);
	const location = useLocation();
	const history = useHistory();
	console.log(localStorage.getItem("id"), "id of the person");
	const nestedResponse = useFetch({ url: `/nested/section&group` });
	const countryData = useFetch({ url: `/daycareDetails` });
	if (countryData.response) {
		localStorage.setItem("daycareName", countryData.response.name);
	}

	useEffect(() => {
		if (countryData.response) {
			const country = countryData.response.addresses.country;
			let cCode = Object.keys(code).find((value) => code[value] === country);
			if (!cCode) {
				cCode = "IN";
			}
			console.log(
				"gnanasurya country",
				country,
				cCode.toLowerCase(),
				countryData.response.photo
			);
			if (cCode) {
				dispatch(addCountry([cCode.toLowerCase(), countryData.response.photo]));
			}
		}
	}, [countryData.response]);
	useEffect(() => {
		console.log("allowed data2", allowed);
	}, [allowed]);
	useEffect(() => {
		console.log(
			"nested response",
			nestedResponse.response,
			nestedResponse.isLoading,
			nestedResponse.error
		);
		//nestedResponse.response?.message === null 
		// adding above creates regression on the section group drop down
		// if the new daycare manager id when there is no section data
		console.log("nestedResponse is:", nestedResponse.response);
		if (nestedResponse.response && !nestedResponse.response.message ) {
			const data = {};
			Object.entries(nestedResponse.response).map(([key, value]) => {
				data[key] = Object.keys(value.groups);
			});
			console.log("nested value", data);
			dispatch(nestedGroup(data));
			dispatch(addSectionObject(nestedResponse.response));
		}
	}, [nestedResponse.response, nestedResponse.isLoading, nestedResponse.error]);
	useEffect(() => {
		if (token) {
			console.log("twilio token", token);
			const device = new Device();
			let resok = true;
			fetch(`${config.devurl}/ownerNames `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					resok = res.ok;
					return res.json();
				})
				.then((data) => {
					console.log("data owner in child detail", data);
					if (resok) {
						dispatch(ownerlist(data));
					} else {
						if (data.error === "Request failed with status code 500") {
							console.log("working");
							seterr("please login again");

							dispatch(logout());
							localStorage.removeItem("token");
						}
					}
				})
				.catch((err) => {
					console.log("Error occured on login ", err);
				});
			fetch(`${config.devurl}/token`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					resok = res.ok;
					return res.json();
				})
				.then((res) => {
					if (resok) {
						console.log("twilio response", res);
						device.setup(res.token, { debug: true });
						console.log("twilio device", device);
						dispatch(setTwilioDevice(device));
					}
				})
				.catch((err) => console.log("twilio error", err));
			fetch(`${config.devurl}/connectDashboard/channelMember/parent`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					console.log("response received for parents", res);
					resok = res.ok;
					return res.json();
				})
				.then((data) => {
					if (resok) {
						// console.log("data stafflist 49 for parents", data);
						console.log("parents list is", data, data.parents);
						dispatch(setParents(data.parents));
					} else {
						console.log("error is", data.error);
					}
				})
				.catch((err) => console.log("Error occured on login", err));
			fetch(`${config.devurl}/groups/viewAll `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					resok = res.ok;
					return res.json();
				})
				.then((data) => {
					console.log("data group", data);
					if (resok) {
						dispatch(groupnames(data));
						dispatch(group(data));
					} else {
						if (data.error === "Request failed with status code 500") {
							console.log("working");
						}
					}
				})
				.catch((err) => {
					console.log("Error occured on login ", err);
				});
			fetch(`${config.devurl}/sections/viewAll`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					resok = res.ok;
					return res.json();
				})
				.then((data) => {
					console.log("data section", data);
					if (resok) {
						dispatch(sectionNames(data));
						dispatch(section(data));
					} else {
						if (data.error === "Request failed with status code 500") {
							console.log("working");
						}
					}
				})
				.catch((err) => {
					console.log("Error occured on login ", err);
				});
			fetch(`${config.devurl}/currency/view`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				credentials: "same-origin",
			})
				.then((res) => {
					resok = res.ok;
					return res.json();
				})
				.then((data) => {
					console.log("data stafflist for currency", data);
					if (resok) {
						// setCurrency(data.currency);
						dispatch(addCurrency(data.currency));
					}
				})
				.catch((err) => {
					console.log("Error occured on login ", err);
				});
		}
	}, [token]);
	React.useEffect(() => {
		const config = JSON.parse(localStorage.getItem("config"));
		if (config) {
			console.log(
				"login data data",
				config.data,
				config.subModule,
				config.keywords
			);
			dispatch(addConfig(config.data));
			dispatch(addSubmodule(config.subModule));
			dispatch(addKeywords(config.keywords));
		}

		const accountType = localStorage.getItem("accountType");
		console.log(accountType, "Account Type");
		if (accountType === "daycare") {
			const accessibilityData = JSON.parse(localStorage.getItem("adminLabels"));
			if (accessibilityData) {
				const payload = accessibilityData.modules;
				Object.keys(payload).forEach((key) => {
					Object.keys(payload[key].subModules).forEach(
						(ele) => (payload[key].subModules[ele].accessibility = "Edit")
					);
				});
				console.log(payload, "Payload");
				dispatch(addAccessibleModulesData(payload));
			}
		} else {
			const accessibilityData = JSON.parse(localStorage.getItem("payload"));
			if (accessibilityData) {
				const payload = accessibilityData.modules;
				dispatch(addAccessibleModulesData(payload));
			}
		}
	}, []);
	React.useEffect(() => {
		var token = localStorage.getItem("token");
		if (token) {
			console.log("token found");
			dispatch(login(JSON.parse(token)));
			dispatch(setloading(false));
		} else {
			console.log("token not found");
			dispatch(setloading(false));
		}
	}, []);
	React.useEffect(() => {
		const username = localStorage.getItem("username");
		setAllowedData(AllowedData[username]);
	}, [token]);
	React.useEffect(() => {
		if (token && allowed.config) {
			const pathName = location.pathname.split("/")[1];
			const index = allowed.config.findIndex((ele) => {
				if (ele.type) {
					return (
						ele.data.findIndex((ele) => ele.path.includes(pathName)) !== -1
					);
				} else {
					return ele.path.includes(pathName);
				}
			});
			console.log("data location", pathName);
			if (index == -1) {
				console.log("data location", pathName);
				console.log("route not allowed");
				history.replace("/childManagement/childProfile");
			}
		}
	}, [location.pathname, allowed.config]);
// removal of duplicate staff information
/*	const StaffWithId = ({ match }) => {
		console.log("param called", match.params.staffid);
		return <StaffDetail id={match.params.staffid} />;
	}; */
	if (isLoading)
		return (
			<div
				className="w-100 d-flex justify-content-center align-items-center"
				style={{ height: "100vh" }}
			>
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	console.log("routes", allowed);
	if (token) {
		return (
			<main className={styles.main}>
				<ErrorBoundary>
					<Header />
				
						<Suspense fallback={<Loader />}>
							<Switch>
							{/*	<Route path="/createDP" component={CreateDP} /> */}
							{/*	<Route
									exact
									path="/Staff_manage"
									component={Staffmanament}
								></Route>
								<Route
									exact
									path="/Staff_payroll"
									component={PayrollComp}
								></Route> */ }
								<Route
									exact
									path="/childManagement/childRegister"
									component={ChildRegister}
								/>
								{allowed.submodule.map((ele, index) => (
									<Route
										key={index}
										path={ele[0]}
										render={() => (
											<ChildSideNav data={ele[2]} sideNav={ele[1]} />
										)}
									/>
								))}
								<Redirect to={"/childManagement/childProfile"} />
							</Switch>
						</Suspense>
				
				</ErrorBoundary>
			</main>
		);
	} else {
		return (
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route path="/login" component={Login} />
					<Route exact path="/forgetpass/user_id" component={User} />
					<Route exact path="/resetpassword" component={Reset} />
					<Route exact path="/user/resetpassword" component={Resetpassword} />
					<Route exact path="/otp" component={Otpscreen} />
					<Redirect to="/login" />
				</Switch>
			</Suspense>
		);
	}
}
export default Main;
