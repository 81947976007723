import React, { useEffect, useState } from "react";
import AddressInput from "./AddressInput";
import CustomInput from "./CustomInput";
import InputAddress from "./InputAddress";
import InputArray from "./InputArray";
import InputDatalist from "./InputDatalist";
import InputDropDown from "./InputDropDown";
import InputImage from "./InputImage";
import InputMultipleDropDown from "./InputMultipleDropdown";
import InputPhone from "./InputPhone";
import InputValueDropDown from "./InputValueDropDown";
import VaccineInput from "./VaccineInput";
import InputRadio from "./InputRadio";
import InputDropdownwithOthers from "./InputDropdownOthers";
import "../../css/register.css";
const InputRenderer = ({
	handleChange,
	values,
	data,
	handleArrayChange,
	handleBlur,
	errors,
	isEnrollment,
	isChildProfile,
	disabled,
	...props
}) => {
	let emaildupCheck = 0;
	let mobiledupCheck = 0;

	if (values.motherEmail === values.fatherEmail && values.fatherEmail) {
		emaildupCheck = 1;
	} else {
		emaildupCheck = 0;
	}

	if (values.motherMobile === values.fatherMobile && values.fatherMobile) {
		mobiledupCheck = 1;
	} else {
		mobiledupCheck = 0;
	}

	return data.map((ele) => {
		if (Array.isArray(ele.initialValue) && ele.type !== "multipleDropdown") {
			return (
				<InputArray
					name={ele.name}
					label={ele.label}
					disabled={isEnrollment || disabled}
					value={values[ele.name]}
					onChange={handleArrayChange}
					onBlur={handleBlur}
				/>
			);
		}
		if (Array.isArray(ele)) {
			if (ele[0].type === "vaccine") {
				return (
					<VaccineInput
						values={values}
						ele={ele}
						disabled={isEnrollment || disabled}
						handleBlur={handleBlur}
						handleChange={handleArrayChange}
					/>
				);
			} else if (ele[0].type === "dropdown") {
				return (
					<InputDropdownwithOthers
						values={values}
						ele={ele}
						isEnrollment={isEnrollment}
						handleBlur={handleBlur}
						handleChange={handleChange}
						errors={errors}
						disabled={disabled}
					/>
				);
			} else if (ele[0].type === "string") {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<CustomInput
							key={ele[0].name}
							label={ele[0].label}
							type={ele[0].type}
							name={ele[0].name}
							required={ele[0].required}
							value={values[ele[0].name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
						/>
						<CustomInput
							key={ele[1].name}
							label={ele[1].label}
							type={ele[1].type}
							name={ele[1].name}
							required={ele[1].required}
							value={values[ele[1].name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
						/>
					</div>
				);
			} else {
				return (
					<div className="d-flex align-items-center">
						<InputValueDropDown
							value={[values[ele[0].name], values[ele[1].name]]}
							name={[ele[0].name, ele[1].name]}
							label={[ele[0].label]}
							onBlur={handleBlur}
							onChange={handleChange}
							required={ele[0].required}
							type={ele[0].type}
							disabled={isEnrollment || disabled}
							options={ele[1].options}
						/>
					</div>
				);
			}
		} else {
			switch (ele.type) {
				case "tel":
					return (
						<div className="form-group" style={{ paddingBottom: "26px" }}>
							<InputPhone
								{...ele}
								onChange={handleChange}
								value={values[ele.name]}
								data={values}
								onBlur={handleBlur}
								error={errors[ele.name]}
								disabled={isEnrollment || disabled}
								duplicateMobileError={mobiledupCheck}
							/>
						</div>
					);
				case "file": {
					return (
						<InputImage
							{...ele}
							onChange={handleChange}
							value={values[ele.name]}
							disabled={isEnrollment || disabled}
							style={{
								gridRowStart: "1",
								gridRowEnd: `${Math.floor(data.length - 1) + 1}`,
							}}
						/>
					);
				}

				case "dropdown":
					return (
						<InputDropDown
							prospects={props.prospects}
							value={values[ele.name]}
							label={ele.label}
							name={ele.name}
							options={ele.options}
							onChange={handleChange}
							onBlur={handleBlur}
							defaultName={ele.defaultName}
							required={ele?.required}
							disabled={isEnrollment || disabled}
							error={errors[ele.name]}
						/>
					);
				case "multipleDropdown":
					return (
						<InputMultipleDropDown
							options={ele.options}
							label={ele.label}
							name={ele.name}
							value={values[ele.name] || []}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
						/>
					);
				case "datalist":
					return (
						<InputDatalist
							name={ele.name}
							label={ele.label}
							type={ele.type}
							start={ele.start}
							end={ele.end}
							step={ele.step}
							required={ele.required}
							value={values[ele.name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
							error={errors[ele.name]}
							wrapperStyle={{ marginBottom: "30px" }}
						/>
					);
				case "textarea":
					return isEnrollment ? (
						<div>
							<p className="m-0">Comment</p>
							<textarea
								style={{
									width: "100%",
									border: "rgb(206, 212, 218) solid 1px",
									borderRadius: "5px",
								}}
								value={values[ele.name]}
								id={ele.name}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={disabled}
							/>
						</div>
					) : null;
				case "address":
					return (
						<InputAddress
							value={values[ele.name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
							required={ele.required}
							label={ele.label}
							name={ele.name}
						/>
					);
				case "address1":
					return (
						<AddressInput
							key={ele.name}
							label={ele.label}
							type={ele.type}
							name={ele.name}
							required={ele.required}
							value={values[ele.name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
							error={errors[ele.name]}
							min={ele.min}
						/>
					);
				case "radio":
					return (
						<InputRadio
							value={values[ele.name]}
							label={ele.label}
							name={ele.name}
							options={ele.options}
							onChange={handleChange}
							onBlur={handleBlur}
							defaultName={ele.defaultName}
							disabled={isEnrollment || disabled}
							error={errors[ele.name]}
						/>
					);
				default:
					return (
						<CustomInput
							association={ele.association || ""}
							key={ele.name}
							label={ele.label}
							type={ele.type}
							name={ele.name}
							required={ele.required}
							value={values[ele.name]}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={isEnrollment || disabled}
							error={errors[ele.name]}
							min={ele.min}
							duplicateEmailError={emaildupCheck}
						/>
					);
			}
		}
	});
};

export default InputRenderer;
