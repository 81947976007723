import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import useFetch from "../../CustomHooks/useFetch";
import Table from "../../childdetail/ChildProfile/Table";
import TableItem from "../../childdetail/ChildProfile/TableItem";
import PhotoName from "./PhotoName";
import { MDBIcon } from "mdbreact";
import Loader from "../../childdetail/ChildDashboard/DevelopmentDasboard/charts/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ActivityData = ({ data, sectionName, groupName, setPercentage }) => {
  const { t } = useTranslation();
  const groupData = useFetch({ url: "/groups/viewAll" });
  const [groupPhoto, setGroupPhoto] = useState(null);
  const [sectionPhoto, setSectionPhoto] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const keywords = useSelector((state) => state.config.keywords);

  useEffect(() => {
    if (totalData) {
      let done = 0,
        data = [];
      console.log("finaldata2", sectionName);
      if (sectionName === "All daycare") {
        data = totalData;
      } else {
        data = totalData.filter((ele) => {
          return ele.section.name === sectionName && groupName === "All"
            ? true
            : typeof ele.group === "string"
            ? ele.group === groupName
            : ele.group.name === groupName;
        });
      }
      data.forEach((ele) => {
        if (ele.status) {
          done++;
        }
      });
      setFilteredData(data);
      console.log("finaldata2", data);
      if (data.length) {
        setPercentage(Math.floor((done / data.length) * 100));
      } else {
        setPercentage(0);
      }
    }
  }, [sectionName, groupName]);
  useEffect(() => {
    console.log("groupData.response", groupData.response);
    if (groupData.response && !groupData.response.message) {
      const groups = {},
        sections = {};
      groupData.response.forEach((ele) => {
        groups[ele.name] = { photo: ele.avatar, name: ele.name };
        if (
          ele.section !== null &&
          sections[ele.section.sectionName] === undefined
        ) {
          sections[ele.section.sectionName] = {
            photo: ele.section.avatar,
            name: ele.section.sectionName,
          };
        }
      });
      console.log("group data2", groupData.response, groups, sections);
      setGroupPhoto(groups);
      setSectionPhoto(sections);
    }
  }, [groupData.response]);
  useEffect(() => {
    if (data && groupPhoto && sectionPhoto) {
      console.log("finaldata", data);
      const childPhoto = {};
      data.child_photo_name.forEach((ele) => {
        childPhoto[ele._id] = { name: ele.child_name, photo: ele.photo };
      });
      const finalData = [];
      data.planned_act.forEach((ele) => {
        finalData.push({
          ...ele,
          group: groupPhoto[ele.group],
          section: sectionPhoto[ele.section],
          activityName: ele.title,
          performedByProfessional: "",
          children: "ALL",
          status: false,
        });
      });
      data.group_data.forEach((ele) => {
        finalData.push({
          ...ele,
          section: sectionPhoto[ele.section],
          group: "ALL",
          status: true,
        });
      });
      data.selected_child_data.forEach((ele) => {
        ele.children.forEach((child) =>
          finalData.push({
            ...ele,
            section: sectionPhoto[ele.section],
            group: "ALL",
            children: childPhoto[child],
            status: true,
          })
        );
      });
      console.log("finaldata", finalData);
      setTotalData(finalData);
      setFilteredData(finalData);
    }
  }, [data, sectionPhoto, groupPhoto]);
  return (
    <div style={{ width: "100%", overflowY: "scroll", height: "100%" }}>
      {totalData ? (
        <Table
          data={filteredData}
          isFirstSortable={false}
          tableHeader={[
            [t(keywords.section)],
            [t(keywords.group)],
            [t("Owner")],
            [t("Child")],
            [t("Planned Activity")],
            [t("Status")],
            [t("Time")],
          ]}
          tableItems={(data, index) => (
            <TableItem
              data={[
                <PhotoName data={data.section} />,
                <PhotoName data={data.group} />,
                data.performedByProfessional,
                <PhotoName data={data.children} />,
                data.activityName,
                data.status ? (
                  <MDBIcon icon="check" />
                ) : (
                  <MDBIcon icon="minus" />
                ),
                data.time,
              ]}
            />
          )}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ActivityData;
