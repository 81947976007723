import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';


function Loading(){

    
    return(
    <>
        <div className="col-md-12">
                <div className="row" style={{ marginLeft: '45%',marginTop:'200px' }}>
                    <CircularProgress />
                </div>
        </div>
    </>  
    );
}
export default Loading;