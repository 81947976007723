import * as ActionTypes from "../actionTypes.js/Data";

export const login = (token) => {
  return {
    type: ActionTypes.LOG_IN,
    payload: { token },
  };
};
export const Reset = (token) => {
  return {
    type: ActionTypes.RESET,
    payload: { token },
  };
};
export const otpscreen = (token) => {
  return {
    type: ActionTypes.OTPSCREEN,
    payload: { token },
  };
};
export const logout = () => {
  return {
    type: ActionTypes.LOG_OUT,
  };
};
export const setloading = (token) => {
  return {
    type: ActionTypes.LOAD_ING,
    payload: { token },
  };
};
export const childdata = (data) => {
  console.log(" child data in reducer function", data);
  return {
    type: ActionTypes.CHILDDATA,
    payload: data,
  };
};
export const childdetail = (data) => {
  console.log(" child detail in reducer function", data);
  return {
    type: ActionTypes.CHILDDETAIL,
    payload: data.allDetails,
  };
};
export const childenrolldetail = (data) => {
  console.log(" child detail in reducer function", data);
  return {
    type: ActionTypes.CHILDENROLLDETAIL,
    payload: data,
  };
};
export const sectionNames = (data) => {
  console.log(" section names and details", data);
  return {
    type: ActionTypes.SECTION,
    payload: data,
  };
};
export const section = (data) => {
  console.log("only for  section names  line no-50 Redux fn", data);
  return {
    type: ActionTypes.SECTIONNAMES,
    payload: data,
  };
};
export const newsection = (data) => {
  console.log(" new sec inside data action", data);
  return {
    type: ActionTypes.CREATESEC,
    payload: data,
  };
};
export const newsectionname = (data) => {
  console.log(" new sec inside data action", data);
  return {
    type: ActionTypes.ADD_SEC,
    payload: data,
  };
};
export const editsectionred = (data) => {
  console.log(" edit sec inside data action", data);
  return {
    type: ActionTypes.EDITSECTION,
    payload: data,
  };
};
export const editgroupred = (data) => {
  console.log(" edit group inside data action", data);
  return {
    type: ActionTypes.EDITGROUP,
    payload: data,
  };
};
export const creategroupred = (data) => {
  console.log(" create group inside data action", data);
  return {
    type: ActionTypes.CREATEGROUP,
    payload: data,
  };
};
export const ownerlist = (data) => {
  console.log(" ownerlist inside data action", data);
  return {
    type: ActionTypes.OWNERLIST,
    payload: data,
  };
};
export const groupnames = (data) => {
  console.log(" group", data);
  return {
    type: ActionTypes.GROUPNAME,
    payload: data,
  };
};
export const group = (data) => {
  console.log(" group", data);
  return {
    type: ActionTypes.GROUP,
    payload: data,
  };
};
export const new_group = (data) => {
  console.log(" group", data);
  return {
    type: ActionTypes.ADD_GROUP,
    payload: data,
  };
};
export const category = (data) => {
  return {
    type: ActionTypes.CATEGORY,
    payload: data,
  };
};
export const subcategory = (data) => {
  return {
    type: ActionTypes.SUBCATEGORY,
    payload: data,
  };
};
export const activity = (data) => {
  return {
    type: ActionTypes.ACTIVITY,
    payload: data,
  };
};
export const addactivity = (data) => {
  return {
    type: ActionTypes.CREATEACTIVITY,
    payload: data,
  };
};
export const staffLoading = () => ({
  type: ActionTypes.STAFF_LOADING,
});
export const staffFailed = (errmess) => ({
  type: ActionTypes.STAFF_FAILED,
  payload: errmess,
});

export const addStaff = (staff) => {
  console.log("add staff is called", staff);
  return {
    type: ActionTypes.ADD_STAFF,
    payload: staff,
  };
};
export const addStaffList = (staff) => {
  console.log("add staff is called", staff);
  return {
    type: ActionTypes.ADD_STAFFLIST,
    payload: staff,
  };
};

export const deleteStaff = (data) => {
  console.log("deleteStaff action creator is called with id=", data);
  return {
    type: ActionTypes.DELETE_STAFF,
    payload: data,
  };
};

export const archiveStaff = (data) => {
  console.log("archiveStaff action creator is called with id=", data);
  return {
    type: ActionTypes.ARCHIVE_STAFF,
    payload: data,
  };
};

export const addSalary = (data) => {
  console.log("add salary action creator is called with data:", data);
  return {
    type: ActionTypes.ADD_SALARY,
    payload: data,
  };
};

export const editSalary = (data) => {
  console.log("edit salary action creator is called with data:", data);
  return {
    type: ActionTypes.EDIT_SALARY,
    payload: data,
  };
};

export const deletesect = (data) => {
  console.log("act delete sec", data);
  return {
    type: ActionTypes.DELETESEC,
    payload: data,
  };
};

export const deletesectgroup = (data) => {
  console.log("act delete sec group", data);
  return {
    type: ActionTypes.DELETESECGROUP,
    payload: data,
  };
};
export const deletegroup = (data) => {
  return {
    type: ActionTypes.DELETEGROUP,
    payload: data,
  };
};
export const deleteactivity = (data) => {
  console.log("act delete act", data);
  return {
    type: ActionTypes.DELETEACTIVITY,
    payload: data,
  };
};

export const setTwilioDevice = (data) => {
  console.log("twilio device", data);
  return {
    type: ActionTypes.TWILIO_CLIENT_DEVICE,
    payload: data,
  };
};

export const setParents = (data) => {
  console.log("adding parents", data);
  return {
    type: ActionTypes.ADD_PARENTS,
    payload: data,
  };
};

export const updateParents = (data) => {
  console.log("updating parents", data);
  return {
    type: ActionTypes.UPDATE_PARENTS,
    payload: data,
  };
};

export const addCallHistory = (data) => {
  return {
    type: ActionTypes.ADD_CALLHISTORY,
    payload: data,
  };
};

export const updateCallHistory = (data) => {
  return {
    type: ActionTypes.UPDATE_CALLHISTORY,
    payload: data,
  };
};

export const addCurrency = (data) => {
  return {
    type: ActionTypes.ADD_CURRENCY,
    payload: data,
  };
};

export const updateCurrency = (data) => {
  return {
    type: ActionTypes.UPDATE_CURRENCY,
    payload: data,
  };
};

export const nestedGroup = (data) => {
  return {
    type: ActionTypes.ADD_NESTED,
    payload: data,
  };
};

export const addSectionObject = (data) => {
  return {
    type: ActionTypes.SECTION_OBJECT,
    payload: data,
  };
};

export const newEmail = (data) => {
  return {
    type: ActionTypes.NEW_EMAIL,
    payload: data,
  };
};

export const flushEmail = (data) => {
  return {
    type: ActionTypes.FLUSH_EMAIL,
  };
};

export const newMeeting = (data) => {
  return {
    type: ActionTypes.NEW_MEETING,
    payload: data,
  };
};

export const flushMeeting = () => {
  return {
    type: ActionTypes.FLUSH_MEETING,
  };
};

export const addConfig = (data) => {
  return {
    type: ActionTypes.ADD_CONFIG,
    payload: data,
  };
};
export const addSubmodule = (data) => {
  return {
    type: ActionTypes.ADD_SUBMODULE,
    payload: data,
  };
};
export const addKeywords = (data) => {
  return {
    type: ActionTypes.ADD_KEYWORDS,
    payload: data,
  };
};

export const addId = (data) => {
  return {
    type: ActionTypes.ADD_ID,
    payload: data,
  };
};
export const addCountry = (data) => {
  return {
    type: ActionTypes.ADD_COUNTRY,
    payload: data,
  };
};

export const addSocket = (data) => {
  return {
    type: ActionTypes.ADD_SOCKET,
    payload: data,
  };
};

export const addPreviousMessage = (data) => {
  return {
    type: ActionTypes.ADD_MESSAGE,
    payload: data,
  };
};

export const addAllmessage = (id, messages) => {
  return {
    type: ActionTypes.ADD_ALL_MESSAGE,
    payload: { id, messages },
  };
};

export const addNewMessage = (id, message) => {
  return {
    type: ActionTypes.ADD_NEW_MESSAGE,
    payload: { id, message },
  };
};

export const addAccessibleModulesData = (data) => {
  return {
    type: ActionTypes.ADD_ACCESSIBLE_MODULES_DATA,
    payload: data,
  };
};