import React, { useState, useEffect } from "react";
import InputDropDown from "./InputDropDown";
import CustomInput from "./CustomInput";
const InputDropdownOthers = ({
  ele,
  values,
  handleBlur,
  handleChange,
  errors,
  disabled,
  isEnrollment = false,
  ...props
}) => {
  const [showOthers, setShowOthers] = useState(false);
  useEffect(() => {
    console.log(values[ele[0].name]);
    if (values[ele[0].name] === "Others") {
      setShowOthers(true);
    } else {
      setShowOthers(false);
    }
  }, [values, ele]);
  return (
    <div className="d-flex form-group" style={{ position: "relative" }}>
      <InputDropDown
        prospects={props.prospects}
        value={values[ele[0].name]}
        label={ele[0].label}
        name={ele[0].name}
        options={ele[0].options}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultName={ele[0].defaultName}
        disabled={isEnrollment || disabled}
        error={errors[ele[0].name]}
        style={{ flexGrow: "1" }}
      />
      <div style={{ display: `${showOthers ? "" : "none"}`, flexGrow: "1" }}>
        {showOthers && (
          <CustomInput
            key={ele[1].name}
            label={ele[1].label}
            type={ele[1].type}
            name={ele[1].name}
            required={ele[1].required}
            value={values[ele[1].name]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isEnrollment || disabled}
          />
        )}
      </div>
    </div>
  );
};

export default InputDropdownOthers;
