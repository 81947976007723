import { MDBModal, MDBModalHeader } from "mdbreact";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function SnackBar(props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const snackRef = useRef();
  useImperativeHandle(ref, () => ({
    openSnackBar: () => setIsOpen(true),
  }));
  // useEffect(() => {
  //   let timeout = null;
  //   if (isOpen) {
  //     timeout = setTimeout(() => setIsOpen(false), 2000);
  //   }
  //   return () => {
  //     if (timeout) clearTimeout(timeout);
  //   };
  // }, [isOpen]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={props.isError ? 30000 : 10000}
      onClose={handleClose}
      ref={snackRef}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert onClose={handleClose} severity={props.isError ? "error" : "success"}>
        {props.content}
      </Alert>
    </Snackbar>
  );
}
SnackBar = forwardRef(SnackBar);
export default SnackBar;
