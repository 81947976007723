import { MDBBtn } from "mdbreact";
import React from "react";

const Button = ({ disabled, children, ...props }) => (
  <MDBBtn
    disabled={disabled}
    className={`white-text ${props.className || ""} `}
    style={{
      textTransform: `${props.secondaryButton ? "none" : ""}`,
      padding: `${props.secondaryButton ? "0rem 0.5rem" : ""}`,
      fontSize: `${props.secondaryButton ? "15px" : ""}`,
    }}
    color={props.color || "indigo"}
    {...props}
  >
    {children}
  </MDBBtn>
);

export default Button;
