import React from "react";
import { onlyCountries } from "./countriesListWithStates";

export const data = [
	{
		name: "Child Profile",
		data: [
			{
				name: "photo",
				initialValue: "",
				type: "file",
				label: "Add Avatar",
			},
			{
				name: "firstName",
				initialValue: "",
				label: "First Name",
				required: true,
			},
			{
				name: "lastName",
				initialValue: "",
				label: "Last Name",
				required: true,
			},
			[
				{
					name: "weight",
					initialValue: "",
					label: "Weight",
					type: "number",
				},
				{
					name: "weightUnit",
					initialValue: "kg",
					options: ["kg", "lb"],
				},
			],
			[
				{
					name: "height",
					initialValue: "",
					label: "Height",
					type: "number",
				},
				{
					name: "heightUnit",
					initialValue: "cm",
					options: ["cm", "ft"],
				},
			],
			{
				name: "dateOfBirth",
				initialValue: "",
				label: "Date of birth",
				type: "date",
				required: true,
			},
			{
				name: "gender",
				initialValue: "Select An Option",
				label: "Gender",
				options: ["Male", "Female", "Other"],
				type: "dropdown",
				defaultName: "Select An Option",
				required: true,
			},
			{
				name: "comment",
				initialValue: "",
				label: "Comment",
				type: "textarea",
			},
		],
	},
	{
		name: "familyDetails",
		label: "Family Details",
		data: [
			{
				name: "motherPhoto",
				initialValue: "",
				type: "file",
				label: "Add Photo",
			},
			{
				name: "motherPhoto",
				initialValue: "",
				type: "file",
				label: "Add Mother's Photo",
			},
			{
				name: "motherName",
				initialValue: "",
				label: "Mother's First Name",
				required: true,
			},
			{
				name: "motherLastName",
				initialValue: "",
				label: "Mother's Last Name",
				required: true,
			},
			{
				name: "motherMobile",
				initialValue: "",
				label: "Mother's Mobile Number",
				type: "tel",
				required: true,
			},
			{
				name: "motherEmail",
				initialValue: "",
				label: "Mother's Email",
				type: "email",
				required: true,
			},
			{
				name: "fatherPhoto",
				initialValue: "",
				type: "file",
				label: "Add Father's Photo",
			},
			{
				name: "fatherName",
				initialValue: "",
				label: "Father's First Name",
				required: true,
			},
			{
				name: "fatherLastName",
				initialValue: "",
				label: "Father's Last Name",
				required: true,
			},
			{
				name: "fatherMobile",
				initialValue: "",
				label: "Father's Mobile Number",
				required: true,
				type: "tel",
			},
			{
				name: "fatherEmail",
				initialValue: "",
				label: "Father's Email",
				required: true,
				type: "email",
			},
			{
				name: "siblingName",
				initialValue: [""],
				label: "Sibiling Name",
			},
			[
				{
					name: "siblingName",
					initialValue: [""],
					label: "Sibiling Name",
					type: "vaccine",
				},
				{
					name: "siblingDOB",
					initialValue: "",
					label: "Date of birth",
					type: "date",
				},
			],
		],
		section: [
			// {
			//   name: "Primary Contact",
			//   data: [
			//     {
			//       name: "primaryContact",
			//       label: "Select a primary contact",
			//       type: "radio",
			//       required: true,
			//       options: ["Mother", "Father"],
			//       defaultName: "Select An Option",
			//       initialValue: "Select An Option",
			//     },
			//   ],
			// },
			{
				name: "Mother's Details",
				data: [
					{
						name: "motherPhoto",
						initialValue: "",
						type: "file",
						label: "Add Mother's Photo",
					},
					{
						name: "motherName",
						initialValue: "",
						label: "Mother's First Name",
						required: true,
					},
					{
						name: "motherLastName",
						initialValue: "",
						label: "Mother's Last Name",
						required: true,
					},
					{
						name: "motherMobile",
						initialValue: "",
						label: "Mother's Mobile Number",
						type: "tel",
						required: true,
					},
					{
						name: "motherEmail",
						initialValue: "",
						label: "Mother's Email",
						type: "email",
						required: true,
					},
				],
			},
			{
				name: "Father's Details",
				data: [
					{
						name: "fatherPhoto",
						initialValue: "",
						type: "file",
						label: "Add Father's Photo",
					},
					{
						name: "fatherName",
						initialValue: "",
						label: "Father's First Name",
						required: true,
					},
					{
						name: "fatherLastName",
						initialValue: "",
						label: "Father's Last Name",
						required: true,
					},
					{
						name: "fatherMobile",
						initialValue: "",
						label: "Father's Mobile Number",
						required: true,
						type: "tel",
					},
					{
						name: "fatherEmail",
						initialValue: "",
						label: "Father's Email",
						required: true,
						type: "email",
					},
				],
			},
			{
				name: "Sibling Details",
				data: [
					[
						{
							name: "siblingName",
							initialValue: [""],
							label: "Sibiling Name",
							type: "vaccine",
						},
						{
							name: "siblingDOB",
							initialValue: [""],
							label: "Date of birth",
							type: "date",
						},
					],
				],
			},
		],
	},
	{
		name: "address",
		label: "Child Address",
		data: [
			{
				name: "addressLine1",
				initialValue: "",
				label: "Address Line 1",
				required: true,
				type: "address1",
			},
			{
				name: "addressLine2",
				initialValue: "",
				label: "Address Line 2",
				required: true,
				type: "address1",
			},
			{
				name: "addressCountry",
				initialValue: "Select A Country",
				type: "dropdown",
				options: onlyCountries,
				defaultName: "Select A Country",
				label: "Country",
				required: true,
			},
			{
				name: "addressState",
				initialValue: "Select A State",
				type: "dropdown",
				defaultName: "Select A State",
				options: [""],
				label: "State",
				required: true,
			},
			{
				name: "addressCity",
				initialValue: "",
				label: "City",
				required: true,
			},
			{
				name: "addressPostalCode",
				initialValue: "",
				label: "Postal Code",
				required: true,
			},
		],
	},
	{
		name: "emergencyContactDetails",
		data: [
			{
				name: "emergencyContactFirstName1",
				initialValue: "",
				label: "Emergency Contact First Name",
				//required: true,
			},
			{
				name: "emergencyContactLastName1",
				initialValue: "",
				label: "Emergency Contact Last Name",
				//required: true,
			},
			{
				name: "emergencyContact1",
				initialValue: "",
				label: "Emergency Contact Mobile Number",
				type: "tel",
				//required: true,
			},
			{
				name: "relationToChild1",
				initialValue: "",
				label: "Relationship With Child",
				//required: true,
			},
			{
				name: "emergencyContactFirstName2",
				initialValue: "",
				label: "Emergency Contact First Name",
			},
			{
				name: "emergencyContactLastName2",
				initialValue: "",
				label: "Emergency Contact Last Name",
			},
			{
				name: "emergencyContact2",
				initialValue: "",
				label: "Emergency Mobile Number",
				type: "tel",
			},
			{
				name: "relationToChild2",
				initialValue: "",
				label: "Relationship With Child",
			},
		],
		label: "Emergency Contact Details",
		section: [
			{
				name: "emergencyContactDetails1",
				//sectionHeading: "Emergency Contact 1 (Required)",
				sectionHeading: "Emergency Contact 1 (Optional)",
				data: [
					{
						name: "emergencyContactFirstName1",
						initialValue: "",
						label: "Emergency Contact First Name",
						//required: true,
					},
					{
						name: "emergencyContactLastName1",
						initialValue: "",
						label: "Emergency Contact Last Name",
						//required: true,
					},
					{
						name: "emergencyContact1",
						initialValue: "",
						label: "Emergency Contact Mobile Number",
						type: "tel",
						//required: true,
					},
					{
						name: "relationToChild1",
						initialValue: "",
						label: "Relationship With Child",
						//required: true,
					},
				],
			},
			{
				name: "emergencyContactDetails2",
				sectionHeading: "Emergency Contact 2 (Optional)",
				data: [
					{
						name: "emergencyContactFirstName2",
						initialValue: "",
						label: "Emergency Contact First Name",
					},
					{
						name: "emergencyContactLastName2",
						initialValue: "",
						label: "Emergency Contact Last Name",
					},
					{
						name: "emergencyContact2",
						initialValue: "",
						label: "Emergency Mobile Number",
						type: "tel",
					},
					{
						name: "relationToChild2",
						initialValue: "",
						label: "Relationship With Child",
					},
				],
			},
		],
	},
	{
		name: "Daycare Details",
		data: [
			{
				name: "section",
				initialValue: "Select An Option",
				label: "Section",
				options: [],
				type: "dropdown",
				defaultName: "Select An Option",
				required: true,
			},
			{
				name: "group",
				initialValue: "Select An Option",
				label: "Group",
				options: [],
				defaultName: "Select An Option",
				type: "dropdown",
				required: true,
			},
			{
				name: "photoVideoAuthorization",
				initialValue: "Select An Option",
				label: "Photo/Video Authorization",
				options: ["Yes", "No"],
				defaultName: "Select An Option",
				type: "dropdown",
				//required: true,
			},
			{
				name: "exteriorTripAuthorization",
				initialValue: "Select An Option",
				label: "Exterior Trip Authorisation",
				options: ["Yes", "No"],
				defaultName: "Select An Option",
				type: "dropdown",
				//required: true,
			},
			{
				name: "checkIn",
				initialValue: "",
				label: "Check In Time (24H)",
				type: "datalist",
				start: "09:00",
				end: "16:00",
				step: 30,
				//required: true,
			},
			{
				name: "checkOut",
				initialValue: "",
				label: "Check Out Time (24H)",
				type: "datalist",
				start: "09:00",
				end: "16:00",
				step: 30,
				//required: true,
			},
			{
				name: "billing",
				initialValue: "Select An Option",
				//options: ["Monthly", "Quarterly", "Annually", "Pay as you go"],
				options: ["Monthly"],
				label: "Billing Type",
				type: "dropdown",
				defaultName: "Select An Option",
				required: true,
			},
			{
				name: "contractType",
				label: "Contract Type",
				initialValue: "Select An Option",
				//options: ["Fixed", "Flexible"],
				options: ["Fixed"],
				type: "dropdown",
				required: true,
				defaultName: "Select An Option",
			},
			{
				name: "days",
				//label: "Days (Required)",
				label: "Days",
				initialValue: [],
				options: ["All Weekdays", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
				type: "multipleDropdown",
				//required: true,
			},
			[
				{
					name: "fees",
					initialValue: "",
					label: "Fees",
					type: "number",
					min: 10,
					required: true, // it just add the * in red on the UI but no other validation - even on DB side
				},
				{
					name: "per",
					initialValue: "Month",
					//options: ["Month", "Quarter", "Year", "Hour"],
					options: ["Month"],
				},
			],
			{
				name: "session",
				initialValue: "Select An Option",
				label: "Choose Session",
				options: [],
				type: "dropdown",
				defaultName: "Select An Option",
				required: true,
			},
			[
				{
					name: "howDidYouHearAboutUs",
					initialValue: "Select An Option",
					label: "How did you hear about us?",
					options: [
						"From a friend/colleague",
						"From our website",
						"From an existing parent",
						"From the staff member",
						"Others",
					],
					type: "dropdown",
					defaultName: "Select An Option",
					required: true,
				},
				{
					name: "howDidYouKnowAboutUsOthers",
					label: "Please specify",
					required: true,
					initialValue: "",
				},
			],
		],
	},
	{
		name: "Medical Details",
		data: [
			{
				name: "allergies",
				initialValue: [""],
				label: "Allergy",
			},
			{
				name: "healthProblems",
				initialValue: [""],
				label: "Health Problem",
			},
			[
				{
					name: "vaccinationType",
					label: "Vaccine Name",
					required: false,
					initialValue: [""],
					type: "vaccine",
				},
				{
					name: "vaccinationLastDate",
					label: "Vaccination Date",
					required: false,
					type: "date",
					initialValue: [""],
				},
			],
			{
				name: "bloodGroup",
				initialValue: "Select An Option",
				label: "Child's Blood group",
				options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
				type: "dropdown",
				defaultName: "Select An Option",
			},
		],
	},
];
export const details = [
	"Child Profile",
	"Family Details",
	"Child Address",
	"Emergency Contacts",
	"Daycare Details",
	"Medical Details",
	"Documents",
];
