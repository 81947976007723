import React from "react";

import { useTranslation } from 'react-i18next'


const CustomDropDown = ({ labelRequired = true, ...props }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={`d-flex justify-conten-ceter align-items-center ${labelRequired ? "mt-3" : "mt-0"
          }`}
        style={{
          position: "relative",
          ...props.style,
        }}>
        {labelRequired && (
          <label htmlFor={props.name} style={{ width: "50%" }}>
            {t(props.label)} {props.required ? <span className="red-text">*</span> : null}
          </label>
        )}
        <select
          className="browser-default custom-select"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          onBlur={props.onBlur}
          id={props.name}
          required={true}
          className="form-control"
          style={{ width: `${labelRequired ? "50%" : "100%"}`, ...props.style }}>
          <option
            selected={true}
            disabled
            value={props.defaultName ? props.defaultName : `Select An Option`}
            key="Select An Option">
            {t(props.defaultName) ? props.defaultName : t(`Select An Option`)}
          </option>
          {props.options.map((option) => (
            <option value={option} key={option}>
              {t(option)}
            </option>
          ))}
        </select>
        {props.error ? (
          <p className="red-text m-0">{`${t(props.defaultValue) || t("Select An Option")}`}</p>
        ) : null}
      </div>
    </div>
  );
};

export default CustomDropDown;
