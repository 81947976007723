import React, { useState, useEffect } from "react";
import { MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from "mdbreact";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../GeneralComponents/Button";
import CheckIcon from "@material-ui/icons/Check";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  modalHeader: {
    backgroundColor: "#304391!important",
    textAlign: "center",
    color: "#fff!important",
  },
  button: {
    backgroundColor: "#304391!important",
    color: "#fff",
  },
});

const PaymentConfirmationModal = ({
  month,
  isOpen,
  paymentSuccess,
  payableAmount,
  selectedStaff,
  handleToggle,
  paymentConfirmationIntent,
}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <MDBModal isOpen={true} toggle={handleToggle} centered>
      <MDBModalHeader
        style={{ backgroundColor: "#2A4494" }}
        titleClass="d-flex justify-content-between align-items-center white-text w-100">
        <p className="m-0 p-0" style={{ fontSize: "21px", fontWeight: "bold" }}>
          {t("Payment Summary")}
        </p>
        <MDBIcon
          icon="times"
          onClick={() => {
            handleToggle();
          }}
        />
      </MDBModalHeader>
      <MDBModalBody>
        <div className="d-flex justify-content-center font-weight-bold">
          <div className="d-flex">
            <div>
              <div>{t("Month")}</div>
              <div>{t("Total Staff Selected")}</div>
              <div>{t("Total Amount")} ($)</div>
            </div>
            <div className="ml-1">
              <div>=</div>
              <div>=</div>
              <div>=</div>
            </div>
            <div className="ml-2">
              <div>{month}</div>
              <div>{selectedStaff}</div>
              <div>{payableAmount}</div>
            </div>
          </div>
        </div>
        {paymentSuccess && (
          <div className="text-center text-success d-flex justify-content-center">
            {t("Payment for above successfully completed")}
            <CheckIcon />
          </div>
        )}
      </MDBModalBody>
      {!paymentSuccess && (
        <MDBModalFooter className="d-flex justify-content-between">
          <Button onClick={handleToggle}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              paymentConfirmationIntent();
              handleToggle();
            }}>
            {t("Go To Payment")}
          </Button>
        </MDBModalFooter>
      )}
      {paymentSuccess && (
        <MDBModalFooter className="d-flex">
          <Button onClick={handleToggle}>{t("Ok")}</Button>
        </MDBModalFooter>
      )}
    </MDBModal>
  );
};

export default PaymentConfirmationModal;
