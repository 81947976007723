import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
const InputValueDropDown = ({
  name,
  value,
  onChange,
  type,
  label,
  onBlur,
  required,
  options,
  disabled,
}) => {

  const {t} = useTranslation();

  const currency = useSelector((state) => state.currency);
  useEffect(() => {
    console.log({ currency });
  }, [currency]);
  return (
    <div className="d-flex align-items-center mt-1">
      <div
        className="form-group mt-3"
        style={{
          marginTop: "0px",
          marginBottom: "40px",
          padding: "5px",
          display: `${label == "Fees" ? "" : "none"}`,
        }}>
        {label == "Fees" && currency[0]}
      </div>
      <div className="form-group mt-3">
        <input
          min="1"
          type={type || "text"}
          className="form-control"
          id={name[0]}
          value={value[0]}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          style={{
            borderRightWidth: "0px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
          required={required ? true : null}
        />
        <label htmlFor={name}>
          {t(label)} {required ? <span style={{ color: "red" }}>*</span> : null}
        </label>
      </div>
      <div
        style={{
          marginBottom: "32px",
          position: "relative",
          paddingTop: "1px",
        }}>
        <select
          className="browser-default custom-select"
          value={value[1]}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          id={name[1]}
          style={{
            borderLeftWidth: "0px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            backgroundColor: "#ebebeb",
          }}>
          {options.map((option) => (
            <option value={option} key={option}>
              {t(option)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default InputValueDropDown;
