import React from "react";

const InputFileName = ({
  name,
  label,
  type,
  disabled,
  onChange,
  value,
  onBlur,
  required,
  ...props
}) => (
  <div className="d-flex justify-conten-ceter align-items-center">
    <input
      type={type || "text"}
      id={name}
      disabled={disabled}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={props.placeholder}
      onBlur={onBlur || null}
      className="form-control"
      style={{ width: "100%", ...props.style }}
    />
  </div>
);
export default InputFileName;
