export const LOG_IN = "LOG_IN";
export const LOAD_ING = "LOAD_ING";
export const LOG_OUT = "LOG_OUT";
export const RESET = "RESET";
export const CHILDDETAIL = "CHILDDETAIL";
export const CHILDENROLLDETAIL = "CHILDENROLLDETAIL";
export const CHILDDATA = "CHILDDATA";
export const SECTION = "SECTION";
export const CREATESEC = "CREATESEC";
export const OWNERLIST = "OWNERLIST";
export const GROUPNAME = "GROUPNAME";
export const SECTIONNAMES = "SECTIONNAMES";
export const EDITSECTION = "EDITSECTION";
export const ADD_SEC = "ADD_SEC";
export const EDITGROUP = "EDITGROUP";
export const CREATEGROUP = "CREATEGROUP";
export const GROUP = "GROUP";
export const ADD_GROUP = "ADD_GROUP";
export const CATEGORY = "CATEGORY";
export const SUBCATEGORY = "SUBCATEGORY";
export const ACTIVITY = "ACTIVITY";
export const CREATEACTIVITY = "CREATEACTIVITY";
export const OTPSCREEN = "OTPSCREEN";
export const STAFF_LOADING = "STAFF_LOADING";
export const STAFF_FAILED = "STAFF_FAILED";
export const ADD_STAFF = "ADD_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";
export const ARCHIVE_STAFF = "ARCHIVE_STAFF";
export const ADD_SALARY = "ADD_SALARY";
export const EDIT_SALARY = "EDIT_SALARY";

export const ADD_STAFFLIST = "ADD_STAFFLIST";
export const DELETESEC = "DELETESEC";
export const DELETEGROUP = "DELETEGROUP";
export const DELETEACTIVITY = "DELETEACTIVITY";
export const DELETESECGROUP = "DELETESECGROUP";
export const TWILIO_CLIENT_DEVICE = "TWILIO_CLIENT_DEVICE";
export const ADD_PARENTS = "ADD_PARENTS";
export const UPDATE_PARENTS = "UPDATE_PARENTS";
export const ADD_CALLHISTORY = "ADD_CALLHISTORY";
export const UPDATE_CALLHISTORY = "UPDATE_CALLHISTORY";
export const ADD_CURRENCY = "ADD_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const ADD_NESTED = "ADD_NESTED";
export const NEW_EMAIL = "NEW_EMAIL";
export const FLUSH_EMAIL = "FLUSH_EMAIL";
export const NEW_MEETING = "NEW_MEETING";
export const FLUSH_MEETING = "FLUSHMEETING";
export const ADD_CONFIG = "ADD_CONFIG";
export const ADD_SUBMODULE = "ADD_SUBMODULE";
export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const ADD_ID = "ADD_ID";
export const ADD_COUNTRY = "ADD_COUNTRY";

export const ADD_SOCKET = "ADD_SOCKET";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_ALL_MESSAGE = "ADD_ALL_MESSAGE";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const SECTION_OBJECT = "SECTION_OBJECT";
export const ADD_ACCESSIBLE_MODULES_DATA = "ADD_ACCESSIBLE_MODULES_DATA";
