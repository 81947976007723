import { MDBIcon } from "mdbreact";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const InputMultipleDropDown = ({
	options,
	label,
	value,
	onBlur,
	onChange,
	name,
	disabled,
	...props
}) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [isChecked, setIsChecked] = useState(
		new Array(options.length).fill(false)
	);
	const [selectedValues, setSelectedValues] = useState([]);

	const DropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (DropdownRef.current && !DropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [DropdownRef]);

	useEffect(() => {
		console.log("raw date", value);
		const newChecked = new Array(options.length).fill(false);
		value.forEach((ele) => {
			const index = options.findIndex((data) => data == ele);
			if (index != -1) {
				newChecked[index] = true;
			}
		});
		setIsChecked(newChecked);
		setSelectedValues(value);
	}, [value]);
	const ChangeHandler = (index) => {
		const newChecked = [...isChecked];
		newChecked[index] = !newChecked[index];
		const newSelectedValues = [...selectedValues];
		if (newChecked[index]) {
			newSelectedValues.push(options[index]);
		} else {
			newSelectedValues.splice(
				newSelectedValues.findIndex((ele) => ele === options[index]),
				1
			);
		}
		setSelectedValues(newSelectedValues);
		setIsChecked(newChecked);
		onChange({
			target: {
				id: name,
				value: newSelectedValues,
				type: "multipleDropdown",
			},
		});
		onBlur({
			target: {
				id: name,
				value: newSelectedValues,
				type: "multipleDropdown",
			},
		});
	};

	return (
		<div
			style={{
				marginBottom: "30px",
				borderRadius: "12px",
				borderColor: "black",
				position: "relative",
			}}
		>
			<p
				className="m-0"
				style={{
					position: "relative",
					width: "max-content",
					zIndex: "20",
					backgroundColor: "white",
					top: "10px",
					left: "15px",
					padding: "0px 5px",
					fontSize: "14px",
				}}
			>
				{t(label)}{" "}
				{props?.required ? <span className="red-text">*</span> : <span></span>}
			</p>
			<div
				// disabling this is fixing the issue on child registration on selecting the days under school tab
				//ref={DropdownRef}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "10px 12px",
					border: "1px solid #ced4da",
					borderRadius: "0.25rem",
					backgroundColor: `${disabled ? "#e9ecef" : "white"}`,
				}}
				className="browser-default custom-select"
				onClick={() => (disabled ? null : setIsOpen((state) => !state))}
				onBlur={() => setIsOpen(false)}
			>
				<p className="m-0">
					{selectedValues.length
						? selectedValues.join(",")
						: t("Select An Option")}
				</p>
				<MDBIcon icon="chevron-down" style={{ display: "none" }} />
			</div>
			<div
				style={{
					display: `${isOpen ? "flex" : "none"}`,
					flexDirection: "column",
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
					padding: "10px 12px",
					borderRadius: "0px 0px 12px 12px",
					position: "absolute",
					zIndex: "1000",
					width: "100%",
					background: "white",
				}}
			>
				{options.map((ele, index) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "10px",
						}}
					>
						<input
							type="checkbox"
							id={ele}
							checked={isChecked[index]}
							onChange={() => ChangeHandler(index)}
						/>
						<label className="m-0 pl-2" htmlFor={ele}>
							{t(ele)}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default InputMultipleDropDown;
